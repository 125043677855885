import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { enqueueSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../components/AuthProvider";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import PeopleIcon from "@mui/icons-material/People";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";
import * as yup from "yup";
import { isValidNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  AppBar,
  Toolbar,
  TextField,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import People from "@mui/icons-material/People";

const passwordSchema = yup
  .string()
  .min(8)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    "Password must be at least 8 characters, have one uppercase, one lowercase, one number, and one special character"
  );

const regexWholeNumber = /^\d*$/; // matches one or more digits (whole numbers)

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const SettingsPage = ({ history }) => {
  const defaultShipmentType = {
    shipment_type_name: `New Shipment Type`,
    departure_day: "Monday",
    price_per_pound: "0.00",
    dimensional_weight: "0.00",
    per_km_rate: "0.00",
    est_delivery_time: "10",
    desired_update_time: "3",
  };

  const [newShipmentType, setNewShipmentType] = useState(null);

  const { accessLevel, logoutUser } = useContext(AuthContext);
  const theme = useTheme();
  const location = useLocation();
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [sort, setSort] = useState("created_at");
  const [filter, setFilter] = useState("");
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [displayEmployees, setDisplayEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewRequestOpen, setReviewRequestOpen] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [originalRowData, setOriginalRowData] = useState(null);
  // Profile variables
  const [initialProfileInfo, setInitialProfileInfo] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [deliversToCuba, setDeliversToCuba] = useState(false);

  const handleEmailChange = (event) => {
    const { value } = event.target;

    setEmail(value);

    const isValidEmail = value ? yup.string().email().isValidSync(value) : true;
    setEmailError(!isValidEmail);
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/[^a-zA-Z\u00C0-\u024F\s]/g, "");
    setFirstName(updatedValue);
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/[^a-zA-Z\u00C0-\u024F\s]/g, "");
    setLastName(updatedValue);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const isValid = isValidNumber(value, country.countryCode.toUpperCase());

    setPhoneError(!isValid);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;

    setPassword(value);

    if (value !== "") {
      const isValidPassword = passwordSchema.isValidSync(value);
      setPasswordError(!isValidPassword);
    }
    if (value === "") {
      setPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  useEffect(() => {
    setConfirmPasswordError(confirmPassword !== password);
  }, [password, confirmPassword]);

  const handleNewShipmentTypeChange = (field, value) => {
    setNewShipmentType((prev) => ({ ...prev, [field]: value }));
  };

  const handleCreateNew = async () => {
    // validate the fields
    setIsLoading(true);
    if (!newShipmentType || Object.values(newShipmentType).includes("")) {
      enqueueSnackbar(
        "Please fill in all fields before creating a new shipment type",
        { variant: "error" }
      );
      return;
    }

    try {
      const response = await axiosInstance.post(
        "/packages/create_shipment_type",
        newShipmentType
      );
      if (response.status === 200) {
        enqueueSnackbar("Shipment created successfully", {
          variant: "success",
        });
        fetchShipmentTypes(); // to fetch updated data
        setNewShipmentType(null); // reset the new shipment type
      } else {
        enqueueSnackbar(`Error creating shipment: ${response.data.error}`, {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.error) {
        enqueueSnackbar(`Can't create shipment: ${err.response.data.error}`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(`Can't create shipment: ${err}`, { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  const handleAdd = () => {
    // create an empty row
    setNewShipmentType(defaultShipmentType);
  };

  const fetchShipmentTypes = async () => {
    try {
      const response = await axiosInstance.get(`/packages/get_shipments`);
      if (response.status === 200) {
        const shipmentTypesWithEditFlag = response.data.map((shipmentType) => ({
          ...shipmentType,
          isEditing: false,
        }));
        console.log(shipmentTypesWithEditFlag);
        setShipmentTypes(shipmentTypesWithEditFlag);
      } else {
        enqueueSnackbar("Error fetching shipment types", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    Promise.all([
      fetchShipmentTypes(),
      fetchDeliversToCuba(),
      fetchProfileInfo(),
    ]).then(() => {
      setIsLoading(false);
    });
    console.log(shipmentTypes);
    fetchProfileInfo();
  }, []);

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = (index) => {
    // repopulate the row with the original data
    const newShipmentTypes = [...shipmentTypes];
    newShipmentTypes[index] = originalRowData;
    setShipmentTypes(newShipmentTypes);
    setOriginalRowData(null);
  };

  const handleEdit = (index) => {
    const newShipmentTypes = [...shipmentTypes];
    newShipmentTypes[index] = { ...newShipmentTypes[index], isEditing: true };
    setShipmentTypes(newShipmentTypes);
    setOriginalRowData({ ...shipmentTypes[index] });
  };

  const handleChange = (index, name, value) => {
    const newShipmentTypes = [...shipmentTypes];
    newShipmentTypes[index][name] = value;
    setShipmentTypes(newShipmentTypes);
  };

  const handleDelete = async (index) => {
    try {
      const response = await axiosInstance.put(
        `/packages/delete_shipment_type`,
        shipmentTypes[index]
      );
      if (response.status === 200) {
        enqueueSnackbar("Shipment deleted successfully", {
          variant: "success",
        });
        fetchShipmentTypes(); // to fetch updated data
      } else {
        enqueueSnackbar("Error deleting shipment", { variant: "error" });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        enqueueSnackbar(err.response.data.error, { variant: "error" });
      } else {
        enqueueSnackbar("Error deleting shipment", { variant: "error" });
      }
    }
  };

  const handleConfirm = async (index) => {
    try {
      const response = await axiosInstance.put(
        `/packages/update_shipment_type`,
        shipmentTypes[index]
      );
      if (response.status === 200) {
        enqueueSnackbar("Shipment updated successfully", {
          variant: "success",
        });
        fetchShipmentTypes(); // to fetch updated data
      } else {
        enqueueSnackbar("Error updating shipment", { variant: "error" });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        enqueueSnackbar(err.response.data.error, { variant: "error" });
      } else {
        enqueueSnackbar("Error deleting shipment", { variant: "error" });
      }
    }
    setEditRowIndex(null);
  };

  // Profile Settings

  const fetchProfileInfo = async () => {
    try {
      const response = await axiosInstance.get("employees/get_profile_info");
      const data = response.data;
      setEmail(data.email);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setPhone(data.phone);
      setInitialProfileInfo(data);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchDeliversToCuba = async () => {
    try {
      const response = await axiosInstance.get(
        "/vendor/get_current_vendor_deliver_in_cuba_status"
      );
      console.log(response.data);
      setDeliversToCuba(response.data);
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "Error fetching delivery status";
      console.error("Error fetching delivers to Cuba status:", error);
      enqueueSnackbar(errorMsg, { variant: "error" });
    }
  };

  const toggleDeliversToCuba = async () => {
    try {
      const endpoint = deliversToCuba
        ? "/vendor/set_does_not_deliver_in_cuba"
        : "/vendor/set_delivers_in_cuba";
      const response = await axiosInstance.post(endpoint);
      if (response.data.message === "Vendor updated successfully") {
        setDeliversToCuba(!deliversToCuba);
        enqueueSnackbar("Delivery status updated successfully", {
          variant: "success",
        });
      } else {
        const errorMsg =
          response.data?.error || "Error updating delivery status";
        enqueueSnackbar(errorMsg, { variant: "error" });
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "Error updating delivery status";
      console.error("Error changing delivers to Cuba status:", error);
      enqueueSnackbar(errorMsg, { variant: "error" });
    }
  };

  const handleCancelChanges = () => {
    setFirstName(initialProfileInfo.first_name);
    setLastName(initialProfileInfo.last_name);
    setPhone(initialProfileInfo.phone);
    setPassword("");
    setEmail(initialProfileInfo.email);
    setConfirmPassword("");
  };

  const handleSubmitChanges = async () => {
    try {
      const response = await axiosInstance.put(
        "employees/update_profile_info",
        {
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          password: password,
        }
      );
      if (response.status === 200) {
        enqueueSnackbar("Profile updated successfully", { variant: "success" });
        setInitialProfileInfo({
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          password: password,
        });
      } else {
        enqueueSnackbar("Error updating profile", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Settings
            </Typography>
            <Button onClick={fetchShipmentTypes}>
              <RefreshIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
        }}
      >
        {accessLevel <= 3 && (
          <TableContainer>
            <Typography
              variant="h5"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              Shipments
            </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={200} align="center">
                    📦 Shipment Type Name
                  </TableCell>

                  <TableCell width={100} align="center">
                    🗓️ Departure Day
                  </TableCell>

                  <TableCell width={100} align="center">
                    💰 Per Pound Rate
                  </TableCell>

                  <TableCell width={100} align="center">
                    ⚖️ Dimensional Weight Price
                  </TableCell>

                  <TableCell width={100} align="center">
                    🛣️ Per Km Rate
                  </TableCell>

                  <TableCell width={100} align="center">
                    🕰️ Estimated Delivery
                  </TableCell>

                  <TableCell width={100} align="center">
                    ⏲️ Update Time
                  </TableCell>

                  <TableCell width={480} align="center">
                    📋 Package Types
                  </TableCell>

                  <TableCell width={50} align="center">
                    ✏️/🗑️ Edit/Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipmentTypes &&
                  shipmentTypes.map((shipmentType, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {shipmentType.isEditing ? (
                          <TextField
                            sx={{ width: "100%" }}
                            type="text"
                            value={shipmentType.shipment_type_name}
                            onChange={(e) => {
                              if (typeof e.target.value === "string") {
                                handleChange(
                                  index,
                                  "shipment_type_name",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        ) : (
                          shipmentType.shipment_type_name
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {shipmentType.isEditing ? (
                          <Select
                            sx={{ width: "100%" }}
                            value={shipmentType.departure_day}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "departure_day",
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="Monday">Monday</MenuItem>
                            <MenuItem value="Tuesday">Tuesday</MenuItem>
                            <MenuItem value="Wednesday">Wednesday</MenuItem>
                            <MenuItem value="Thursday">Thursday</MenuItem>
                            <MenuItem value="Friday">Friday</MenuItem>
                            <MenuItem value="Saturday">Saturday</MenuItem>
                            <MenuItem value="Sunday">Sunday</MenuItem>
                          </Select>
                        ) : (
                          shipmentType.departure_day
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {!shipmentType.isEditing ? (
                          shipmentType.price_per_pound
                        ) : (
                          <TextField
                            fullWidth
                            name="price_per_pound"
                            type="text"
                            inputProps={{ min: "0.00", step: "1.00" }}
                            value={shipmentType.price_per_pound}
                            onChange={(event) => {
                              let val = event.target.value;
                              val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                              if (val === "") {
                                handleChange(index, "price_per_pound", "0.00");
                              } else {
                                // Format as decimal
                                val = parseFloat(
                                  parseInt(val, 10) / 100
                                ).toFixed(2);
                                handleChange(index, "price_per_pound", val);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {!shipmentType.isEditing ? (
                          shipmentType.dimensional_weight
                        ) : (
                          <TextField
                            fullWidth
                            name="dimensional_weight"
                            type="text"
                            inputProps={{ min: "0.00", step: "1.00" }}
                            value={shipmentType.dimensional_weight}
                            onChange={(event) => {
                              let val = event.target.value;
                              val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                              if (val === "") {
                                handleChange(
                                  index,
                                  "dimensional_weight",
                                  "0.00"
                                );
                              } else {
                                // Format as decimal
                                val = parseFloat(
                                  parseInt(val, 10) / 100
                                ).toFixed(2);
                                handleChange(index, "dimensional_weight", val);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {!shipmentType.isEditing ? (
                          shipmentType.per_km_rate
                        ) : (
                          <TextField
                            fullWidth
                            name="per_km_rate"
                            type="text"
                            inputProps={{ min: "0.00", step: "1.00" }}
                            value={shipmentType.per_km_rate}
                            onChange={(event) => {
                              let val = event.target.value;
                              val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                              if (val === "") {
                                handleChange(index, "per_km_rate", "0.00");
                              } else {
                                // Format as decimal
                                val = parseFloat(
                                  parseInt(val, 10) / 100
                                ).toFixed(2);
                                handleChange(index, "per_km_rate", val);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {shipmentType.isEditing ? (
                          <TextField
                            fullWidth
                            label="Days"
                            type="text"
                            value={shipmentType.est_delivery_time}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (regexWholeNumber.test(val)) {
                                handleChange(index, "est_delivery_time", val);
                              }
                            }}
                          />
                        ) : (
                          shipmentType.est_delivery_time
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {shipmentType.isEditing ? (
                          <TextField
                            label="Hours"
                            fullWidth
                            type="text"
                            value={shipmentType.desired_update_time}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (regexWholeNumber.test(val)) {
                                handleChange(index, "desired_update_time", val);
                              }
                            }}
                          />
                        ) : (
                          shipmentType.desired_update_time
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {!shipmentType.isEditing ? (
                          shipmentType.package_type.join(", ")
                        ) : (
                          <TextField
                            select
                            sx={{ width: "100%" }}
                            multiple
                            value={shipmentType.package_type || []}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "package_type",
                                e.target.value
                              )
                            }
                            SelectProps={{
                              multiple: true,
                              renderValue: (selected) => selected.join(", "),
                            }}
                          >
                            {[
                              { label: "ENVELOPE", emoji: "✉️" },
                              { label: "PACKAGE", emoji: "🛍️" },
                              { label: "BOX", emoji: "📦" },
                              { label: "PALLET", emoji: "📋" },
                              { label: "CRATE", emoji: "🚧" },
                              { label: "OTHER", emoji: "🔍" },
                            ].map((type) => (
                              <MenuItem key={type.label} value={type.label}>
                                {type.emoji} {type.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {shipmentType.isEditing ? (
                          <>
                            <IconButton onClick={() => handleConfirm(index)}>
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={() => handleCancel(index)}>
                              <CancelIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={() => handleEdit(index)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {newShipmentType && (
                  <TableRow>
                    {/* Shipment Type Name as String */}
                    <TableCell align="center">
                      <TextField
                        sx={{ width: "100%" }}
                        value={newShipmentType.shipment_type_name}
                        onChange={(e) =>
                          handleNewShipmentTypeChange(
                            "shipment_type_name",
                            e.target.value
                          )
                        }
                      />
                    </TableCell>

                    {/* Day of the Week Dropdown */}
                    <TableCell align="center">
                      <TextField
                        select
                        sx={{ width: "100%" }}
                        value={newShipmentType.departure_day}
                        onChange={(e) =>
                          handleNewShipmentTypeChange(
                            "departure_day",
                            e.target.value
                          )
                        }
                      >
                        {[
                          "Monday",
                          "Tuesday",
                          "Wednesday",
                          "Thursday",
                          "Friday",
                          "Saturday",
                          "Sunday",
                        ].map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>

                    {/* Price per Pound as Decimal */}
                    <TableCell align="center">
                      <TextField
                        name="price_per_pound"
                        type="text"
                        inputProps={{ min: "0.00", step: "1.00" }}
                        value={newShipmentType.price_per_pound}
                        onChange={(event) => {
                          let val = event.target.value;
                          val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                          if (val === "") {
                            handleNewShipmentTypeChange(
                              "price_per_pound",
                              "0.00"
                            );
                          } else {
                            // Format as decimal
                            val = parseFloat(parseInt(val, 10) / 100).toFixed(
                              2
                            );
                            handleNewShipmentTypeChange("price_per_pound", val);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ width: "100%" }}
                        name="dimensional_weight"
                        type="text"
                        inputProps={{ min: "0.00", step: "1.00" }}
                        value={newShipmentType.dimensional_weight}
                        onChange={(event) => {
                          let val = event.target.value;
                          val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                          if (val === "") {
                            handleNewShipmentTypeChange(
                              "dimensional_weight",
                              "0.00"
                            );
                          } else {
                            // Format as decimal
                            val = parseFloat(parseInt(val, 10) / 100).toFixed(
                              2
                            );
                            handleNewShipmentTypeChange(
                              "dimensional_weight",
                              val
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ width: "100%" }}
                        name="per_km_rate"
                        type="text"
                        inputProps={{ min: "0.00", step: "1.00" }}
                        value={newShipmentType.per_km_rate}
                        onChange={(event) => {
                          let val = event.target.value;
                          val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                          if (val === "") {
                            handleNewShipmentTypeChange("per_km_rate", "0.00");
                          } else {
                            // Format as decimal
                            val = parseFloat(parseInt(val, 10) / 100).toFixed(
                              2
                            );
                            handleNewShipmentTypeChange("per_km_rate", val);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        label="Days"
                        value={newShipmentType.est_delivery_time}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (regexWholeNumber.test(val)) {
                            handleNewShipmentTypeChange(
                              "est_delivery_time",
                              val
                            );
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <TextField
                        sx={{ width: "100%" }}
                        type="text"
                        label="Hours"
                        value={newShipmentType.desired_update_time}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (regexWholeNumber.test(val)) {
                            handleNewShipmentTypeChange(
                              "desired_update_time",
                              val
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        select
                        sx={{ width: "100%" }}
                        multiple
                        value={newShipmentType.package_type || []}
                        onChange={(e) =>
                          handleNewShipmentTypeChange(
                            "package_type",
                            e.target.value
                          )
                        }
                        SelectProps={{
                          multiple: true,
                          renderValue: (selected) => selected.join(", "),
                        }}
                      >
                        {[
                          { label: "ENVELOPE", emoji: "✉️" },
                          { label: "PACKAGE", emoji: "🛍️" },
                          { label: "BOX", emoji: "📦" },
                          { label: "PALLET", emoji: "📋" },
                          { label: "CRATE", emoji: "🚧" },
                          { label: "OTHER", emoji: "🔍" },
                        ].map((type) => (
                          <MenuItem key={type.label} value={type.label}>
                            {type.emoji} {type.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                    <TableCell width={50} align="center">
                      <IconButton onClick={handleCreateNew}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={() => setNewShipmentType(null)}>
                        <CancelIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={10} style={{ textAlign: "center" }}>
                    <IconButton onClick={handleAdd}>
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 3,
              mb: 5,
              width: "50%",
            }}
          >
            <AccountCircle sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography component="h1" variant="h5">
              Profile
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              error={firstName === ""}
              helperText={firstName === "" && "Please enter your first name"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
              error={lastName === ""}
              helperText={lastName === "" && "Please enter your last name"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              onChange={handleEmailChange}
              error={emailError || email === ""}
              helperText={
                email === ""
                  ? "Email field cannot be empty"
                  : emailError
                  ? "Please enter a valid email address"
                  : ""
              }
            />
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={handlePhoneChange}
              countryCodeEditable={false}
              enableCountryCode={true}
              enableSearch={true}
              isValid={() => !phoneError}
              placeholder="Enter phone number"
              inputStyle={{
                width: "95%",
                height: "56px",
                fontSize: "1rem",
                marginLeft: "40px",
                padding: "18.5px 14px",
                border: "1px solid #ced4da",
                borderRadius: "4px",
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label="New Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
              helperText={
                passwordError
                  ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character"
                  : ""
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={
                confirmPasswordError ||
                (password !== "" && confirmPassword === "")
              }
              helperText={
                password !== "" && confirmPassword === ""
                  ? "Confirm Password field cannot be empty"
                  : confirmPasswordError
                  ? "Passwords do not match"
                  : ""
              }
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                disabled={
                  isLoading ||
                  (firstName === initialProfileInfo.first_name &&
                    lastName === initialProfileInfo.last_name &&
                    email === initialProfileInfo.email &&
                    phone === initialProfileInfo.phone &&
                    password === "" &&
                    confirmPassword === "")
                }
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={handleCancelChanges} // Add a handler to reset the profile data
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  isLoading ||
                  (firstName === initialProfileInfo.first_name &&
                    lastName === initialProfileInfo.last_name &&
                    email === initialProfileInfo.email &&
                    phone === initialProfileInfo.phone &&
                    password === "") ||
                  emailError ||
                  phoneError ||
                  passwordError ||
                  confirmPasswordError
                }
                onClick={handleSubmitChanges} // Add a handler to submit the changes
              >
                Submit Changes
              </Button>
            </Box>
          </Box>
          {accessLevel <= 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 3,
                  mb: 5,
                  width: "50%",
                }}
              >
                <LocalShippingIcon sx={{ fontSize: 60, marginBottom: 2 }} />
                <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                  Delivery Settings
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <PublicIcon sx={{ color: "primary.main", mr: 1 }} />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={deliversToCuba}
                        onChange={toggleDeliversToCuba}
                      />
                    }
                    label={
                      <Typography variant="subtitle1">
                        Enable Delivering to Cuba
                      </Typography>
                    }
                  />
                </Box>
                <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                  {deliversToCuba
                    ? "Currently delivering to Cuba."
                    : "Not delivering to Cuba."}
                </Typography>
                <Typography variant="body2" align="center">
                  Enable this option to deliver packages for other vendors in
                  Cuba. Ensure you have at least one operating courier to
                  enable. You will not be able to disable this option if you
                  have any packages in transit for other vendors in Cuba.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;

import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axiosInstance from "../components/axiosInstance";
import { enqueueSnackbar } from "notistack";
import UserDrawerComponent from "../components/UserDrawerComponent";
import WalletBalance from "../components/WalletBalance";

const stripePromise = loadStripe(
  "pk_live_51NnQZ6DZzcpBER6kAXXlwm7DPy4YfJPr4dq7L2O4bQU9Wma39a35H3KJApSF0Uode4ocVmkpgrL3WPNvbDE4cXWo00NcSCvsxN"
);

const Background = styled("div")(({ theme }) => ({
  background: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
  minHeight: "100vh",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: -1,
}));

const UserHomePage = () => {
  const [open, setOpen] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [rechargeKey, setRechargeKey] = useState(0);
  const TOTAL_BALANCE = 100;
  const [animatedBalance, setAnimatedBalance] = useState(walletBalance);

  useEffect(() => {
    const fetchWalletBalance = async () => {
      const response = await axiosInstance.get("/wallet/get_wallet_balance");
      setWalletBalance(response.data.wallet_balance);
    };
    fetchWalletBalance();
  }, []);

  const handleToggle = () => setOpen(!open);

  const handleRechargeClick = async (amount) => {
    try {
      // turn wallet balance to a float
      let displayAmount = parseFloat(walletBalance);
      // add the amount to the wallet balance
      displayAmount += amount;
      console.log("Recharging wallet with amount:", amount);
      const response = await axiosInstance.post(
        "/wallet/recharge_wallet_intent",
        { amount }
      );
      setClientSecret(response.data.clientSecret);
      setRechargeKey((prevKey) => prevKey + 1);
      //
      setAnimatedBalance(displayAmount);
    } catch (error) {
      console.error("Error creating checkout session:", error);
      enqueueSnackbar("Failed to initiate recharge", { variant: "error" });
    }
  };

  // Modify handleCancelRecharge to reset the animation:
  const handleCancelRecharge = () => {
    setClientSecret("");
    setRechargeKey((prevKey) => prevKey + 1);
    setAnimatedBalance(walletBalance);
  };

  const checkoutOptions = { clientSecret };

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleToggle}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            User Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <UserDrawerComponent open={open} onMenuItemClick={handleToggle} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Typography paragraph>
          Welcome to your dashboard! Here you can manage your account settings
          and more.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Virtual Wallet
        </Typography>
        <Typography variant="h4" sx={{ my: 2, textAlign: "center" }}>
          Wallet Balance: ${walletBalance}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <WalletBalance
            balance={animatedBalance}
            totalBalance={TOTAL_BALANCE}
          />
        </Box>
        <Background />
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            gap: 2,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {[25, 50, 100].map((amount) => (
            <Button
              key={amount}
              variant="contained"
              onClick={() => handleRechargeClick(amount)}
              sx={{
                backgroundColor: "#0f0f0f",
                "&:hover": { backgroundColor: "#333" },
                mb: 2,
              }}
            >
              Recharge ${amount}
            </Button>
          ))}
          {clientSecret && (
            <Button
              variant="contained"
              onClick={handleCancelRecharge}
              sx={{
                mt: 2,
                mb: 2,
                color: "white",
                borderColor: "white",
                backgroundColor: "red",
              }}
            >
              Cancel Recharge
            </Button>
          )}
        </Box>
        {clientSecret && (
          // make the edges rounded
          <EmbeddedCheckoutProvider
            key={rechargeKey}
            stripe={stripePromise}
            options={checkoutOptions}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </Box>
    </Box>
  );
};

export default UserHomePage;

import React, { useEffect, useState } from "react";
import axiosInstance from "../components/axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { BusinessRegistrationContext } from "../BusinessRegistrationContext";
import { AuthContext } from "../components/AuthProvider";
import * as yup from "yup";
import {
  TextField,
  Button,
  Container,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { FormHelperText } from "@mui/material";
import { isValidNumber } from "libphonenumber-js";
import logo from "../logoClean.svg";

// Create the custom vibrant color theme
// TODO: Block form after submission and add loading indicator
const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9", // Approximation of Apple's blue color
    },
    secondary: {
      main: "#aeaeae", // A neutral secondary color
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // A similar font to Apple's San Francisco
  },
});

const passwordSchema = yup
  .string()
  .min(8)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    "Password must be at least 8 characters, have one uppercase, one lowercase, one number, and one special character"
  );

const ManagerSignUp = () => {
  const { businessRegistered } = useContext(BusinessRegistrationContext);
  const location = useLocation();

  if (!businessRegistered) {
    navigate("/");
  }

  const { manager } = location.state;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(manager[0]);
  const [lastName, setLastName] = useState(manager[1]);
  const [phone, setPhone] = useState("");
  const [vendor, setVendor] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        "/auth/register/managerRegister",
        {
          name: manager,
          email: email,
          phone: phone,
          password: password,
        }
      );

      if (response.status === 200) {
        enqueueSnackbar(
          "Registration successful. Check your email for verification.",
          { variant: "success" }
        );
        navigate("/");
      } else {
        enqueueSnackbar(response.data.error, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        enqueueSnackbar(error.response.data.error, { variant: "error" });
      } else {
        enqueueSnackbar("An error occurred", { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!location.state || !manager) {
      navigate("/"); // Or wherever you want to redirect
      return null; // Do not render anything
    }
  }, [location, manager, navigate]);

  const handleEmailChange = (event) => {
    const { value } = event.target;

    setEmail(value);

    const isValidEmail = value ? yup.string().email().isValidSync(value) : true;
    setEmailError(!isValidEmail);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const isValid = isValidNumber(value, country.countryCode.toUpperCase());

    setPhoneError(!isValid);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;

    setPassword(value);

    const isValidPassword = value ? passwordSchema.isValidSync(value) : true;
    setPasswordError(!isValidPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  useEffect(() => {
    setConfirmPasswordError(confirmPassword !== password);
  }, [password, confirmPassword]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">
          Manager Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            value={firstName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            value={lastName}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            value={email}
            onChange={handleEmailChange}
            error={emailError || email === ""}
            helperText={
              email === ""
                ? "Email field cannot be empty"
                : emailError
                ? "Please enter a valid email address"
                : ""
            }
          />
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={handlePhoneChange}
            countryCodeEditable={false}
            enableCountryCode={true}
            enableSearch={true}
            isValid={() => !phoneError}
            placeholder="Enter phone number"
            inputStyle={{
              width: "90%",
              height: "56px",
              fontSize: "1rem",
              marginLeft: "40px",
              padding: "18.5px 14px",
              border: "1px solid #ced4da",
              borderRadius: "4px",
            }}
          />
          <FormHelperText error={phoneError || phone === ""}>
            {(phoneError && "Invalid phone number") ||
              (phone === "" && "Please, provide a valid phone number")}
          </FormHelperText>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError || password === ""}
            helperText={
              password === ""
                ? "Password field cannot be empty"
                : passwordError
                ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={confirmPasswordError || confirmPassword === ""}
            helperText={
              confirmPassword === ""
                ? "Confirm Password field cannot be empty"
                : confirmPasswordError
                ? "Passwords do not match"
                : ""
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              isLoading ||
              emailError ||
              phoneError ||
              passwordError ||
              confirmPasswordError ||
              email === "" ||
              phone === "" ||
              password === "" ||
              confirmPassword === ""
            }
            sx={{
              mt: 3,
              mb: 2,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)", // A gradient to give a 3D effect
              boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)", // A shadow to enhance the 3D effect
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ManagerSignUp;

import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  ThemeProvider,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import { GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";
import axiosInstance from "../components/axiosInstance";
import markerIcon from "../deliverer.svg";
import { DrawerContext } from "../DrawerContext";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { AuthContext } from "../components/AuthProvider";
import backgroundImage from "../background.jpeg";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

const Dashboard = ({ user }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [courierData, setCourierData] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const { open, setOpen } = useContext(DrawerContext);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  const [mapCenter, setMapCenter] = useState({
    lat: 21.621757,
    lng: -79.781167,
  });
  const [mapZoom, setMapZoom] = useState(7);
  const mapRef = useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    axiosInstance
      .get("/couriers/get_all_couriers")
      .then((response) => {
        setCourierData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const handleCourierClick = (courier) => {
    setSelectedCourier(courier);
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: theme.palette.background.default,
            width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
            ml: open ? "240px" : "0",
            transition: theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          anchor="left"
          sx={{
            width: open ? "240px" : theme.spacing(7),
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? "240px" : theme.spacing(7),
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              boxSizing: "border-box",
              overflowX: "hidden",
              bgcolor: "rgb(20, 20, 20)",
              color: theme.palette.common.white,
            },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleToggle}
            sx={{
              mt: 2,
              ml: 0.5,
              display: "block",
              color: theme.palette.common.white,
              borderRadius: "25%",
              // padding: theme.spacing(1),
              // '&:hover': { backgroundColor: theme.palette.grey[300] }
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, p: 2, color: 'white' }}>
          Menu
        </Typography> */}
          <List>
            {menuItems.map(({ text, icon, path }) => (
              <ListItem
                button
                key={text}
                onClick={() => handleMenuClick(path)}
                selected={location.pathname === path}
              >
                <ListItemIcon
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    display: "ruby",
                    borderRadius: "25%",
                    color: theme.palette.common.white,
                  }}
                >
                  {icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={text}
                    sx={{
                      mt: 1,
                    }}
                  />
                )}
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: "auto" }}>
            <Divider />
            <List>
              <ListItem button onClick={() => handleMenuClick("/settings")}>
                <ListItemIcon sx={{ color: "white" }}>
                  {" "}
                  <SettingsDrawerIcon
                    style={{
                      fill: "white",
                      height: "35",
                      width: "35",
                      marginLeft: "-5",
                    }}
                  />{" "}
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemIcon sx={{ color: "white" }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, mt: 8, p: 3 }}>
          <IconButton
            onClick={handleToggle}
            sx={{
              position: "absolute",
              top: 15,
              left: open ? "10px" : "20px",
              transition: "left 0.3s",
            }}
          >
            <MenuIcon />
          </IconButton>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "500px" }}
            center={mapCenter}
            zoom={mapZoom}
            onCenterChanged={() => {
              if (mapRef.current) {
                const newCenter = mapRef.current.getCenter();
                setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
              }
            }}
            onZoomChanged={() => {
              if (mapRef.current) {
                setMapZoom(mapRef.current.getZoom());
              }
            }}
            mapTypeId={"satellite"}
          >
            {courierData.map(
              (courier) =>
                courier.location_latitude &&
                courier.location_longitude && (
                  <MarkerF
                    key={courier.email}
                    position={{
                      lat: Number(courier.location_latitude),
                      lng: Number(courier.location_longitude),
                    }}
                    onClick={() => handleCourierClick(courier)}
                    onMouseOver={() => setHoveredMarker(courier.email)}
                    onMouseOut={() => setHoveredMarker(null)}
                    icon={{
                      url: markerIcon,
                      scaledSize:
                        hoveredMarker === courier.email
                          ? new window.google.maps.Size(50, 50)
                          : new window.google.maps.Size(40, 40),
                    }}
                  />
                )
            )}

            {selectedCourier && (
              <InfoWindow
                position={{
                  lat: Number(selectedCourier.location_latitude),
                  lng: Number(selectedCourier.location_longitude),
                }}
                onCloseClick={() => setSelectedCourier(null)}
              >
                <div>
                  <h2>{`${selectedCourier.first_name} ${selectedCourier.last_name}`}</h2>
                  {/* Display other courier details here */}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { Paper, IconButton, Typography, Grid, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PackageDetails = ({
  formData,
  shipmentTypes,
  handleClose,
  handleInputChange,
  handleFormCompletion,
}) => {
  // State variables to hold individual shipment details
  const [departureDay, setDepartureDay] = useState("");
  const [pricePerPound, setPricePerPound] = useState("");
  const [dimensionalWeight, setDimensionalWeight] = useState("");
  const [perKmRate, setPerKmRate] = useState("");
  const [estDeliveryTime, setEstDeliveryTime] = useState("");

  // Function to map package types to emojis
  const mapPackageTypeToEmoji = (type) => {
    const emojiMap = {
      ENVELOPE: "✉️",
      PACKAGE: "🛍️",
      BOX: "📦",
      PALLET: "📋",
      CRATE: "🚧",
      OTHER: "🔍",
    };
    return `${emojiMap[type] || ""} ${type}`;
  };

  useEffect(() => {
    // Checking if shipment_type_name is not empty and then fetching details
    if (formData.shipment_type_name) {
      const foundShipmentType = shipmentTypes.find(
        (shipmentType) =>
          shipmentType.shipment_type_name === formData.shipment_type_name
      );
      if (foundShipmentType) {
        setDepartureDay(foundShipmentType.departure_day);
        setPricePerPound(foundShipmentType.price_per_pound);
        setEstDeliveryTime(foundShipmentType.est_delivery_time);
        setDimensionalWeight(foundShipmentType.dimensional_weight);
        setPerKmRate(foundShipmentType.per_km_rate);
        handleFormCompletion(true);
      }
    } else {
      handleFormCompletion(false);
    }
  }, [formData.shipment_type_name, handleFormCompletion, shipmentTypes]);

  // Function to clear form fields
  const clearForm = () => {
    handleInputChange({
      target: {
        name: "shipment_type_name",
        value: "",
      },
    });
    handleFormCompletion(false);
  };

  // Function to handle shipment selection
  const handleShipmentSelect = (event, value, reason) => {
    if (reason === "selectOption") {
      // Set values upon selection
      handleInputChange({
        target: {
          name: "shipment_type_id",
          value: value.shipment_type_id,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_name",
          value: value.shipment_type_name,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_price_per_pound",
          value: value.price_per_pound,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_dimensional_weight",
          value: value.dimensional_weight,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_per_km_rate",
          value: value.per_km_rate,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_est_delivery_time",
          value: value.est_delivery_time,
        },
      });
      handleInputChange({
        target: {
          name: "shipment_type_package_types",
          value: value.package_type,
        },
      });
      handleFormCompletion(true);
    }
    if (reason === "clear") {
      clearForm();
    }
  };

  const formStyle = {
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#F9F9F9",
  };
  const titleStyle = { marginBottom: "30px", fontWeight: 500, color: "#333" };
  const subtextStyle = { marginBottom: "30px", fontWeight: 300, color: "#666" };

  return (
    <Paper style={formStyle}>
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        edge="end"
        color="default"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" style={titleStyle}>
        Shipment Type
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={subtextStyle}>
            Please, select a shipment type:
          </Typography>
          <Autocomplete
            options={shipmentTypes}
            getOptionLabel={(option) => option.shipment_type_name}
            value={
              shipmentTypes.find(
                (item) =>
                  item.shipment_type_name === formData.shipment_type_name
              ) || null
            }
            onChange={handleShipmentSelect}
            renderInput={(params) => (
              <TextField {...params} label="Shipment Type" variant="outlined" />
            )}
          />
        </Grid>
        {/* Various shipment details displayed here */}
        <Grid item xs={12}>
          <Typography>🗓️ Departure Day: {departureDay}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>🕰️ Estimated Delivery: {estDeliveryTime}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>💰 Per Pound Rate: {pricePerPound}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            ⚖️ Dimensional Weight Price: {dimensionalWeight}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>🛣️ Per Km Rate: {perKmRate}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            📋 Package Types:{" "}
            {shipmentTypes
              .find(
                (item) =>
                  item.shipment_type_name === formData.shipment_type_name
              )
              ?.package_type.map(mapPackageTypeToEmoji)
              .join(", ")}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PackageDetails;

import axios from "axios";

const instance = axios.create({
  // Dev:
  // baseURL: process.env.REACT_APP_BACKEND_URL,
  baseURL: "https://api.cexpress.app",
  withCredentials: true,
});

export default instance;

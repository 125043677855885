import React, { useState, useEffect } from "react";
import { GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  ThemeProvider,
  Container,
  createTheme,
  CssBaseline,
  Paper,
} from "@mui/material";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";
import logo from "../logoClean.svg";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../background.jpeg";
import { styled } from "@mui/system";
import markerIcon from "../package_container.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily:
      '"SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
  },
});

const Background = styled("div")({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backdropFilter: "blur(10px)",
  overflow: "auto",
  maxHeight: "200vh",
});

const mapContainerStyle = {
  height: "400px",
  width: "100%",
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
};

const PackageTrackingPage = () => {
  const navigate = useNavigate();
  const { trackingNumber } = useParams();
  const [trackingNumberInput, setTrackingNumberInput] = useState(
    trackingNumber || ""
  );
  const [data, setData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const fetchPackageData = async (packageId) => {
    try {
      const response = await axiosInstance.get(
        `/packages/get_package_tracking/${packageId}`
      );
      setData(response.data);
      // generateAddressFromCoordinates(response.data);
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response?.data?.error || "Error fetching package data";
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  // Transform data.recipient_address_latitude and data.recipient_address_longitude and data.sender_address_latitude and data.sender_address_longitude into readable addresses
  // const generateAddressFromCoordinates = async (data) => {
  //   console.log(data);
  //   // Recipient address
  //   const recipientResponse = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.recipient_address_latitude},${data.recipient_address_longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
  //   );
  //   const recipientCoords = await recipientResponse.json();
  //   const recipientAddress = recipientCoords.results[0].formatted_address;
  //   setRecipientAddress(recipientAddress);
  //   // Sender address
  //   const senderAddress = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.sender_address_latitude},${data.sender_address_longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
  //   );
  //   const senderCoords = await senderAddress.json();
  //   const senderAddressFormatted = senderCoords.results[0].formatted_address;
  //   setSenderAddress(senderAddressFormatted);
  // };

  useEffect(() => {
    if (trackingNumber) {
      fetchPackageData(trackingNumber);
    } else {
      setData(null); // Ensure no previous data is displayed
    }
  }, [trackingNumber]);

  useEffect(() => {
    setTrackingNumberInput(trackingNumber || "");
  }, [trackingNumber]);

  const handleTrack = () => {
    if (trackingNumberInput) {
      navigate(`/track/${trackingNumberInput}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Background>
        <Container maxWidth="md">
          <Button
            onClick={() => navigate("/")}
            color="primary"
            variant="contained"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
            }}
          >
            <img src={logo} alt="CExpress logo" height="30" />
          </Button>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Track Your Package
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                gap={2}
                padding={5}
              >
                <TextField
                  label="Enter Tracking Number"
                  variant="outlined"
                  style={{ width: "350px" }}
                  value={trackingNumberInput}
                  onChange={(e) => setTrackingNumberInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundImage:
                      "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                  }}
                  onClick={handleTrack}
                >
                  Track
                </Button>
              </Box>
            </Grid>

            {data && (
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  borderRadius: "20px",
                  backgroundColor: "rgba(255,255,255,0.8)",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Sender Details:</Typography>
                    <Typography>{`${data.sender_first_name} ${data.sender_last_name}`}</Typography>
                    <Typography>{data.sender_email}</Typography>
                    <Typography>{data.sender_phone}</Typography>
                    <Typography>{data.sender_address}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="h6">Recipient Details:</Typography>
                    <Typography>{`${data.recipient_first_name} ${data.recipient_last_name}`}</Typography>
                    <Typography>{data.recipient_email}</Typography>
                    <Typography>{data.recipient_phone}</Typography>
                    <Typography>{data.recipient_address}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">Processed By:</Typography>
                    <Typography>{data.original_vendor_name}</Typography>
                    <Typography>{data.employee_name}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">Shipment Details:</Typography>
                    <Typography>{`Type: ${data.shipment_type_name}`}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6">Package Details:</Typography>
                    <Typography>{`Package Type: ${data.package_type}`}</Typography>
                    <Typography>{`Delivery Preference: ${data.delivery_preference}`}</Typography>
                    <Typography>{`Status: ${data.status}`}</Typography>
                    <Typography>{`Weight: ${data.weight} lbs`}</Typography>
                    <Typography>{`Additional Charges:`}</Typography>
                    <div className="additional-charges">
                      {JSON.parse(data.additional_charges).map((charge) => {
                        const uniqueKey = Math.random()
                          .toString(36)
                          .substr(2, 9);
                        return (
                          <Typography key={uniqueKey} variant="body2">
                            {charge.type === "Other"
                              ? charge.customName
                              : charge.type}
                            : {charge.amount}
                          </Typography>
                        );
                      })}
                    </div>
                    <Typography>{`Price: $${data.price}`}</Typography>
                    <Typography>{`Date Created: ${new Date(
                      data.created_at
                    ).toLocaleString()}`}</Typography>
                    {data.delivered_at && (
                      <Typography>{`Date Delivered: ${new Date(
                        data.delivered_at
                      ).toLocaleString()}`}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Additional Delivery Instructions:{" "}
                    </Typography>
                    <Typography>
                      {data.additional_delivery_instructions}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <ScrollingBox>
                      <ul>
                        {data.items.map((item, index) => (
                          <li key={index}>
                            {item.name} - Quantity: {item.quantity}
                          </li>
                        ))}
                      </ul>
                    </ScrollingBox>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h5" marginTop={5} marginBottom={2}>
                      Current Location:{" "}
                    </Typography>
                    <div
                      style={{
                        borderRadius: "12px",
                        overflow: "hidden",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={{ lat: data.latitude, lng: data.longitude }}
                        zoom={10}
                        mapTypeId={"satellite"}
                      >
                        <MarkerF
                          position={{
                            lat: Number(data.latitude),
                            lng: Number(data.longitude),
                          }}
                          onClick={() => {
                            setSelectedLocation({
                              lat: Number(data.latitude),
                              lng: Number(data.longitude),
                              status: data.status,
                              status_message: data.status_message,
                              updated_at: data.updated_at,
                            });
                          }}
                          icon={{
                            url: markerIcon,
                            scaledSize: new window.google.maps.Size(50, 50),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(25, 25),
                          }}
                        />
                        {selectedLocation && (
                          <InfoWindow
                            position={{
                              lat: selectedLocation.lat,
                              lng: selectedLocation.lng,
                            }}
                            onCloseClick={() => setSelectedLocation(null)}
                          >
                            <div
                              style={{
                                backgroundColor: "#333",
                                color: "#fff",
                                padding: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              <h2>{`Status: ${selectedLocation.status}`}</h2>
                              <p>{`Status Message: ${selectedLocation.status_message}`}</p>
                              <p>{`Last Updated: ${new Date(
                                selectedLocation.updated_at
                              ).toLocaleString()}`}</p>
                            </div>
                          </InfoWindow>
                        )}
                      </GoogleMap>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Container>
      </Background>
    </ThemeProvider>
  );
};

export default PackageTrackingPage;

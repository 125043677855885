import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";
export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);
  const [userId, setUserId] = useState(null);
  const socket = useRef(null);

  useEffect(() => {
    fetch(`https://api.cexpress.app/auth/user`, {
      // Comment out this line and uncomment the next line for local development
      // fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isAuthenticated) {
          console.log("User authenticated");
          setUser(true);
          setAccessLevel(data.access_level);
          setUserId(data.user_id);
          if (!socket.current) {
            initializeWebSocket();
          }
        } else {
          console.log("User not authenticated");
          setUser(false);
          setAccessLevel(null);
          setUserId(null);
        }
        setAuthCheckComplete(true);
      })
      .catch((err) => {
        console.error(err);
        setAuthCheckComplete(true);
        setAccessLevel(null);
      });
  }, []);

  let reconnectTimeout;

  const initializeWebSocket = () => {
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }

    console.log("Initializing WebSocket connection...");
    // socket.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL); // Comment out this line and uncomment the next line for local development
    socket.current = new WebSocket("wss://api.cexpress.app");

    socket.current.onopen = (event) => {
      console.log("WebSocket connection opened:", event);

      // Clear the reconnect timeout since the connection has opened successfully
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };

    socket.current.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);
      const message = JSON.parse(event.data);
      handleWebSocketMessage(message);
    };

    socket.current.onclose = (event) => {
      console.log(
        "WebSocket closed. Reason:",
        event.reason,
        "Code:",
        event.code
      );

      // Set a timeout for reconnection. If connection fails for 15 seconds, reload the page.
      reconnectTimeout = setTimeout(() => {
        console.log(
          "WebSocket reconnection failed for 15 seconds. Reloading the page."
        );
        window.location.reload();
      }, 15000);

      // Reconnect after a short delay
      setTimeout(initializeWebSocket, 5000);
    };

    socket.current.onerror = (error) => {
      console.error("WebSocket encountered an error:", error);
    };
  };

  const handleWebSocketMessage = (message) => {
    if (message.type === "FORCE_LOGOUT") {
      console.log("Received FORCE_LOGOUT message from server. Logging out...");
      logoutUser();
    }
  };

  const loginUser = (userData) => {
    setUser(true);
    setUserId(userData.user_id);
    setAccessLevel(userData.access_level);
    if (!socket.current) {
      initializeWebSocket();
    }
  };

  const logoutUser = () => {
    setUser(false);
    setUserId(null);
    setAccessLevel(null);
    if (socket.current) {
      socket.current.close();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userId,
        setAuthCheckComplete,
        authCheckComplete,
        accessLevel,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

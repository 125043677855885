import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, createTheme, ThemeProvider, Link as MuiLink } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../components/axiosInstance';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../logoClean.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0070c9',
    },
    secondary: {
      main: '#aeaeae',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/auth/password_reset_email_employee', {
        email,
      });
  
      if (response.status === 200) {
        enqueueSnackbar('Password reset link sent to your email', { variant: 'success' });
        navigate('/login');
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
  
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Button
          onClick={() => navigate('/')}
          color="primary"
          variant="contained"
          sx={{ position: 'absolute', top: 10, left: 10, backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'}}
        >
          <img src={logo} alt="CExpress logo" height="30" /> {/* Adjust the height as necessary */}
        </Button>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{
              mt: 3,
              mb: 2,
              backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)',
              boxShadow: '0 3px 5px 2px rgba(0, 112, 201, .3)',
            }}
          >
            {isLoading ? 'Sending reset link...' : 'Send reset link'}
          </Button>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <MuiLink component={RouterLink} to="/login">Back to login</MuiLink>
          </Box>
        </Box>
      </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;

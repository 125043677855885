import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../components/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Box, Grid, AppBar, Toolbar, IconButton, Button, TextField, Select, MenuItem, Dialog, Divider, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';
import {DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AuthContext } from '../components/AuthProvider';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';


const AccessLevelInfo = () => (
    <div>
        <strong>Employee:</strong>
        <ul>
            <li>Allowed to create packages</li>
            <li>Can enter client info and initial package info</li>
            <li>Assign initial verifier and courier to packages</li>
            <li>Print receipts and QR codes</li>
            <li>View other package related information</li>
            <li>Edit sender and recipient info after package creation</li>
        </ul>
        <strong>Supervisor:</strong>
        <ul>
            <li>Has all permissions of an Employee</li>
            <li>
                <ul>
                    <li>Edit package contents directly</li>
                    <li>Edit critical tracking data such as package status</li>
                </ul>
            </li>
        </ul>
        <strong>Manager:</strong>
        <ul>
            <li>Has all permissions of a Supervisor</li>
            <li>
                <ul>
                    <li>View detailed employee and courier info</li>
                    <li>Accept and deny employee and courier requests</li>
                    <li>Create, edit and remove shipment types</li>
                </ul>
            </li>
        </ul>
        <strong>Admin:</strong>
        <ul>
            <li>Has all permissions of a Manager</li>
            <li>
                <ul>
                    <li>Change access levels of other employees</li>
                    <li>Delete employee accounts</li>
                    <li><strong>Only one Admin per vendor (initial business sign-up)</strong></li>
                    <li><strong>Admin status is non-assignable and irrevocable</strong></li>
                </ul>
            </li>
        </ul>
    </div>
);




const accessLevels = {
    'Employee' : 5,
    'Supervisor': 4,
    'Manager': 3,
};


const EmployeeDetails = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [employeeAccessLevel, setEmployeeAccessLevel] = useState(null);
    const [newAccessLevel, setNewAccessLevel] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { accessLevel } = useContext(AuthContext);


    const handleChangeAccessLevel = (event) => {
        if (data.access_level !== event.target.value) {
            setNewAccessLevel(event.target.value);
            setShowDialog(true);
        }
    };

    const handleConfirmChange = async () => {
        try {
            await axiosInstance.put(`/employees/update_access_level/${id}`, {
                access_level: newAccessLevel
            });
            enqueueSnackbar('Access level updated successfully!', { variant: 'success' });
            // Get the user extended info again
            fetchData();
        } catch (error) {
            enqueueSnackbar('Error updating access level.', { variant: 'error' });
        }
        setShowDialog(false);
    };

    const handleDeleteAccount = async () => {
        try {
            await axiosInstance.put(`/employees/delete/${id}`); // Assuming this endpoint deletes the employee account
            enqueueSnackbar('Employee account deleted successfully!', { variant: 'success' });
            navigate(-1); // Navigate back to the previous page
        } catch (error) {
            enqueueSnackbar('Error deleting the account.', { variant: 'error' });
        }
        setShowDeleteDialog(false);
    };
    

    const handleCancelChange = () => {
        setNewAccessLevel(null);
        setShowDialog(false);
    };

    const navigateToPackage = (id) => {
        navigate(`/packages/${id}`);
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`/employees/extended_info/${id}`);
            
            setData(response.data);
            console.log(response.data);
            console.log(response.data.access_level);
            setEmployeeAccessLevel(response.data.access_level);
            setIsLoading(false);
        } catch (err) {
            
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!data) {
        return (
            <Box sx={{ display: 'flex' }}>
                <Box
                    sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 9999,
                    visibility: isLoading ? 'visible' : 'hidden'
                    }}
                >
                    <CircularProgress />
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" display="flex" width="100%"
            sx={{
                backgroundImage:'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'
            }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                        Back
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ mr:8.0, flexGrow: 1, textAlign: 'left' }}>
                        Employee Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="100%" sx={{ mt: '64px' }}>
                <Paper sx={{ p: 3 }}>
                    {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress />
                    </Box>
                    ) : (
                    <>
                        <Typography variant="h4" gutterBottom component="div">
                            {`${data.first_name} ${data.last_name}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Email: ${data.email}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Phone: ${data.phone}`}
                        </Typography>
                        {employeeAccessLevel > 2 && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    label="Access Level"
                                    value={data.access_level} // set the numeric value here
                                    onChange={handleChangeAccessLevel}
                                    variant="outlined"
                                >
                                {Object.entries(accessLevels).map(([level, value]) => (
                                    <MenuItem key={level} value={value}>{level}</MenuItem> // pass the numeric value as the value
                                ))}
                                </Select>
                                <Tooltip title={<AccessLevelInfo />} placement="top-start" arrow>
                                    <IconButton size="small" style={{ marginLeft: '8px' }}>
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        {/* Confirmation Dialog */}
                        <Dialog
                            open={showDialog}
                            onClose={() => setShowDialog(false)}
                        >
                            <DialogTitle>{"Confirm Access Level Change"}</DialogTitle>
                            <DialogContent>
                                <Typography gutterBottom>
                                    Are you sure you want to change this user's access level from 
                                    <strong>
                                        "{Object.keys(accessLevels).find(level => accessLevels[level] === data.access_level)}"
                                    </strong> 
                                    to 
                                    <strong>
                                        "{Object.keys(accessLevels).find(level => accessLevels[level] === newAccessLevel)}"
                                    </strong>?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleConfirmChange}
                                >
                                    Accept
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    color="secondary" 
                                    onClick={handleCancelChange}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Divider sx={{ my: 2 }} />

                        <TableContainer component={Paper}>
                        <Typography variant="body1" gutterBottom>
                            {`Packages Modified: ${data.total_packages_modified.length}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Last Modified: ${new Date(data.dateLastModified).toLocaleDateString()}`}
                        </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Packages Modified</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.total_packages_modified && data.total_packages_modified.map((pkg) => (
                                    <TableRow key={pkg.package_id} onClick={() => navigateToPackage(pkg.package_id)} style={{cursor: 'pointer'}}>
                                    <TableCell>{pkg.package_id}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider sx={{ my: 2 }} />

                        <TableContainer component={Paper}>
                        <Typography variant="body1" gutterBottom>
                            {`Total Packages Created: ${data.total_packages_created.length}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Total Packages Created Weight: ${data.total_weight} lbs`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Total Packages Created Value: $${data.total_price}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {`Last Created: ${new Date(data.dateLastCreated).toLocaleDateString()}`}
                        </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total Packages</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.total_packages && data.total_packages.map((pkg) => (
                                    <TableRow key={pkg.package_id} onClick={() => navigateToPackage(pkg.package_id)} style={{cursor: 'pointer'}}>
                                    <TableCell>{pkg.package_id}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    )}
                </Paper>
                { employeeAccessLevel !== 2 && (
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        sx={{ mt: 3 }} 
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        Delete Employee Account
                    </Button>
                )}

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={showDeleteDialog}
                    onClose={() => setShowDeleteDialog(false)}
                >
                    <DialogTitle>{"Confirm Account Deletion"}</DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            Are you sure you want to delete this employee's account? This action cannot be undone.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleDeleteAccount}
                        >
                            Delete
                        </Button>
                        <Button 
                            variant="outlined" 
                            onClick={() => setShowDeleteDialog(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
};

export default EmployeeDetails;

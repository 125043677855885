import React, { useState, useContext } from "react";
import { SnackbarProvider } from "notistack";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import BusinessRegister from "./pages/BusinessRegister";
import ManagerSignUp from "./pages/ManagerSignUp";
import Home from "./pages/Home";
import Login from "./pages/login";
import SignUp from "./pages/signup";
import Verify from "./components/Verify";
import VerifyEmailUpdate from "./components/VerifyEmailUpdate";
import Dashboard from "./pages/Dashboard";
import Packages from "./pages/Packages";
import PrivatePage from "./pages/samplePrivate";
import PackagePage from "./pages/PackagePage";
import CourierPage from "./pages/CourierPage";
import CourierDetailPage from "./pages/CourierDetailPage";
import EmployeePage from "./pages/EmployeePage";
import EmployeeDetailPage from "./pages/EmployeeDetailPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SettingsPage from "./pages/SettingsPage";
import PrintPage from "./pages/PrintPage";
import PrintQRCode from "./components/PrintQrCode";
import PackageTrackingPage from "./components/Tracking";
import ShipmentOptionsPage from "./components/VendorShipments";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AppDownload from "./pages/AppDownload";
import OtherPackages from "./pages/OtherVendorsPackages";
import CustomerPage from "./pages/CustomerPage";
import OtherVendorsPackagePage from "./pages/OtherVendorsPackagesPage";
import PackagePayments from "./pages/PackagePayments";
import UserLogin from "./pages/UserLogin";
import UsersHomeOptions from "./pages/UsersHomeOptions";
import UserSignup from "./pages/UserSignup";
import ForgotPasswordUser from "./pages/ForgotPasswordUser";
import UserHomePage from "./pages/UserHomePage";
import UserProfilePage from "./pages/UserProfilePage";
import Manifests from "./pages/Manifests";
import ManifestDetails from "./pages/ManifestPage";

import "./App.css";
import { BusinessRegistrationContext } from "./BusinessRegistrationContext";
import { DrawerContextProvider } from "./DrawerContextProvider";
import { AuthProvider, useAuth } from "./components/AuthProvider";
import { FormDataProvider } from "./FormDataContext";

const googleMapsApiKey = "AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0";
const libraries = ["places"];

// PrivateRoute and PublicRoute components are used to restrict access to certain pages based on whether the user is logged in or not.
function PrivateRoute({ element }) {
  const auth = useAuth();

  if (!auth.authCheckComplete) {
    return null; // or some loading spinner
  }

  if (!auth.user) {
    return <Navigate to="/" />;
  }

  return element;
}

function PublicRoute({ element }) {
  const { user, authCheckComplete, accessLevel } = useAuth();

  return authCheckComplete ? (
    !user ? (
      element
    ) : (
      <Navigate
        to={{
          pathname: accessLevel === null ? "/user_dashboard" : "/dashboard",
          state: { from: window.location.pathname },
        }}
        replace
      />
    )
  ) : null;
}

function AnyRoute({ element }) {
  const auth = useAuth();
  return auth.authCheckComplete ? element : null;
}

// A route component that redirects to business registration page if business is not registered while in the process of registering a manager
function BusinessRegistrationRoute({ element }) {
  const { businessRegistered } = useContext(BusinessRegistrationContext);
  const auth = useAuth();

  if (auth.authCheckComplete && !auth.user) {
    // Check if not authenticated (public access)
    return businessRegistered ? (
      element
    ) : (
      <Navigate
        to={{
          pathname: "/register_business",
          state: { from: window.location.pathname },
        }}
        replace
      />
    );
  } else {
    return null;
  }
}

function App() {
  const [businessRegistered, setBusinessRegistered] = useState(false);

  return (
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        <DrawerContextProvider>
          <Router>
            <LoadScript
              googleMapsApiKey={googleMapsApiKey}
              libraries={libraries}
            >
              <div className="App">
                {}

                <BusinessRegistrationContext.Provider
                  value={{ businessRegistered, setBusinessRegistered }}
                >
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route
                      path="/register_business"
                      element={<PublicRoute element={<BusinessRegister />} />}
                    />
                    <Route
                      path="/register_business/manager"
                      element={
                        <BusinessRegistrationRoute
                          element={<ManagerSignUp />}
                        />
                      }
                    />
                    <Route
                      path="/privacy_policy"
                      element={<PublicRoute element={<PrivacyPolicy />} />}
                    />
                    <Route
                      path="/app_download"
                      element={<PublicRoute element={<AppDownload />} />}
                    />
                    <Route
                      path="/login"
                      element={<PublicRoute element={<Login />} />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<PublicRoute element={<ForgotPassword />} />}
                    />
                    <Route
                      path="/resetPassword"
                      element={<PublicRoute element={<ResetPassword />} />}
                    />
                    <Route
                      path="/signup"
                      element={<PublicRoute element={<SignUp />} />}
                    />
                    <Route
                      path="/verify"
                      element={<PublicRoute element={<Verify />} />}
                    />
                    <Route
                      path="/verify_email"
                      element={<AnyRoute element={<VerifyEmailUpdate />} />}
                    />
                    <Route
                      path="/track"
                      element={<AnyRoute element={<PackageTrackingPage />} />}
                    />
                    <Route
                      path="/shipment_options"
                      element={<AnyRoute element={<ShipmentOptionsPage />} />}
                    />
                    <Route
                      path="/track/:trackingNumber"
                      element={<AnyRoute element={<PackageTrackingPage />} />}
                    />
                    <Route
                      path="/settings"
                      element={<PrivateRoute element={<SettingsPage />} />}
                    />
                    <Route
                      path="/dashboard"
                      element={<PrivateRoute element={<Dashboard />} />}
                    />
                    <Route
                      path="/packages"
                      element={
                        <PrivateRoute
                          element={
                            <FormDataProvider>
                              <Packages />
                            </FormDataProvider>
                          }
                        />
                      }
                    />
                    <Route
                      path="/packages/:id"
                      element={
                        <PrivateRoute
                          element={
                            <PackagePage googleMapsApiKey={googleMapsApiKey} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/manifests"
                      element={<PrivateRoute element={<Manifests />} />}
                    />
                    <Route
                      path="/manifests/:id"
                      element={<PrivateRoute element={<ManifestDetails />} />}
                    />
                    <Route
                      path="/couriers"
                      element={<PrivateRoute element={<CourierPage />} />}
                    />
                    <Route
                      path="/couriers/:id"
                      element={<PrivateRoute element={<CourierDetailPage />} />}
                    />
                    <Route
                      path="/customers"
                      element={<PrivateRoute element={<CustomerPage />} />}
                    />
                    <Route
                      path="/private"
                      element={<PrivateRoute element={<PrivatePage />} />}
                    />
                    <Route
                      path="/other_packages"
                      element={<PrivateRoute element={<OtherPackages />} />}
                    />
                    <Route
                      path="/other_packages/:id"
                      element={
                        <PrivateRoute element={<OtherVendorsPackagePage />} />
                      }
                    />
                    <Route
                      path="/employees"
                      element={<PrivateRoute element={<EmployeePage />} />}
                    />
                    <Route
                      path="/employees/:id"
                      element={
                        <PrivateRoute element={<EmployeeDetailPage />} />
                      }
                    />
                    <Route
                      path="/printQR"
                      element={<PrivateRoute element={<PrintPage />} />}
                    />
                    <Route
                      path="/package_payments"
                      element={<PrivateRoute element={<PackagePayments />} />}
                    />
                    <Route
                      path="/print-qr-code"
                      element={<PrivateRoute element={<PrintQRCode />} />}
                    />
                    {/* <Route
                      path="/user_login"
                      element={<PublicRoute element={<UserLogin />} />}
                    />
                    <Route
                      path="/user_signup"
                      element={<PublicRoute element={<UserSignup />} />}
                    />
                    <Route
                      path="/users_home_options"
                      element={<PublicRoute element={<UsersHomeOptions />} />}
                    />
                    <Route
                      path="/forgot_password_user"
                      element={<PublicRoute element={<ForgotPasswordUser />} />}
                    />
                    <Route
                      path="/user_dashboard"
                      element={<PrivateRoute element={<UserHomePage />} />}
                    />
                    <Route
                      path="/user_account"
                      element={<PrivateRoute element={<UserProfilePage />} />}
                    /> */}
                    <Route
                      path="/"
                      element={<PublicRoute element={<Home />} />}
                    />
                    <Route path="*" element={<h1>Not Found</h1>} />
                  </Routes>
                </BusinessRegistrationContext.Provider>
              </div>
            </LoadScript>
          </Router>
        </DrawerContextProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "../components/axiosInstance";
import { useSnackbar } from "notistack";

const DeliveryOptions = ({
  formData,
  couriers,
  handleInputChange,
  handleClose,
}) => {
  const [thirdPartyShipping, setThirdPartyShipping] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axiosInstance.get(
          "/vendor/get_vendors_that_deliver_in_cuba"
        );
        setVendors(response.data);

        if (formData.active_vendor_id) {
          setThirdPartyShipping(true);
          const vendor = response.data.find(
            (v) => v.vendor_id === formData.active_vendor_id
          );
          setSelectedVendor(vendor || null);
        } else {
          setThirdPartyShipping(false);
          setSelectedVendor(null);
        }
      } catch (error) {
        console.error("Error fetching vendors:", error);
        enqueueSnackbar("Error fetching vendors.", { variant: "error" });
      }
    };
    fetchVendors();
  }, [formData.active_vendor_id, enqueueSnackbar]);

  const handleMultipleCourierChange = (event, value, reason) => {
    const couriersArray = value.map((courier) => ({
      courier_id: courier.courier_id,
      first_name: courier.first_name,
      last_name: courier.last_name,
      email: courier.courier_email,
      phone: courier.courier_phone,
    }));

    handleInputChange({ target: { name: "couriers", value: couriersArray } });

    if (value.length > 0) {
      setThirdPartyShipping(false);
      setSelectedVendor(null);
      handleInputChange({ target: { name: "active_vendor_id", value: "" } });
      handleInputChange({ target: { name: "active_vendor_name", value: "" } });
    }
  };

  const handleThirdPartyVendorChange = (event, newValue) => {
    setSelectedVendor(newValue);
    if (newValue) {
      setThirdPartyShipping(true);
      handleInputChange({ target: { name: "couriers", value: [] } });
      handleInputChange({
        target: { name: "active_vendor_id", value: newValue.vendor_id },
      });
      handleInputChange({
        target: { name: "active_vendor_name", value: newValue.vendor_name },
      });
    } else {
      setThirdPartyShipping(false);
      handleInputChange({ target: { name: "active_vendor_id", value: "" } });
      handleInputChange({ target: { name: "active_vendor_name", value: "" } });
    }
  };

  const toggleThirdPartyShipping = () => {
    const newStatus = !thirdPartyShipping;
    setThirdPartyShipping(newStatus);
    if (newStatus) {
      setSelectedVendor(null);
      handleInputChange({ target: { name: "couriers", value: [] } });
    } else {
      handleInputChange({ target: { name: "active_vendor_id", value: "" } });
      handleInputChange({ target: { name: "active_vendor_name", value: "" } });
    }
  };

  const formStyle = {
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#F9F9F9",
  };
  const titleStyle = { marginBottom: "30px", fontWeight: 500, color: "#333" };

  return (
    <Paper style={formStyle}>
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        edge="end"
        color="default"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" style={titleStyle}>
        Delivery Options
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Couriers</Typography>
          <Autocomplete
            multiple
            options={couriers}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            isOptionEqualToValue={(option, value) =>
              option.courier_id === value.courier_id
            }
            value={formData.couriers || []}
            onChange={handleMultipleCourierChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={thirdPartyShipping}
                onChange={toggleThirdPartyShipping}
              />
            }
            label="Enable 3rd Party Shipping"
          />
          {thirdPartyShipping && (
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) => option.vendor_name}
              value={selectedVendor}
              onChange={handleThirdPartyVendorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Vendor"
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DeliveryOptions;

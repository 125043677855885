import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { useFormData } from "../FormDataContext";
import { enqueueSnackbar } from "notistack";
import SenderInfoForm from "../components/SenderInfoForm";
import PackageDetails from "../components/PackageDetails";
import RecipientInfoForm from "../components/RecipientInfoForm";
import PackageContents from "../components/PackageContents";
import DeliveryOptions from "../components/DeliveryOptions";
import ReviewForm from "../components/ReviewForm";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import PackageReceipt from "../components/PackageReceipt";
import Modal from "react-modal";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  Tooltip,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Step,
  StepLabel,
  Stepper,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  FormControl,
  Checkbox,
  Tab,
  Tabs,
} from "@mui/material";

// TODO: add session storage for package data
// TODO: add current location to package data

const Packages = ({ history }) => {
  const theme = useTheme();
  const location = useLocation();
  const [packages, setPackages] = useState([]);
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [customerEmails, setCustomerEmails] = useState([]);
  const [customerPhones, setCustomerPhones] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const { formData, handleInputChange, clearForm, setFormData } = useFormData();
  const [sortConfig, setSortConfig] = useState({
    key: localStorage.getItem("sortKey") || "created_at",
    direction: localStorage.getItem("sortDirection") || "asc",
  });
  const [filter, setFilter] = useState(localStorage.getItem("filter") || "");
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );

  let [sortedPackages, setSortedPackages] = useState([]);
  const [displayPackages, setDisplayPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [currPackageId, setCurrPackageId] = useState(null);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  // Batch actions
  let [selectedPackages, setSelectedPackages] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [batchAction, setBatchAction] = useState("");
  // Batch action dialog
  const [isBatchDialogOpen, setBatchDialogOpen] = useState(false);
  const [batchDialogType, setBatchDialogType] = useState(""); // 'assi' or 'veri'
  const [selectedBatchCourier, setSelectedBatchCourier] = useState("");
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // Manifests
  const currentTab = location.pathname === "/manifests" ? 1 : 0;
  const [tabValue, setTabValue] = useState(currentTab);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Navigate to the correct tab
    switch (newValue) {
      case 0:
        navigate(`/packages`);
        break;
      case 1:
        navigate(`/manifests`);
        break;
      default:
        navigate(`/packages`);
    }
  };

  useEffect(() => {
    // Initialize page and rowsPerPage from localStorage
    const savedPage = parseInt(localStorage.getItem("currentPage"), 10) || 0;
    const savedRowsPerPage =
      parseInt(localStorage.getItem("currentRowsPerPage"), 10) || 20;
    setPage(savedPage);
    setRowsPerPage(savedRowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("currentPage", newPage.toString());
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page changes
    localStorage.setItem("currentRowsPerPage", newRowsPerPage.toString());
    localStorage.setItem("currentPage", "0");
  };

  const handleBatchActionChange = (event) => {
    const action = event.target.value;
    if (action === "shipped") {
      handleBatchAction("shipped");
    }
    if (action === "assi") {
      handleBatchAction("assi");
    }
    if (action === "delivered") {
      handleBatchAction("delivered");
    }
    setBatchAction(""); // reset the dropdown to its initial state after action
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/customers/get_all_customers");
      if (response.status === 200) {
        let emails = response.data.emails;
        // Iterate over phones and remove leading + for each phone number
        let phones = response.data.phones;
        // remove leading + for each phone numbe
        setCustomerEmails(emails);
        // Assuming you've also defined setCustomerPhones
        setCustomerPhones(phones);
      } else {
        console.error(response.data.message);
        enqueueSnackbar(
          "Error occurred while getting customer emails and phone numbers",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Error occurred while getting customer emails and phone numbers",
        { variant: "error" }
      );
    }
  };

  const fetchCouriers = async () => {
    try {
      const response = await axiosInstance.get("/couriers/get_couriers");
      if (response.status === 200) {
        setCouriers(response.data);
        console.log("Couriers:", response.data);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting couriers", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting couriers", {
        variant: "error",
      });
    }
  };

  const fetchShipmentTypes = async () => {
    try {
      const response = await axiosInstance.get("/packages/get_shipments");
      if (response.status === 200) {
        setShipmentTypes(response.data);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting shipment types", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting shipment types", {
        variant: "error",
      });
    }
  };

  const handleFormCompletion = (complete) => {
    setIsFormComplete(complete);
  };

  const steps = [
    "Sender Info",
    "Recipient Info",
    "Package Details",
    "Package Contents",
    "Delivery Options",
    "Review",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigateToCreatePackage = () => {
    setOpenDialog(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    Promise.all([
      fetchPackages(),
      fetchCustomers(),
      fetchShipmentTypes(),
      fetchCouriers(),
    ]).then(() => {
      setIsLoading(false);
      clearForm();
    });
  }, []);

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const handleClose = () => {
    clearForm();
    setActiveStep(0);
    setOpenDialog(false);
  };

  const fetchPackages = async () => {
    try {
      const response = await axiosInstance.get("/packages/get_all");
      console.log("Packages:", response.data);
      setPackages(response.data);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const navigateToPackageDetails = (id) => {
    navigate(`/packages/${id}`);
  };

  const displayReceipt = (id) => {
    setCurrPackageId(id);
    setIsReceiptOpen(true);
  };

  const handleReceiptClose = () => {
    setIsReceiptOpen(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        "/packages/send_package_receipt/" + currPackageId
      );
      if (response.status === 200) {
        setIsLoading(false);
        enqueueSnackbar("Email sent successfully!", {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar("Failed to send email", {
          variant: "error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar("Failed to send email", {
        variant: "error",
      });
    }
  };

  const createNewPackage = async (passedData) => {
    try {
      setIsLoading(true);
      // send a POST request to the server
      const response = await axiosInstance.post(
        "/packages/create_package",
        passedData
      );
      if (response.status === 200) {
        // Package created successfully
        setIsLoading(false);
        // Display receipt
        displayReceipt(response.data.package_id);
        handleClose();
        fetchPackages();
        enqueueSnackbar("Package created successfully!", {
          variant: "success",
        });
      } else {
        // Package creation failed
        setIsLoading(false);
        const errorMessage = response.data.error || "Unknown error";
        console.error("Failed to create package:", errorMessage);
        enqueueSnackbar(`Failed to create package: ${errorMessage}`, {
          variant: "error",
        });
      }
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.error || error.message || "Unknown error";
      console.error("Failed to create package:", errorMessage);
      enqueueSnackbar(`Failed to create package: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const createOrUpdateSender = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let customerData = {
          customer_id: formData.sender_id,
          customer_first_name: formData.sender_first_name,
          customer_last_name: formData.sender_last_name,
          customer_email: formData.sender_email,
          customer_phone: formData.sender_phone,
          customer_address_latitude: formData.sender_address_latitude,
          customer_address_longitude: formData.sender_address_longitude,
          customer_address: formData.sender_address,
          customer_foreign_id: formData.sender_foreign_id,
        };
        if (formData.new_sender) {
          try {
            setIsLoading(true);
            const response = await axiosInstance.post(
              "/customers/create_customer_from_vendor",
              customerData
            );

            console.log("response", response);
            console.log("response.data", response.data);

            const updatedFormData = {
              ...formData,
              sender_id: response.data.customer_id,
              new_sender: false,
            };
            setIsLoading(false);
            setFormData(updatedFormData);
            fetchCustomers();
            resolve(updatedFormData); // Resolve with updated formData for chaining
          } catch (error) {
            const errorMessage =
              error.response?.data?.error || error.message || "Unknown error";
            console.error("Failed to create sender:", errorMessage);
            enqueueSnackbar(`Failed to create sender: ${errorMessage}`, {
              variant: "error",
            });
            setActiveStep(0);
            reject(errorMessage); // Reject in case of error
            return;
          }
        } else if (formData.update_sender_info) {
          try {
            setIsLoading(true);
            await axiosInstance.put(
              "/customers/update_customer_from_vendor",
              customerData
            );

            const updatedFormData = {
              ...formData,
              update_sender_info: false,
            };
            setIsLoading(false);
            setFormData(updatedFormData);
            fetchCustomers();
            resolve(updatedFormData); // Resolve with updated formData for chaining
          } catch (error) {
            const errorMessage =
              error.response?.data?.error || error.message || "Unknown error";
            console.error("Failed to update sender:", errorMessage);
            enqueueSnackbar(`Failed to update sender: ${errorMessage}`, {
              variant: "error",
            });
            setActiveStep(0);
            reject(errorMessage); // Reject in case of error
            return;
          }
        } else {
          // If no updates were made, still resolve with the current formData
          resolve(formData);
        }
        // TODO: request to send email to customer
      } catch (error) {
        const errorMessage =
          error.response?.data?.error || error.message || "Unknown error";
        console.error("Failed to create or update sender:", errorMessage);
        enqueueSnackbar(`Failed to create or update sender: ${errorMessage}`, {
          variant: "error",
        });
        reject(errorMessage); // Reject the promise in the outer catch
      }
    });
  };

  const createOrUpdateRecipient = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let customerData = {
          customer_id: formData.recipient_id,
          customer_first_name: formData.recipient_first_name,
          customer_last_name: formData.recipient_last_name,
          customer_email: formData.recipient_email,
          customer_phone: formData.recipient_phone,
          customer_address_latitude: formData.recipient_address_latitude,
          customer_address_longitude: formData.recipient_address_longitude,
          customer_address: formData.recipient_address,
          customer_foreign_id: formData.recipient_foreign_id,
        };

        if (formData.new_recipient) {
          try {
            setIsLoading(true);
            const response = await axiosInstance.post(
              "/customers/create_customer_from_vendor",
              customerData
            );

            console.log("response", response);
            console.log("response.data", response.data);

            const updatedFormData = {
              ...formData,
              recipient_id: response.data.customer_id,
              new_recipient: false,
            };
            setIsLoading(false);
            setFormData(updatedFormData);
            fetchCustomers();
            resolve(updatedFormData); // Resolve with updated formData for chaining
          } catch (error) {
            const errorMessage =
              error.response?.data?.error || error.message || "Unknown error";
            console.error("Failed to create recipient:", errorMessage);
            enqueueSnackbar(`Failed to create recipient: ${errorMessage}`, {
              variant: "error",
            });
            setActiveStep(1);
            reject(errorMessage); // Reject in case of error
            return;
          }
        } else if (formData.update_recipient_info) {
          try {
            setIsLoading(true);
            await axiosInstance.put(
              "/customers/update_customer_from_vendor",
              customerData
            );

            const updatedFormData = {
              ...formData,
              update_recipient_info: false,
            };
            setIsLoading(false);
            setFormData(updatedFormData);
            fetchPackages();
            resolve(updatedFormData); // Resolve with updated formData for chaining
          } catch (error) {
            const errorMessage =
              error.response?.data?.error || error.message || "Unknown error";
            console.error("Failed to update recipient:", errorMessage);
            enqueueSnackbar(`Failed to update recipient: ${errorMessage}`, {
              variant: "error",
            });
            setActiveStep(1);
            reject(errorMessage); // Reject in case of error
            return;
          }
        } else {
          // If no updates were made, still resolve with the current formData
          resolve(formData);
        }
        // TODO: request to send email to customer
      } catch (error) {
        const errorMessage =
          error.response?.data?.error || error.message || "Unknown error";
        console.error("Failed to create or update recipient:", errorMessage);
        enqueueSnackbar(
          `Failed to create or update recipient: ${errorMessage}`,
          {
            variant: "error",
          }
        );
        reject(errorMessage); // Reject the promise in the outer catch
      }
    });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    // Update localStorage
    localStorage.setItem("sortKey", key);
    localStorage.setItem("sortDirection", direction);
  };

  sortedPackages = useMemo(() => {
    return [...packages].sort((a, b) => {
      if (sortConfig.key === "weight" || sortConfig.key === "price") {
        let numA = parseFloat(a[sortConfig.key]);
        let numB = parseFloat(b[sortConfig.key]);

        if (numA < numB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (numA > numB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      } else if (
        sortConfig.key === "date_added" ||
        sortConfig.key === "date_updated"
      ) {
        let dateA = new Date(a[sortConfig.key]);
        let dateB = new Date(b[sortConfig.key]);

        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [packages, sortConfig]);

  useEffect(() => {
    let updatedPackages = [...sortedPackages];

    // Filter logic
    if (filter) {
      updatedPackages = updatedPackages.filter((pkg) => pkg.status === filter);
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      updatedPackages = updatedPackages.filter((pkg) => {
        return (
          pkg.employee_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.sender_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.recipient_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.package_id.toLowerCase().includes(lowerSearchTerm) ||
          // Enhanced Search logic for array of courier names
          (pkg.courier_names &&
            pkg.courier_names.some((name) =>
              name.toLowerCase().includes(lowerSearchTerm)
            )) ||
          pkg.shipment_type_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.tracking_number.toLowerCase().includes(lowerSearchTerm) ||
          pkg.status.toLowerCase().includes(lowerSearchTerm) ||
          String(pkg.weight).includes(searchTerm) ||
          String(pkg.price).includes(searchTerm)
        );
      });
    }

    // Reset page number and update localStorage when filter or searchTerm changes
    setPage(0);
    localStorage.setItem("currentPage", "0");

    if (filter) {
      // Update localStorage when filter changes
      localStorage.setItem("filter", filter);
    } else {
      localStorage.setItem("filter", "");
    }

    if (searchTerm) {
      // Update localStorage when searchTerm changes
      localStorage.setItem("searchTerm", searchTerm);
    } else {
      localStorage.setItem("searchTerm", "");
    }

    setDisplayPackages(updatedPackages); // setting the state here
  }, [sortedPackages, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  async function handleBatchAction(actionType) {
    switch (actionType) {
      case "shipped":
        let package_ids = selectedPackages;
        // if any of the package id's is missing a verifier or courier display an error
        for (let i = 0; i < package_ids.length; i++) {
          const package_id = package_ids[i];
          const packageData = packages.find(
            (pkg) => pkg.package_id === package_id
          );
        }
        try {
          await axiosInstance.put("/packages/batch_ship", { package_ids });
          enqueueSnackbar("Packages marked as shipped!", {
            variant: "success",
          });
          fetchPackages();
        } catch (error) {
          const errorMessage =
            error.response?.data?.error || error.message || "Unknown error";
          console.error("Failed to mark packages as shipped:", errorMessage);
          enqueueSnackbar(
            `Failed to mark packages as shipped: ${errorMessage}`,
            { variant: "error" }
          );
        }
        // Clear selections after performing the action
        setSelectedPackages([]);
        setIsAllSelected(false);
        break;
      case "assi":
        setBatchDialogType("assi");
        setBatchDialogOpen(true);
        break;
      case "delivered":
        let delivered_package_ids = selectedPackages;
        // if any of the package id's is missing a verifier or courier display an error
        for (let i = 0; i < delivered_package_ids.length; i++) {
          const package_id = delivered_package_ids[i];
          const packageData = packages.find(
            (pkg) => pkg.package_id === package_id
          );
        }
        try {
          await axiosInstance.put("/packages/batch_mark_as_delivered", {
            delivered_package_ids,
          });
          enqueueSnackbar("Packages marked as delivered!", {
            variant: "success",
          });
          fetchPackages();
        } catch (error) {
          const errorMessage =
            error.response?.data?.error || error.message || "Unknown error";
          console.error("Failed to mark packages as delivered:", errorMessage);
          enqueueSnackbar(
            `Failed to mark packages as delivered: ${errorMessage}`,
            { variant: "error" }
          );
        }
        // Clear selections after performing the action
        setSelectedPackages([]);
        setIsAllSelected(false);
        break;
    }
  }

  const displayedPackages = displayPackages.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Packages" />
          <Tab label="Manifests" />
        </Tabs>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Packages
            </Typography>
            <Button onClick={fetchPackages}>
              <RefreshIcon />
            </Button>
          </Box>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            sx={{ width: "300px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ minWidth: 120, height: "40px" }}>
            <Select
              labelId="filter-label"
              value={filter}
              displayEmpty
              sx={{ height: "40px", width: "150px" }}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="">
                <em>Status...</em>
              </MenuItem>
              <MenuItem value="PROCESSED">📦 Processed</MenuItem>
              <MenuItem value="LABELED">🔖 Labeled</MenuItem>
              <MenuItem value="SHIPPED">✈️ Shipped</MenuItem>
              <MenuItem value="VERIFIED">✔️ Verified</MenuItem>
              <MenuItem value="INCOMPLETE">❗ Incomplete</MenuItem>
              <MenuItem value="DELIVERING">🚚 Delivering</MenuItem>
              <MenuItem value="DELIVERED">✅ Delivered</MenuItem>
              <MenuItem value="RETURNED">↩️ Returned</MenuItem>
              <MenuItem value="CANCELLED">❌ Cancelled</MenuItem>
              <MenuItem value="LOST">🔍 Lost</MenuItem>
              {/* Add more filter options here */}
            </Select>
          </FormControl>

          {accessLevel <= 3 && (
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                labelId="batch-action-label"
                value={batchAction}
                displayEmpty
                disabled={selectedPackages.length === 0}
                sx={{ height: "40px", width: "200px" }}
                onChange={handleBatchActionChange}
              >
                <MenuItem value="">
                  <em>Choose Action...</em>
                </MenuItem>
                <MenuItem value="shipped">Label as Shipped</MenuItem>
                <MenuItem value="delivered">Label as Delivered</MenuItem>
                <MenuItem value="assi">Assign to Courier</MenuItem>
                {/* Add more batch actions here */}
              </Select>
            </FormControl>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={navigateToCreatePackage}
            sx={{
              mt: 1.5,
              mb: 1.5,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
              boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
            }}
          >
            + New Package
          </Button>

          <Dialog
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "90%",
                height: "90%",
                maxHeight: "100%",
                margin: 0,
                p: 0,
                borderRadius: 4,
              },
            }}
            open={openDialog}
            onClose={handleClose}
          >
            <DialogTitle>Create New Package</DialogTitle>
            <DialogContent>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && (
                <SenderInfoForm
                  formData={formData}
                  handleInputChange={handleChange}
                  setFormData={setFormData}
                  handleFormCompletion={handleFormCompletion}
                  customerEmails={customerEmails}
                  customerPhones={customerPhones}
                  handleClose={handleClose}
                />
              )}
              {activeStep === 1 && (
                <RecipientInfoForm
                  formData={formData}
                  handleInputChange={handleChange}
                  setFormData={setFormData}
                  handleFormCompletion={handleFormCompletion}
                  customerEmails={customerEmails}
                  customerPhones={customerPhones}
                  handleClose={handleClose}
                />
              )}
              {activeStep === 2 && (
                <PackageDetails
                  formData={formData}
                  handleInputChange={handleChange}
                  handleFormCompletion={handleFormCompletion}
                  handleClose={handleClose}
                  shipmentTypes={shipmentTypes}
                />
              )}
              {activeStep === 3 && (
                <PackageContents
                  formData={formData}
                  handleInputChange={handleChange}
                  handleFormCompletion={handleFormCompletion}
                  handleClose={handleClose}
                  shipmentTypes={shipmentTypes}
                />
              )}
              {activeStep === 4 && (
                <DeliveryOptions
                  formData={formData}
                  handleInputChange={handleChange}
                  handleFormCompletion={handleFormCompletion}
                  handleClose={handleClose}
                  couriers={couriers}
                />
              )}
              {activeStep === 5 && (
                <ReviewForm
                  formData={formData}
                  setStep={setActiveStep}
                  handleClose={handleClose}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button
                disabled={!isFormComplete}
                onClick={async () => {
                  if (activeStep === steps.length - 1) {
                    try {
                      // Step 1: Create or Update Sender
                      const senderData = await createOrUpdateSender();
                      console.log("senderData", senderData);
                      // Step 2: Create or Update Recipient
                      const recipientData = await createOrUpdateRecipient();
                      console.log("recipientData", recipientData);
                      // Merge senderData and recipientData
                      const mergedData = {
                        ...senderData,
                        ...recipientData,
                        sender_id: senderData.sender_id,
                        recipient_id: recipientData.recipient_id,
                      };

                      // Step 3: Use the mergedData to create a new package
                      await createNewPackage(mergedData);
                    } catch (error) {
                      console.error("Error occurred:", error);
                      // Handle error or display an error message to the user
                    }
                  } else if (activeStep === 0) {
                    handleNext();
                  } else if (activeStep === 1) {
                    handleNext();
                  } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
          // Add transition time here
        }}
      >
        <Dialog
          open={isReceiptOpen}
          onClose={handleReceiptClose}
          aria-labelledby="receipt-dialog"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <PackageReceipt
              packageId={currPackageId}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleReceiptClose}
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
            <Button onClick={handlePrint} color="primary" disabled={isLoading}>
              Print
            </Button>
            <Button
              onClick={handleSendEmail}
              color="primary"
              disabled={isLoading || !currPackageId}
            >
              Send Email
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer>
          <Table sx={{ minWidth: 650, marginTop: 6 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {accessLevel <= 3 && (
                    <Checkbox
                      checked={isAllSelected}
                      onChange={() => {
                        setIsAllSelected(!isAllSelected);
                        setSelectedPackages(
                          isAllSelected
                            ? []
                            : displayPackages.map((pkg) => pkg.package_id)
                        );
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>Action Items</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "created_at"}
                    direction={
                      sortConfig.key === "created_at"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("created_at")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "status"}
                    direction={
                      sortConfig.key === "status" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "employee_name"}
                    direction={
                      sortConfig.key === "employee_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("employee_name")}
                  >
                    Created By
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "sender_name"}
                    direction={
                      sortConfig.key === "sender_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("sender_name")}
                  >
                    Sender
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "recipient_name"}
                    direction={
                      sortConfig.key === "recipient_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("recipient_name")}
                  >
                    Recipient
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "shipment_type_name"}
                    direction={
                      sortConfig.key === "shipment_type_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("shipment_type_name")}
                  >
                    Shipment Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "tracking_number"}
                    direction={
                      sortConfig.key === "tracking_number"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("tracking_number")}
                  >
                    Tracking Number
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === "weight"}
                    direction={
                      sortConfig.key === "weight" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("weight")}
                  >
                    Weight
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === "price"}
                    direction={
                      sortConfig.key === "price" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("price")}
                  >
                    Price
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Receipt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedPackages.map((pkg) => {
                // Check if the package has not been delivered yet
                const currentDate = new Date();
                const estDeliveryDate = new Date(pkg.est_delivery);
                const isDelayed =
                  currentDate > estDeliveryDate && pkg.status !== "DELIVERED";
                let delayInDays = 0;
                if (isDelayed) {
                  delayInDays = Math.ceil(
                    (currentDate - estDeliveryDate) / (1000 * 60 * 60 * 24)
                  );
                }

                // Determine the background color
                let backgroundColor = "inherit";
                if (
                  isDelayed ||
                  pkg.status === "LOST" ||
                  pkg.status === "INCOMPLETE"
                ) {
                  backgroundColor = "rgba(255, 0, 0, 0.2)"; // Red for urgent issues
                } else if (
                  pkg.status !== "DELIVERED" &&
                  (!pkg.courier_names || pkg.courier_names.length === 0)
                ) {
                  backgroundColor = "rgba(255, 165, 0, 0.2)"; // Orange for missing courier
                }

                return (
                  // Check if package is delayed
                  <TableRow
                    key={pkg.package_id}
                    hover
                    onClick={(e) => {
                      if (e.target.type !== "checkbox") {
                        navigateToPackageDetails(pkg.package_id);
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: backgroundColor,
                    }}
                  >
                    <TableCell>
                      {accessLevel <= 3 && (
                        <Checkbox
                          checked={selectedPackages.includes(pkg.package_id)}
                          onChange={() => {
                            const newSelectedPackages =
                              selectedPackages.includes(pkg.package_id)
                                ? selectedPackages.filter(
                                    (id) => id !== pkg.package_id
                                  )
                                : [...selectedPackages, pkg.package_id];
                            setSelectedPackages(newSelectedPackages);
                            setIsAllSelected(
                              newSelectedPackages.length ===
                                displayPackages.length
                            );
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {isDelayed ? (
                        <span style={{ color: "red" }}>
                          Package is delayed by {delayInDays} day
                          {delayInDays !== 1 ? "s" : ""}
                        </span>
                      ) : pkg.status === "LOST" ? (
                        <span style={{ color: "red" }}>Package Lost</span>
                      ) : pkg.status === "INCOMPLETE" ? (
                        <span style={{ color: "red" }}>Package Incomplete</span>
                      ) : !pkg.courier_names ||
                        (pkg.courier_names.length === 0 &&
                          pkg.status !== "DELIVERED") ? (
                        <span style={{ color: "orange" }}>
                          No Courier Assigned
                        </span>
                      ) : (
                        <span style={{ color: "green" }}>
                          All good here! 😊
                        </span>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {new Date(pkg.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        switch (pkg.status) {
                          case "PROCESSED":
                            return "📦 Processed"; // Box icon
                          case "LABELED":
                            return "🔖 Labeled"; // Label icon
                          case "SHIPPED":
                            return "✈️ Shipped"; // Plane taking off icon
                          case "VERIFIED":
                            return "✔️ Verified"; // Check mark icon
                          case "INCOMPLETE":
                            return "❗ Incomplete"; // Exclamation mark icon
                          case "DELIVERING":
                            return "🚚 Delivering"; // Truck icon
                          case "DELIVERED":
                            return "✅ Delivered"; // Check mark icon
                          case "RETURNED":
                            return "↩️ Returned"; // Arrow turning left icon
                          case "CANCELLED":
                            return "❌ Cancelled"; // Cross mark icon
                          case "LOST":
                            return "🔍 Lost"; // Magnifying glass icon
                          default:
                            return pkg.status;
                        }
                      })()}
                    </TableCell>
                    <TableCell>{pkg.employee_name}</TableCell>
                    <TableCell>{pkg.sender_name}</TableCell>
                    <TableCell>{pkg.recipient_name}</TableCell>
                    <TableCell>{pkg.shipment_type_name}</TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        transition: "opacity 0.5s",
                      }}
                    >
                      {pkg.tracking_number}
                    </TableCell>
                    <TableCell align="right">{pkg.weight}</TableCell>
                    <TableCell align="right">{pkg.price}</TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      onMouseEnter={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.cursor = "default";
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrPackageId(pkg.package_id);
                        setIsReceiptOpen(true);
                      }}
                    >
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent TableRow click event
                          setCurrPackageId(pkg.package_id);
                          setIsReceiptOpen(true);
                        }}
                      >
                        <span>📃</span> {/* Replace with your desired icon */}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 80, 100]}
            component="div"
            count={displayPackages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Dialog
          open={isBatchDialogOpen}
          onClose={() => setBatchDialogOpen(false)}
        >
          <DialogTitle>
            {batchDialogType === "assi"
              ? "Choose a Deliverer"
              : batchDialogType === "shipped"
              ? "Verify Shipment"
              : "Confirm Delivery"}
          </DialogTitle>
          <DialogContent>
            <Select
              fullWidth
              value={selectedBatchCourier}
              onChange={(e) => setSelectedBatchCourier(e.target.value)}
            >
              {couriers.map((courier) => (
                <MenuItem key={courier.courier_id} value={courier.courier_id}>
                  {courier.first_name + " " + courier.last_name}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBatchDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                try {
                  setIsLoading(true);
                  if (batchDialogType === "assi") {
                    await axiosInstance.put("/packages/batch_assign_courier", {
                      package_ids: selectedPackages,
                      courier_id: selectedBatchCourier,
                    });
                    enqueueSnackbar(
                      "Packages assigned to courier successfully!",
                      { variant: "success" }
                    );
                  } else if (batchDialogType === "shipped") {
                    await axiosInstance.put("/packages/batch_verify_shipment", {
                      package_ids: selectedPackages,
                      verifier_id: selectedBatchCourier,
                    });
                    enqueueSnackbar("Shipment verified successfully!", {
                      variant: "success",
                    });
                  } else if (batchDialogType === "delivered") {
                    await axiosInstance.put(
                      "/packages/batch_confirm_delivery",
                      {
                        package_ids: selectedPackages,
                        confirmer_id: selectedBatchCourier,
                      }
                    );
                    enqueueSnackbar("Delivery confirmed successfully!", {
                      variant: "success",
                    });
                  }
                  setIsLoading(false);
                  fetchPackages(); // Fetch the updated packages list
                  setBatchDialogOpen(false);
                  setSelectedPackages([]); // Clear selections after performing the action
                  setSelectedBatchCourier("");
                  setIsAllSelected(false);
                } catch (error) {
                  const errorMessage =
                    error.response?.data?.error ||
                    error.message ||
                    "Unknown error";
                  console.error(
                    `Failed to process batch action: ${errorMessage}`
                  );
                  enqueueSnackbar(
                    `Failed to process batch action: ${errorMessage}`,
                    { variant: "error" }
                  );
                }
              }}
              color="primary"
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Packages;

import React, { createContext, useState, useContext } from "react";

const getInitialFormData = () => ({
  // Sender Info
  sender_id: "",
  sender_first_name: "",
  sender_last_name: "",
  sender_email: "",
  sender_phone: "",
  sender_address: "",
  sender_address_latitude: "",
  sender_address_longitude: "",
  sender_foreign_id: "",
  // Recipient Info
  recipient_id: "",
  recipient_first_name: "",
  recipient_last_name: "",
  recipient_email: "",
  recipient_phone: "",
  recipient_address: "",
  recipient_address_latitude: "",
  recipient_address_longitude: "",
  recipient_foreign_id: "",
  // Enrollment Options
  is_sender_enrolled: false,
  is_recipient_enrolled: false,
  sender_enrollment_email: false,
  recipient_enrollment_email: false,
  new_sender: true,
  new_recipient: true,
  update_sender_info: false,
  update_recipient_info: false,
  // Shipment Options
  shipment_type_id: "",
  shipment_type_price_per_pound: "0.00",
  shipment_type_est_delivery_time: "",
  shipment_type_name: "",
  shipment_type_dimensional_weight: "0.00",
  shipment_type_per_km_rate: "0.00",
  shipment_type_package_types: [],
  // Package Info
  package: {
    weight: "0.00",
    length: "0.00",
    width: "0.00",
    height: "0.00",
    package_type: "PACKAGE",
    destination_type: "RESIDENTIAL",
    delivery_preference: "ANYTIME",
    additional_charges: [],
    additional_delivery_instructions: "",
    items: [
      {
        item_id: "",
        name: "",
        description: "",
        barcode: "",
        category: "",
        price: "",
        image_url: "",
        quantity: 1,
        brand: "",
      },
    ],
    price: "0.00",
    comission: "0.00",
    is_fragile: false,
    is_hazardous: false,
    is_perishable: false,
    is_oversize: false,
    is_insured: false,
    is_signature_required: false,
    est_delivery: "",
  },
  couriers: [],
  // 3rd Party Shipper Info
  active_vendor_id: "",
  active_vendor_name: "",
});

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(getInitialFormData());

  const handleInputChange = (event) => {
    setFormData((prevFormData) => {
      const { name, value } = event.target;
      if (name.includes(".")) {
        const [objName, keyName] = name.split(".");
        return {
          ...prevFormData,
          [objName]: { ...prevFormData[objName], [keyName]: value },
        };
      }
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  const clearForm = () => {
    setFormData(getInitialFormData());
  };

  const value = { formData, setFormData, handleInputChange, clearForm };

  return (
    <FormDataContext.Provider value={value}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);

import React, { useContext, useEffect, useState, useRef } from "react";
import axiosInstance from "../components/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Icon,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import markerIcon from "../package_container.svg";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import ReceiptPopupVendor from "../components/PackageReceiptVendor";
import QRCodeItemPage from "../components/ItemsReceiptVendor";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import { isValidNumber, parse } from "libphonenumber-js";
import { AuthContext } from "../components/AuthProvider";
import PlacesAutocomplete from "react-places-autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-input-2";
import cloneDeep from "lodash/cloneDeep";
import PackageReceipt from "../components/PackageReceipt";
import { useSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LocationTrail from "../utils/LocationTrail";

// TODO: Handle edit logic for each field
// TODO: add email input check

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const mapStyles = {
  borderRadius: "15px",
  // Add any other desired properties here.
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden", // This ensures the borderRadius affects the inner map.
  height: "200px",
  width: "100%",
};

const OtherVendorsPackagePage = ({ googleMapsApiKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [currentLocation, setCurrentLocation] = useState("");
  const [couriers, setCouriers] = useState([]);
  const courierMapping = couriers.reduce((acc, cur) => {
    acc[cur.courier_id] = cur;
    return acc;
  }, {});
  const [packageStatuses, setPackageStatuses] = useState([
    "PROCESSED",
    "VERIFIED",
    "DELIVERING",
    "RETURNED",
    "CANCELLED",
    "LOST",
    "DELIVERED",
    "SHIPPED",
    "INCOMPLETE",
    "LABELED",
  ]);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  // Api response data
  const [packageData, setPackageData] = useState(null);
  const [courierInPossession, setCourierInPossession] = useState(null);

  // Function to map package types to emojis
  const mapPackageTypeToEmoji = (type) => {
    const emojiMap = {
      ENVELOPE: "✉️",
      PACKAGE: "🛍️",
      BOX: "📦",
      PALLET: "📋",
      CRATE: "🚧",
      OTHER: "🔍",
    };
    return `${emojiMap[type] || ""} ${type}`;
  };

  const destinationTypeOptions = (type) => {
    const destinationTypeMap = {
      RESIDENTIAL: "🏠",
      COMMERCIAL: "🏢",
      OTHER: "🔍",
    };
    return `${destinationTypeMap[type] || ""} ${type}`;
  };

  const deliveryPreferenceOptions = (type) => {
    const deliveryPreferenceMap = {
      ANYTIME: "🕒",
      MORNING: "🌅",
      AFTERNOON: "🌇",
      EVENING: "🌃",
    };
    return `${deliveryPreferenceMap[type] || ""} ${type}`;
  };

  // Audit Trails
  const [locationAuditTrail, setLocationAuditTrail] = useState([]);

  const navigate = useNavigate();
  // map related state
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  // Assuming you have a state to control the visibility of the courier dialog
  const [showCourierDialog, setShowCourierDialog] = useState(false);

  const handleStatusSelect = (event) => {
    const newStatus = event.target.value;
    if (newStatus === packageData.status) return; // No action if the status hasn't changed

    if (newStatus === "DELIVERING") {
      // Show dialog for selecting couriers and courier in possession
      setShowCourierDialog(true);
    } else if (newStatus === "DELIVERED") {
      const confirmDelivered = window.confirm(
        "Setting the status to DELIVERED is irreversible. Continue?"
      );
      if (confirmDelivered) {
        setPackageData((prevData) => ({
          ...prevData,
          status: newStatus,
          courier_in_possession_id: null, // Set to null when status is delivered
        }));
        updateStatus(newStatus);
      }
    } else {
      // For other statuses, directly update
      updateStatus(newStatus);
    }
  };

  // Update Status
  const updateStatus = async (newStatus) => {
    setPackageData((prevData) => ({ ...prevData, status: newStatus }));
    await handleStatusUpdate(newStatus);
  };

  const fetchPackage = async () => {
    try {
      // Fetch package data
      const packageResponse = await axiosInstance.get(
        `/packages/other_vendor_package/${id}`
      );
      let newPackageData = packageResponse.data.package;
      newPackageData.couriers = packageResponse.data.couriers;

      setTempCouriers(packageResponse.data.couriers);

      setLocationAuditTrail(packageResponse.data.packageLocationAuditTrail);
      console.log("Audit trail is: ", packageResponse.data.packageAuditTrail);
      console.log(
        "Location audit trail is: ",
        packageResponse.data.packageLocationAuditTrail
      );

      console.log("Response is : ", packageResponse);

      // Format the sender and recipient first name and last name, that is split on the first " "
      console.log("Package data is: ", newPackageData);
      const deepCopiedPackageData = cloneDeep(newPackageData);
      setPackageData(newPackageData);
    } catch (err) {
      console.log("Error fetching data. Error is: ", err);
      enqueueSnackbar("Error fetching data", { variant: "error" });
    }
  };

  const fetchCouriers = async () => {
    try {
      const response = await axiosInstance.get(`/couriers/get_couriers`);
      // For each Courier, append their first name and last names into a single string
      response.data.forEach((courier) => {
        courier.name = courier.first_name + " " + courier.last_name;
      });

      console.log("Couriers are: ", response.data);

      setCouriers(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchDataAndDependencies = async () => {
      setIsLoading(true);
      await fetchPackage(); // Fetch package data and shipment types
      await fetchCouriers(); // Then fetch couriers
      setIsLoading(false);
      // DEBUG:
      console.log("Access level is: ", accessLevel);
      console.log(
        "Manager access level is: ",
        process.env.REACT_APP_ACCESS_LEVEL_MANAGER
      );
    };

    fetchDataAndDependencies(); // Invoke the wrapper function
  }, []);

  const [tempCouriers, setTempCouriers] = useState([]);

  // When the courier list is changed, update tempCouriers
  const handleMultipleCourierChange = (event, newValue) => {
    const isCourierInPossession = newValue.some(
      (courier) => courier.courier_id === packageData.courier_in_possession_id
    );

    if (!isCourierInPossession && packageData.courier_in_possession_id) {
      alert("Cannot remove courier currently in possession.");
      return;
    }

    setTempCouriers(newValue);
  };

  // When the user confirms their selection
  const handleConfirmCouriers = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.put(`/packages/other_vendor_package_couriers/${id}`, {
        couriers: tempCouriers.map((courier) => courier.courier_id),
      });
      enqueueSnackbar("Couriers updated", { variant: "success" });
      setPackageData((prevData) => ({ ...prevData, couriers: tempCouriers }));
      await fetchPackage();
    } catch (err) {
      enqueueSnackbar("Error updating couriers", { variant: "error" });
    }
    setIsLoading(false);
  };

  // When the user cancels their selection
  const handleCancelCouriers = () => {
    setTempCouriers(packageData.couriers); // Reset to the original list
  };

  // Handle Status Update
  const handleStatusUpdate = async (newStatus) => {
    setIsLoading(true); // Set loading state
    try {
      await axiosInstance.put(`/packages/other_vendor_package_status/${id}`, {
        status: newStatus,
      });
      enqueueSnackbar("Status updated", { variant: "success" });
      await fetchPackage();
    } catch (err) {
      enqueueSnackbar("Error updating status", { variant: "error" });
    }
    setIsLoading(false);
  };

  const haveCouriersChanged = () => {
    const originalCourierIds = new Set(
      packageData.couriers.map((c) => c.courier_id)
    );
    return (
      tempCouriers.some((c) => !originalCourierIds.has(c.courier_id)) ||
      packageData.couriers.some(
        (c) => !tempCouriers.find((tc) => tc.courier_id === c.courier_id)
      )
    );
  };

  // Handle Courier In Possession Change
  const handleCourierInPossessionChange = async (event, newValue) => {
    if (packageData.status !== "DELIVERING") return; // Only update courier in possession if the status is "DELIVERING"

    if (packageData.status === "DELIVERING" && !newValue) {
      alert("Courier in possession is required for delivering status.");
      return;
    }

    const courierId = newValue?.courier_id || null;
    setPackageData((prevData) => ({
      ...prevData,
      courier_in_possession_id: courierId,
    }));

    await handleCourierInPossessionUpdate(courierId);
  };

  const handleCourierInPossessionConfirm = async () => {
    if (!courierInPossession || !courierInPossession.courier_id) return;
    // update status to DELIVERING
    await handleStatusUpdate("DELIVERING");
    await handleCourierInPossessionUpdate(courierInPossession.courier_id);
    setShowCourierDialog(false);
  };

  // Handle Courier In Possession Update
  const handleCourierInPossessionUpdate = async (courierId) => {
    setIsLoading(true);
    try {
      await axiosInstance.put(
        `/packages/other_vendor_package_courier_in_possession/${id}`,
        { courier_id: courierId }
      );
      enqueueSnackbar("Courier in possession updated", { variant: "success" });
      await fetchPackage();
    } catch (err) {
      enqueueSnackbar("Error updating courier in possession", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        display="flex"
        width="100%"
        sx={{
          backgroundImage: "linear-gradient(225deg, #0070c9 30%, #00a8e8 90%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            Back
          </Typography>
          <Typography
            variant="h6"
            sx={{ ml: 4.0, flexGrow: 1, textAlign: "left" }}
          >
            <Grid container alignItems="center">
              <Grid item>Other Vendor Package Details</Grid>
            </Grid>
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "64px" }} />{" "}
      {/* 64px is the typical height of an AppBar */}
      {packageData && (
        <>
          <Container maxWidth="lg">
            <Paper elevation={3}>
              <Box p={3}>
                <Grid container spacing={3}>
                  {/* Sender section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5">Sender</Typography>
                    <TextField
                      name="sender_first_name"
                      label="Sender First Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_first_name || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="sender_last_name"
                      label="Sender Last Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_last_name || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="sender_phone"
                      label="Sender Phone"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_phone || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="sender_email"
                      label="Sender Email"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_email || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="sender_address"
                      label="Sender Address"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_address || ""}
                      fullWidth
                      disabled
                    />
                  </Grid>

                  {/* Recipient section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5">Recipient</Typography>
                    <TextField
                      name="recipient_first_name"
                      label="Recipient First Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_first_name || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="recipient_last_name"
                      label="Recipient Last Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_last_name || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="recipient_phone"
                      label="Recipient Phone"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_phone || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="recipient_email"
                      label="Recipient Email"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_email || ""}
                      fullWidth
                      disabled
                    />
                    <TextField
                      name="recipient_address"
                      label="Recipient Address"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_address || ""}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                {/* Package section */}
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Typography variant="h5" sx={{ mb: 2 }}>
                        Delivery Details
                      </Typography>

                      {/* Package handling options */}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            📦 Is Fragile:{" "}
                            {packageData.is_fragile ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            ☣️ Is Hazardous:{" "}
                            {packageData.is_hazardous ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            🍎 Is Perishable:{" "}
                            {packageData.is_perishable ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            📏 Is Oversize:{" "}
                            {packageData.is_oversize ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            💰 Is Insured:{" "}
                            {packageData.is_insured ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            ✍️ Signature Required?{" "}
                            {packageData.is_signature_required ? "Yes" : "No"}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Destination type and Delivery preference */}
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            Destination Type:{" "}
                            {destinationTypeOptions(
                              packageData.destination_type
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            Delivery Preference:{" "}
                            {deliveryPreferenceOptions(
                              packageData.delivery_preference
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                {/* Delivery section */}
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Delivery</Typography>
                    <Grid container spacing={3}>
                      {/* Couriers section */}
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">Couriers</Typography>
                        <Autocomplete
                          multiple
                          options={couriers}
                          getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.courier_id === value.courier_id
                          }
                          value={tempCouriers}
                          onChange={handleMultipleCourierChange}
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              style={{
                                ...props.style,
                                opacity:
                                  option.courier_id ===
                                  packageData.courier_in_possession_id
                                    ? 0.5
                                    : 1,
                              }}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                                disabled={
                                  option.courier_id ===
                                  packageData.courier_in_possession_id
                                }
                              />
                              {`${option.first_name} ${option.last_name}`}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleConfirmCouriers}
                          disabled={!haveCouriersChanged()}
                          sx={{ mt: 2, mr: 2 }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCancelCouriers}
                          disabled={!haveCouriersChanged()}
                          sx={{ mt: 2, mr: 2 }}
                        >
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                          Courier in Possession
                        </Typography>
                        <Autocomplete
                          options={packageData.couriers || []}
                          getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.courier_id === value.courier_id
                          }
                          value={
                            (packageData.couriers || []).find(
                              (courier) =>
                                courier.courier_id ===
                                packageData.courier_in_possession_id
                            ) || null
                          }
                          onChange={handleCourierInPossessionChange}
                          disabled={packageData.status !== "DELIVERING"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={
                                packageData.status === "DELIVERING" &&
                                !packageData.courier_in_possession_id
                              }
                              helperText={
                                packageData.status === "DELIVERING" &&
                                !packageData.courier_in_possession_id
                                  ? "Please select a courier in possession"
                                  : ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Tracking section */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Tracking Details</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="tracking_number"
                          label="Tracking Number"
                          variant="filled"
                          value={packageData.tracking_number || ""}
                          disabled
                          sx={{ mt: 1.5, mb: 1.5 }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          name="package_status"
                          label="Package Status"
                          variant="filled"
                          value={packageData.status || ""}
                          sx={{ mt: 1.5, mb: 1.5 }}
                          onChange={handleStatusSelect}
                          fullWidth
                        >
                          {packageStatuses.map((status, index) => (
                            <MenuItem key={index} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      {/* Status Message */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="status_message"
                          label="Status Message"
                          variant="filled"
                          value={packageData.status_message || ""}
                          disabled
                          sx={{ mt: 1.5, mb: 1.5 }}
                          fullWidth
                        />
                      </Grid>
                      {/* Additional Delivery Instructions */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="additional_delivery_instructions"
                          label="Additional Delivery Instructions"
                          variant="filled"
                          value={
                            packageData.additional_delivery_instructions || ""
                          }
                          disabled
                          sx={{ mt: 1.5, mb: 1.5 }}
                          fullWidth
                        />
                      </Grid>
                      {/* Current Location */}
                      <Grid item xs={12}>
                        <TextField
                          name="current_location"
                          label="Current Address"
                          variant="filled"
                          value={currentLocation || ""}
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Current Location</Typography>
                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={{
                            lat: packageData.latitude,
                            lng: packageData.longitude,
                          }}
                          zoom={10}
                          mapTypeId={"satellite"}
                        >
                          <MarkerF
                            position={{
                              lat: Number(packageData.latitude),
                              lng: Number(packageData.longitude),
                            }}
                            onClick={() => {
                              setSelectedLocation({
                                lat: Number(packageData.latitude),
                                lng: Number(packageData.longitude),
                                status: packageData.package_status,
                                status_message: packageData.status_message,
                                updated_at: packageData.date_updated,
                              });
                            }}
                            icon={{
                              url: markerIcon,
                              scaledSize: new window.google.maps.Size(50, 50),
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(25, 25),
                            }}
                          />
                          {selectedLocation && (
                            <InfoWindow
                              position={{
                                lat: selectedLocation.lat,
                                lng: selectedLocation.lng,
                              }}
                              onCloseClick={() => setSelectedLocation(null)}
                            >
                              <div
                                style={{
                                  backgroundColor: "#333",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <h2>{`Status: ${selectedLocation.status}`}</h2>
                                <p>{`Status Message: ${selectedLocation.status_message}`}</p>
                                <p>{`Last Updated: ${new Date(
                                  selectedLocation.updated_at
                                ).toLocaleString()}`}</p>
                              </div>
                            </InfoWindow>
                          )}
                        </GoogleMap>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Location Trail</Typography>
                        <LocationTrail
                          locationAuditTrail={locationAuditTrail}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Dialog
                open={showCourierDialog}
                onClose={() => setShowCourierDialog(false)}
                aria-labelledby="courier-dialog-title"
              >
                <DialogTitle id="courier-dialog-title">
                  Select Courier In Possession
                </DialogTitle>
                <DialogContent>
                  <Autocomplete
                    options={tempCouriers}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    value={courierInPossession}
                    onChange={(event, newValue) =>
                      setCourierInPossession(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Courier in Possession"
                        variant="outlined"
                      />
                    )}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowCourierDialog(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleCourierInPossessionConfirm()}
                    color="primary"
                    disabled={
                      !courierInPossession ||
                      !tempCouriers.find(
                        (c) => c.courier_id === courierInPossession.courier_id
                      )
                    }
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Container>
        </>
      )}
    </div>
  );
};

export default OtherVendorsPackagePage;

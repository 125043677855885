import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  InputLabel,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import axiosInstance from "./axiosInstance";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { FixedSizeList as List } from "react-window";

const Header = () => (
  <div
    style={{
      display: "flex",
      borderBottom: "1px solid #e0e0e0",
      paddingBottom: "15px",
      marginBottom: "15px",
    }}
  >
    <Typography style={{ flex: 1, margin: "5px" }}>Item #</Typography>
    <Typography style={{ flex: 3, margin: "5px" }}>Name</Typography>
    <Typography style={{ flex: 2, margin: "5px" }}>Category</Typography>
    <Typography style={{ flex: 1, margin: "5px" }}>Quantity</Typography>
    <Typography style={{ flex: 2, margin: "5px" }}>Barcode</Typography>
    <Typography style={{ flex: 3, margin: "5px" }}>Description</Typography>
    <Typography style={{ flex: 2, margin: "5px" }}>Image</Typography>
    <Typography style={{ flex: 1, margin: "5px" }}>Action</Typography>
  </div>
);

const Row = ({
  index,
  style,
  data: { items, handleItemChange, removeItem },
}) => {
  const item = items[index];

  return (
    <div style={{ ...style, padding: "10px 0" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {index + 1}
        </div>
        <div style={{ flex: 3, padding: "0 5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            value={item.name}
            name="name"
            onChange={(event) => handleItemChange(index, event)}
            disabled={!!item.barcode}
          />
        </div>
        <div style={{ flex: 2, padding: "0 5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            value={item.category}
            name="category"
            disabled
          />
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <TextField
            style={{ width: "60px" }}
            variant="outlined"
            type="number"
            inputProps={{ min: "1", step: "1" }}
            value={item.quantity}
            name="quantity"
            onChange={(event) => {
              const val = event.target.value;
              if (val === "") {
                handleItemChange(index, {
                  target: { name: event.target.name, value: "1" },
                });
              } else {
                handleItemChange(index, event);
              }
            }}
          />
        </div>
        <div style={{ flex: 2, padding: "0 5px" }}>
          <TextField
            fullWidth
            variant="outlined"
            value={item.barcode}
            name="barcode"
            disabled
          />
        </div>
        <div style={{ flex: 3, padding: "0 5px" }}>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={item.description}
            name="description"
            onChange={(event) => handleItemChange(index, event)}
            disabled={!!item.barcode}
          />
        </div>
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            padding: "0 5px",
          }}
        >
          <img
            src={item.image_url}
            alt={item.name}
            loading="lazy"
            style={{ width: "100px", height: "auto" }}
          />
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <IconButton onClick={() => removeItem(index)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const VirtualizedTable = ({ items, handleItemChange, removeItem }) => {
  const MAX_HEIGHT = 550; // This will set a maximum height for your table. Adjust as necessary.

  return (
    <Paper
      style={{
        height: Math.min(items.length * 110 + 70, MAX_HEIGHT),
        width: "100%",
        padding: "15px",
      }}
    >
      <Header />
      <List
        height={Math.min(items.length * 110, MAX_HEIGHT - 70)} // this makes sure our List won't exceed the max height.
        itemCount={items.length}
        itemSize={150} // Increased height to accommodate more spacing.
        width="100%"
        itemData={{ items, handleItemChange, removeItem }}
      >
        {Row}
      </List>
    </Paper>
  );
};

const PackageContents = React.memo(function PackageContents({
  formData,
  handleInputChange,
  handleFormCompletion,
  handleClose,
}) {
  // Initialize with a default item if items is empty
  // Default to empty item if items is empty
  const [items, setItems] = useState(
    formData.package.items.length > 0
      ? formData.package.items
      : [
          {
            item_id: "",
            name: "",
            description: "",
            category: "",
            barcode: "",
            image_url: "",
            brand: "",
            quantity: 1,
            price: "",
          },
        ]
  );
  const [totalPrice, setTotalPrice] = useState(
    formData.package.price || "0.00"
  );
  const [weight, setWeight] = useState(formData.package.weight || "0.00");
  const [height, setHeight] = useState(formData.package.height || "0.00");
  const [width, setWidth] = useState(formData.package.width || "0.00");
  const [length, setLength] = useState(formData.package.length || "0.00");
  const packageTypeOptions = formData.shipment_type_package_types || [];
  const [packageType, setPackageType] = useState(
    formData.package.package_type || "PACKAGE"
  );
  const [isListening, setIsListening] = useState(false);
  const itemsRef = useRef(items);
  const [connectionError, setConnectionError] = useState(false);
  const [destinationType, setDestinationType] = useState(
    formData.package.destination_type || "RESIDENTIAL"
  );
  const [deliveryPreference, setDeliveryPreference] = useState(
    formData.package.delivery_preference || "ANYTIME"
  );
  const [isFragile, setIsFragile] = useState(
    formData.package.is_fragile || false
  );
  const [isHazardous, setIsHazardous] = useState(
    formData.package.is_hazardous || false
  );
  const [isPerishable, setIsPerishable] = useState(
    formData.package.is_perishable || false
  );
  const [isOversize, setIsOversize] = useState(
    formData.package.is_oversize || false
  );
  const [isInsured, setIsInsured] = useState(
    formData.package.is_insured || false
  );
  const [isSignatureRequired, setIsSignatureRequired] = useState(
    formData.package.is_signature_required || false
  );
  const destinationTypes = ["RESIDENTIAL", "COMMERCIAL", "OTHER"];
  const deliveryPreferences = ["ANYTIME", "MORNING", "AFTERNOON", "EVENING"];
  const [additionalCharges, setAdditionalCharges] = useState(
    formData.package.additional_charges || []
  );

  const [refreshSSE, setRefreshSSE] = useState(false);

  const [sseInstance, setSseInstance] = useState(null);

  const chargeTypeDisplayNames = {
    Tax: "Tax",
    Insurance: "Insurance",
    Discount: "Discount",
    Fee: "Fee",
    "Handling Fee": "Handling Fee",
    "Remote Area Charge": "Remote Area Charge",
    "Fuel Surcharge": "Fuel Surcharge",
    "Peak Season Charge": "Peak Season Charge",
    "Oversize Charge": "Oversize Charge",
    "Storage Fee": "Storage Fee",
    "Customs Charge": "Customs Charge",
    Other: "Other",
  };

  const handleAddCharge = () => {
    const newCharges = [
      ...formData.package.additional_charges,
      { type: "Tax", amount: "0.00" },
    ];
    handleInputChange({
      target: {
        name: "package.additional_charges",
        value: newCharges,
      },
    });
    setAdditionalCharges(newCharges);
  };

  const handleChargeChange = (index, field, value) => {
    const newCharges = [...formData.package.additional_charges];
    newCharges[index][field] = value;

    handleInputChange({
      target: {
        name: "package.additional_charges",
        value: newCharges,
      },
    });
    setAdditionalCharges(newCharges);
  };

  const handleRemoveCharge = (index) => {
    const newCharges = [...formData.package.additional_charges];
    newCharges.splice(index, 1);

    handleInputChange({
      target: {
        name: "package.additional_charges",
        value: newCharges,
      },
    });
    setAdditionalCharges(newCharges);
  };

  const handleDestinationTypeChange = (event) => {
    const value = event.target.value;
    setDestinationType(value);
    handleInputChange({
      target: {
        name: "package.destination_type",
        value: value,
      },
    });
  };

  const handleDeliveryPreferenceChange = (event) => {
    const value = event.target.value;
    setDeliveryPreference(value);
    handleInputChange({
      target: {
        name: "package.delivery_preference",
        value: value,
      },
    });
  };

  // Function to map package types to emojis
  const mapPackageTypeToEmoji = (type) => {
    const emojiMap = {
      ENVELOPE: "✉️",
      PACKAGE: "🛍️",
      BOX: "📦",
      PALLET: "📋",
      CRATE: "🚧",
      OTHER: "🔍",
    };
    return `${emojiMap[type] || ""} ${type}`;
  };

  const destinationTypeOptions = (type) => {
    const destinationTypeMap = {
      RESIDENTIAL: "🏠",
      COMMERCIAL: "🏢",
      OTHER: "🔍",
    };
    return `${destinationTypeMap[type] || ""} ${type}`;
  };

  const deliveryPreferenceOptions = (type) => {
    const deliveryPreferenceMap = {
      ANYTIME: "🕒",
      MORNING: "🌅",
      AFTERNOON: "🌇",
      EVENING: "🌃",
    };
    return `${deliveryPreferenceMap[type] || ""} ${type}`;
  };

  const handlePackageTypeChange = (event) => {
    const value = event.target.value;
    setPackageType(value);
    handleInputChange({
      target: {
        name: "package.package_type",
        value: value,
      },
    });

    if (value === "ENVELOPE") {
      setItems([]); // Clear items if package type is "Envelope"

      const newPackageData = {
        "package.items": [],
        "package.package_type": value,
        "package.weight": "0.00",
        "package.height": "0.00",
        "package.width": "0.00",
        "package.length": "0.00",
        "package.is_fragile": false,
        "package.is_hazardous": false,
        "package.is_perishable": false,
        "package.is_oversize": false,
        "package.is_insured": false,
        "package.is_signature_required": false,
      };

      Object.entries(newPackageData).forEach(([key, val]) => {
        handleInputChange({
          target: { name: key, value: val },
        });
      });
    }

    // if package content is BOX then clear the items
    if (value === "BOX") {
      const newPackageData = {
        "package.items": [],
      };

      Object.entries(newPackageData).forEach(([key, val]) => {
        handleInputChange({
          target: { name: key, value: val },
        });
      });
    }
  };

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const addItem = () => {
    setItems((prevItems) => {
      const updatedItems = [
        ...prevItems,
        {
          item_id: "",
          name: "",
          description: "",
          category: "",
          barcode: "",
          quantity: 1,
          price: "",
          image_url: "",
          brand: "",
        },
      ];
      handleInputChange({
        target: {
          name: "package",
          value: { ...formData.package, items: updatedItems },
        },
      });
      return updatedItems;
    });
  };

  // useEffect to check for form Completion, each item must hvae a name, quanity and description, and the weight must be greater than 0
  useEffect(() => {
    // Null check
    if (
      (packageType !== "ENVELOPE" || packageType !== "BOX") &&
      items.length === 0
    ) {
      handleFormCompletion(false);
      return;
    } else if (packageType === "ENVELOPE") {
      handleFormCompletion(true);
      return;
    }

    // Check if each item has a name, description, and quantity
    const isFormComplete =
      items.every((item) => item.name !== "" && item.description !== "") &&
      weight >= 0.0 &&
      totalPrice > 0.0;
    handleFormCompletion(isFormComplete);
  }, [items, weight, handleFormCompletion, totalPrice]);

  useEffect(() => {
    // Listen for scanned data
    initializeSSE();

    // Cleanup effect when component is unmounted
    return () => {
      if (sseInstance) {
        sseInstance.close();
        axiosInstance.post("/scanner/terminate_connection");
      }
    };
  }, []);

  // SSE Initialization
  const initializeSSE = () => {
    console.log("Initializing SSE...");
    setIsListening(true);

    // const sse = new EventSource(
    //   "http://localhost:8080/scanner/listen_scanned_data",
    //   { withCredentials: true }
    // ); // Comment out for production
    const sse = new EventSource(
      "https://api.cexpress.app/scanner/listen_scanned_data",
      { withCredentials: true }
    ); // Comment out for development

    const isLastItemEmpty = (lastItem) => {
      return (
        lastItem &&
        !lastItem.name &&
        !lastItem.description &&
        !lastItem.barcode &&
        lastItem.category === "" &&
        lastItem.price === "" &&
        lastItem.quantity === 1 &&
        lastItem.image_url === "" &&
        lastItem.brand === ""
      );
    };

    sse.onmessage = (event) => {
      console.log("Scanned data received...");
      console.log(event);

      const scannedItem = JSON.parse(event.data);

      console.log(scannedItem);

      if (scannedItem && scannedItem.name && scannedItem.description) {
        // check if the barcode is already in the list
        console.log("Checking if item already exists in the list...");
        console.log(items);
        const barcodeExists = itemsRef.current.some(
          (item) => item.barcode === scannedItem.barcode
        );
        if (barcodeExists) {
          console.log("Item already exists in the list...");
          // Increment the quantity of the item
          setItems((prevItems) => {
            const updatedItems = prevItems.map((prevItem) => {
              if (prevItem.barcode === scannedItem.barcode) {
                return { ...prevItem, quantity: prevItem.quantity + 1 };
              }
              return prevItem;
            });
            handleInputChange({
              target: {
                name: "package",
                value: { ...formData.package, items: updatedItems },
              },
            });
            return updatedItems;
          });
          return;
        }
        // Check if last item is empty
        if (isLastItemEmpty(itemsRef.current[itemsRef.current.length - 1])) {
          console.log("Last item is empty...");
          // Replace the last item with the scanned item
          setItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[updatedItems.length - 1] = {
              item_id: scannedItem.item_id,
              name: scannedItem.name,
              description: scannedItem.description,
              category: scannedItem.category,
              barcode: scannedItem.barcode,
              quantity: 1,
              price: scannedItem.price,
              image_url: scannedItem.image_url,
              brand: scannedItem.brand,
            };
            handleInputChange({
              target: {
                name: "package",
                value: { ...formData.package, items: updatedItems },
              },
            });
            return updatedItems;
          });
          return;
        }
        console.log("Adding new item to the list...");
        // Add the item to the list
        const newItem = {
          item_id: scannedItem.item_id,
          name: scannedItem.name,
          description: scannedItem.description,
          category: scannedItem.category,
          barcode: scannedItem.barcode,
          quantity: 1,
          image_url: scannedItem.image_url,
          price: scannedItem.price,
          brand: scannedItem.brand,
        };
        setItems((prevItems) => {
          const updatedItems = [...prevItems, newItem];
          handleInputChange({
            target: {
              name: "package",
              value: { ...formData.package, items: updatedItems },
            },
          });
          return updatedItems;
        });
      } else {
        console.log("Scanned data is not a valid item...");
      }
    };

    sse.onopen = () => {
      console.log("Listening for scanned data...");
      setIsListening(true);
    };

    sse.onerror = () => {
      console.log("Error listening for scanned data...");
      setConnectionError(true);
      setIsListening(false);
      sse.close();

      // Reconnet after 5 seconds
      setTimeout(() => {
        initializeSSE();
      }, 5000);
    };

    setSseInstance(sse);
  };

  function triggerRefresh() {
    if (sseInstance) {
      sseInstance.close();
    }
    initializeSSE();
  }

  // Function to calculate total additional charges
  const calculateTotalAdditionalCharges = (additionalCharges) => {
    return additionalCharges.reduce((total, charge) => {
      return total + parseFloat(charge.amount);
    }, 0); // Initializing the total as 0
  };

  useEffect(() => {
    let weightPrice =
      parseFloat(weight) * parseFloat(formData.shipment_type_price_per_pound);
    let dimensionalWeight = 0.0;
    if (formData.shipment_type_dimensional_weight > 0) {
      dimensionalWeight =
        (parseFloat(height) * parseFloat(width) * parseFloat(length)) /
        parseFloat(formData.shipment_type_dimensional_weight);
    }

    // Add up all of the additional charges
    const totalAdditionalCharges = calculateTotalAdditionalCharges(
      formData.package.additional_charges
    );

    // Add the weight price and additional charges to get the total price
    const totalPrice = weightPrice + totalAdditionalCharges + dimensionalWeight;

    setTotalPrice(totalPrice.toFixed(2)); // Converting to a string with 2 decimal places

    // Update the formData
    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, price: totalPrice.toFixed(2) },
      },
    });
  }, [
    weight,
    formData.shipment_type_price_per_pound,
    formData.package.additional_charges,
    height,
    width,
    length,
  ]);

  // Must meet the one item minimum requirement
  const removeItem = (index) => {
    if (items.length === 0) return;

    setItems((prevItems) => {
      const updatedItems = prevItems.filter(
        (_, itemIndex) => itemIndex !== index
      );
      handleInputChange({
        target: {
          name: "package",
          value: { ...formData.package, items: updatedItems },
        },
      });
      return updatedItems;
    });
  };

  const handleItemChange = (index, event) => {
    const updatedItems = [...items];
    let value = event.target.value;

    updatedItems[index][event.target.name] = value;
    setItems(updatedItems);

    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, items: updatedItems },
      },
    });
  };

  const handleWeightChange = (event) => {
    let value = event.target.value;
    setWeight(value);

    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, weight: value },
      },
    });
  };

  const handleHeightChange = (event) => {
    let value = event.target.value;
    setHeight(value);

    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, height: value },
      },
    });
  };

  const handleWidthChange = (event) => {
    let value = event.target.value;
    setWidth(value);

    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, width: value },
      },
    });
  };

  const handleLengthChange = (event) => {
    let value = event.target.value;
    setLength(value);

    handleInputChange({
      target: {
        name: "package",
        value: { ...formData.package, length: value },
      },
    });
  };

  const formStyle = {
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#F9F9F9",
  };
  const titleStyle = { marginBottom: "30px", fontWeight: 500, color: "#333" };
  const subtextStyle = { marginBottom: "30px", fontWeight: 300, color: "#666" };

  return (
    <Paper style={formStyle}>
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        edge="end"
        color="default"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" style={titleStyle}>
        <Grid container alignItems="center">
          <Grid item>
            {isListening && !connectionError ? (
              <FiberManualRecordIcon
                color="success" // Changed to success for green color
                fontSize="small"
                sx={{
                  marginRight: "8px", // Space it a bit from the text
                  animation: "blink 1s infinite",
                  "@keyframes blink": {
                    "0%": { opacity: 0.2 },
                    "50%": { opacity: 1 },
                    "100%": { opacity: 0.2 },
                  },
                }}
              />
            ) : (
              <IconButton onClick={triggerRefresh} color="error">
                <RefreshIcon />
              </IconButton>
            )}
            Package Items Information
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Typography>
      {/* Dropdown for Package Types */}
      <Typography variant="h5">Package Type</Typography>
      <Typography variant="subtitle1" style={subtextStyle}>
        Select the type of package you are shipping
      </Typography>
      <Select
        value={packageType}
        onChange={handlePackageTypeChange}
        disabled={packageTypeOptions.length === 0}
        sx={{ mb: 5 }}
      >
        {packageTypeOptions.map((type, index) => (
          <MenuItem value={type} key={index}>
            {mapPackageTypeToEmoji(type)}
          </MenuItem>
        ))}
      </Select>
      <VirtualizedTable
        items={items}
        handleItemChange={handleItemChange}
        removeItem={removeItem}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={addItem}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Add New Item
      </Button>
      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Additional Details
      </Typography>
      <Grid container spacing={3}>
        {packageType !== "ENVELOPE" && (
          <React.Fragment>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Weight"
                name="weight"
                type="text"
                value={weight}
                onChange={(event) => {
                  let val = event.target.value;
                  val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (val === "") {
                    handleWeightChange({
                      target: { name: event.target.name, value: "0.00" },
                    });
                  } else {
                    // Format as decimal
                    val = parseFloat(parseInt(val, 10) / 100).toFixed(2);
                    handleWeightChange({
                      target: { name: event.target.name, value: val },
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Height"
                name="height"
                type="text"
                value={height} // Make sure to define height in your state
                onChange={(event) => {
                  let val = event.target.value;
                  val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (val === "") {
                    handleHeightChange({
                      target: { name: event.target.name, value: "0.00" },
                    });
                  } else {
                    // Format as decimal
                    val = parseFloat(parseInt(val, 10) / 100).toFixed(2);
                    handleHeightChange({
                      target: { name: event.target.name, value: val },
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Width"
                name="width"
                type="text"
                value={width} // Make sure to define width in your state
                onChange={(event) => {
                  let val = event.target.value;
                  val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (val === "") {
                    handleWidthChange({
                      target: { name: event.target.name, value: "0.00" },
                    });
                  } else {
                    // Format as decimal
                    val = parseFloat(parseInt(val, 10) / 100).toFixed(2);
                    handleWidthChange({
                      target: { name: event.target.name, value: val },
                    });
                  }
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Length"
                name="length"
                type="text"
                value={length} // Make sure to define length in your state
                onChange={(event) => {
                  let val = event.target.value;
                  val = val.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  if (val === "") {
                    handleLengthChange({
                      target: { name: event.target.name, value: "0.00" },
                    });
                  } else {
                    // Format as decimal
                    val = parseFloat(parseInt(val, 10) / 100).toFixed(2);
                    handleLengthChange({
                      target: { name: event.target.name, value: val },
                    });
                  }
                }}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <Typography variant="body1">Destination Type</Typography>
            <Select
              id="destination-type-select"
              value={destinationType}
              onChange={handleDestinationTypeChange}
            >
              {destinationTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {destinationTypeOptions(type)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Choose the type of destination</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth>
            <Typography variant="body1">Delivery Preference</Typography>
            <Select
              id="delivery-preference-select"
              value={deliveryPreference}
              onChange={handleDeliveryPreferenceChange}
            >
              {deliveryPreferences.map((type) => (
                <MenuItem key={type} value={type}>
                  {deliveryPreferenceOptions(type)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select your preferred time for delivery
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid container spacing={2} marginLeft={1} marginTop={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_fragile}
                  disabled={packageType === "ENVELOPE"}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_fragile",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="📦 Is Fragile"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_hazardous}
                  disabled={packageType === "ENVELOPE"}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_hazardous",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="☣️ Is Hazardous"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_perishable}
                  disabled={packageType === "ENVELOPE"}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_perishable",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="🍎 Is Perishable"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_oversize}
                  disabled={packageType === "ENVELOPE"}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_oversize",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="📏 Is Oversize"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_insured}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_insured",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="💰 Is Insured"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.package.is_signature_required}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: "package.is_signature_required",
                        value: event.target.checked,
                      },
                    });
                  }}
                />
              }
              label="✍️ Signature Required?"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={200}>Type</TableCell>
                  <TableCell width={200}>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {additionalCharges.map((charge, index) => (
                  <TableRow key={index}>
                    <TableCell width={200}>
                      <FormControl variant="outlined">
                        <InputLabel>Type</InputLabel>
                        <Select
                          fullWidth
                          value={charge.type}
                          onChange={(e) =>
                            handleChargeChange(index, "type", e.target.value)
                          }
                          label="Type"
                        >
                          {Object.keys(chargeTypeDisplayNames).map((type) => (
                            <MenuItem key={type} value={type}>
                              {chargeTypeDisplayNames[type]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {charge.type === "Other" ? (
                      <TableCell width={200}>
                        <TextField
                          fullWidth
                          label="Custom Charge Name"
                          value={charge.customName || ""}
                          onChange={(e) =>
                            handleChargeChange(
                              index,
                              "customName",
                              e.target.value
                            )
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell width={200}>
                        <TextField
                          fullWidth
                          value={chargeTypeDisplayNames[charge.type]}
                          disabled
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        label="Amount"
                        value={charge.amount}
                        onChange={(event) => {
                          let val = event.target.value;
                          let isNegative = false;

                          // Check if value starts with a negative sign
                          if (val.startsWith("-")) {
                            isNegative = true;
                            val = val.substring(1); // Remove the negative sign for processing
                          }

                          // Remove non-numeric characters
                          val = val.replace(/[^0-9]/g, "");

                          if (val === "") {
                            handleChargeChange(index, "amount", "0.00");
                          } else {
                            // Format as decimal
                            val = parseFloat(parseInt(val, 10) / 100).toFixed(
                              2
                            );

                            // Add back the negative sign if needed
                            if (isNegative) {
                              val = "-" + val;
                            }

                            handleChargeChange(index, "amount", val);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveCharge(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddCharge}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            Add New Charge
          </Button>
          <TextField
            fullWidth
            variant="outlined"
            label="Total Price"
            name="totalPrice"
            value={totalPrice}
            disabled
          />
        </Grid>
      </Grid>
    </Paper>
  );
});

export default PackageContents;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, styled } from '@mui/material';

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
  fontFamily: 'Roboto, sans-serif',
  '& .MuiTypography-root': {
    fontSize: '1.3rem',
    fontWeight: '500',
  }
});

const StyledDialogContent = styled(DialogContent)({
  color: '#333',
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: '#f5f5f5',
  '& .MuiTypography-root': {
    fontSize: '1.1rem',
  }
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'space-around',
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: '#f5f5f5',
});

const StyledButton = styled(Button)({
  fontWeight: '600',
  fontFamily: 'Roboto, sans-serif',
  '&.MuiButton-root': {
    textTransform: 'none',
  },
});

const ApprovalPopup = ({data, handleApprove, handleDeny, open, handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">{"User Approval"}</StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="h6" gutterBottom>
          First Name: {data.first_name}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Last Name: {data.last_name}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Email: {data.email}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Phone: {data.phone}
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={handleDeny} color="secondary">
          Deny
        </StyledButton>
        <StyledButton onClick={handleApprove} color="primary" autoFocus>
          Approve
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  );
}

export default ApprovalPopup;

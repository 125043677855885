// DrawerContextProvider.js
import React, { useState } from 'react';
import { DrawerContext } from './DrawerContext';

export const DrawerContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <DrawerContext.Provider value={{ open, setOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};

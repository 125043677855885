import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  List,
  ListItem,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  InputAdornment,
  Toolbar,
  Box,
  Typography,
  useTheme,
  Tab,
  Tabs,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { enqueueSnackbar } from "notistack";
import axiosInstance from "./axiosInstance";
import SearchIcon from "@mui/icons-material/Search";
import { useMemo } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function EditManifestDialog({
  openDialog,
  handleClose,
  shipment_type_id,
  original_packages,
  manifest_id,
}) {
  const theme = useTheme();
  // Package Display
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState({});
  const [displayPackages, setDisplayPackages] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  // Sort, Filter and Search
  let [sortedPackages, setSortedPackages] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: localStorage.getItem("sortKey") || "created_at",
    direction: localStorage.getItem("sortDirection") || "asc",
  });
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );
  const [filter, setFilter] = useState(
    localStorage.getItem("manifestFilter") || ""
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("currentPage", newPage.toString());
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page changes
    localStorage.setItem("currentRowsPerPage", newRowsPerPage.toString());
    localStorage.setItem("currentPage", "0");
  };

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/packages/get_manifest_packages?type=${shipment_type_id}`
        );
        if (response.status === 200) {
          let fetchedPackages = response.data;

          // Map for quick lookup of original packages by their ID
          const originalPackagesMap = new Map(
            original_packages.map((pkg) => [pkg.package_id, pkg])
          );

          // Merge fetched packages with original packages, ensuring no duplicates and preserving original package details
          let mergedPackages = [...fetchedPackages];
          original_packages.forEach((originalPkg) => {
            if (
              !mergedPackages.some(
                (pkg) => pkg.package_id === originalPkg.package_id
              )
            ) {
              mergedPackages.push(originalPkg); // Add missing original packages not present in fetchedPackages
            }
          });

          // Initialize selection states, defaulting to selected for original packages
          const packageSelectionStates = mergedPackages.reduce((acc, pkg) => {
            // If package is part of original_packages, it's selected by default
            acc[pkg.package_id] = originalPackagesMap.has(pkg.package_id);
            return acc;
          }, {});

          setPackages(mergedPackages); // Update the state with merged packages
          setSelectedPackages(packageSelectionStates); // Update selection states
        } else {
          enqueueSnackbar("Error occurred while getting packages", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error occurred while getting packages", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, [shipment_type_id, original_packages]);

  useEffect(() => {
    const initialSelections = new Set(
      original_packages.map((pkg) => pkg.package_id)
    );
    const currentSelections = new Set(
      Object.keys(selectedPackages).filter(
        (packageId) => selectedPackages[packageId]
      )
    );
    const selectionsChanged =
      [...initialSelections].some((id) => !currentSelections.has(id)) ||
      [...currentSelections].some((id) => !initialSelections.has(id));
    setHasChanges(selectionsChanged);
  }, [selectedPackages, original_packages]);

  const handlePackageSelect = (packageId) => {
    const newSelectedPackages = {
      ...selectedPackages,
      [packageId]: !selectedPackages[packageId],
    };
    setSelectedPackages(newSelectedPackages);
  };

  const handleSaveManifest = async () => {
    setIsLoading(true);
    const selectedPackageIds = Object.keys(selectedPackages).filter(
      (package_id) => selectedPackages[package_id]
    );
    try {
      console.log(selectedPackageIds);
      console.log(manifest_id);
      const response = await axiosInstance.put("/packages/update_manifest", {
        manifest_id: manifest_id,
        package_ids: selectedPackageIds,
      });
      if (response.status !== 200) {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while updating manifest", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while updating manifest", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }
    enqueueSnackbar("Manifest updated successfully", { variant: "success" });
    setIsLoading(false);
    handleClose(hasChanges);
  };

  // Filter Sort and Search logic
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    // Update localStorage
    localStorage.setItem("sortKey", key);
    localStorage.setItem("sortDirection", direction);
  };

  sortedPackages = useMemo(() => {
    return [...packages].sort((a, b) => {
      if (sortConfig.key === "weight" || sortConfig.key === "price") {
        let numA = parseFloat(a[sortConfig.key]);
        let numB = parseFloat(b[sortConfig.key]);

        if (numA < numB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (numA > numB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      } else if (
        sortConfig.key === "date_added" ||
        sortConfig.key === "date_updated"
      ) {
        let dateA = new Date(a[sortConfig.key]);
        let dateB = new Date(b[sortConfig.key]);

        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [packages, sortConfig]);

  useEffect(() => {
    let updatedPackages = [...sortedPackages];

    // Filter logic
    if (filter) {
      updatedPackages = updatedPackages.filter((pkg) => pkg.status === filter);
    }

    if (searchTerm) {
      console.log("searchTerm: ", searchTerm);
      const lowerSearchTerm = searchTerm.toLowerCase();
      updatedPackages = updatedPackages.filter((pkg) => {
        return (
          pkg.employee_name.toLowerCase().includes(lowerSearchTerm) ||
          (pkg.sender_first_name + " " + pkg.sender_last_name)
            .toLowerCase()
            .includes(lowerSearchTerm) ||
          (pkg.recipient_first_name + " " + pkg.recipient_last_name)
            .toLowerCase()
            .includes(lowerSearchTerm) ||
          pkg.package_id.toLowerCase().includes(lowerSearchTerm) ||
          // Enhanced Search logic for array of courier names
          (pkg.courier_names &&
            pkg.courier_names.some((name) =>
              name.toLowerCase().includes(lowerSearchTerm)
            )) ||
          pkg.shipment_type_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.tracking_number.toLowerCase().includes(lowerSearchTerm) ||
          pkg.status.toLowerCase().includes(lowerSearchTerm) ||
          String(pkg.weight).includes(searchTerm) ||
          String(pkg.price).includes(searchTerm)
        );
      });
    }

    // Reset page number and update localStorage when filter or searchTerm changes
    setPage(0);
    localStorage.setItem("currentPage", "0");

    if (filter) {
      // Update localStorage when filter changes
      localStorage.setItem("manifestFilter", filter);
    } else {
      localStorage.setItem("manifestFilter", "");
    }

    if (searchTerm) {
      // Update localStorage when searchTerm changes
      localStorage.setItem("searchTerm", searchTerm);
    } else {
      localStorage.setItem("searchTerm", "");
    }

    // Filter packages by date range
    if (startDate && endDate) {
      updatedPackages = updatedPackages.filter((pkg) => {
        const packageDate = new Date(pkg.created_at);
        return packageDate >= startDate && packageDate <= endDate;
      });
    }

    setDisplayPackages(updatedPackages); // setting the state here
  }, [sortedPackages, filter, searchTerm, startDate, endDate]);

  const displayedPackages = displayPackages.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "90%",
          height: "90%",
          maxHeight: "100%",
          margin: 0,
          p: 0,
          borderRadius: 4,
        },
      }}
      open={openDialog}
      onClose={handleClose}
    >
      <DialogTitle>Edit Packages in Manifest</DialogTitle>
      <DialogContent>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Packages
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                sx={{ marginRight: "10px" }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              size="small"
              placeholder="Search"
              sx={{ width: "300px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl sx={{ minWidth: 120, height: "40px" }}>
              <Select
                labelId="filter-label"
                value={filter}
                displayEmpty
                sx={{ height: "40px", width: "150px" }}
                onChange={(e) => setFilter(e.target.value)}
              >
                <MenuItem value="">
                  <em>Status...</em>
                </MenuItem>
                <MenuItem value="PROCESSED">📦 Processed</MenuItem>
                <MenuItem value="LABELED">🔖 Labeled</MenuItem>
                <MenuItem value="SHIPPED">🚚 Shipped</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table aria-label="packages table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "tracking_number"}
                    direction={
                      sortConfig.key === "tracking_number"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("tracking_number")}
                  >
                    Tracking Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "status"}
                    direction={
                      sortConfig.key === "status" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "weight"}
                    direction={
                      sortConfig.key === "weight" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("weight")}
                  >
                    Weight
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "created_at"}
                    direction={
                      sortConfig.key === "created_at"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("created_at")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedPackages.map((packageItem) => (
                <TableRow key={packageItem.package_id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selectedPackages[packageItem.package_id]}
                      onChange={() =>
                        handlePackageSelect(packageItem.package_id)
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {packageItem.tracking_number}
                  </TableCell>
                  <TableCell>{packageItem.status}</TableCell>
                  <TableCell>{packageItem.weight}</TableCell>
                  <TableCell>
                    {new Date(packageItem.created_at).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 80, 100]}
            component="div"
            count={displayPackages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSaveManifest}
          color="primary"
          disabled={
            isLoading ||
            Object.keys(selectedPackages).length === 0 ||
            !hasChanges
          }
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditManifestDialog;

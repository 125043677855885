import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { enqueueSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { alpha } from "@mui/material/styles";
import { FormControl } from "@mui/material";
import ApprovalPopup from "../components/ApprovalPopup";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
} from "@mui/material";

// TODO: Fix clearing of items in courier contents
// TODO: check why create-courier status changes when returning from courier status page

const EmployeePage = ({ history }) => {
  const { accessLevel, logoutUser } = useContext(AuthContext);
  const theme = useTheme();
  const location = useLocation();
  const [sort, setSort] = useState("created_at");
  const [filter, setFilter] = useState("");
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  let [sortedEmployees, setSortedEmployees] = useState([]);
  const [displayEmployees, setDisplayEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewRequestOpen, setReviewRequestOpen] = useState(false);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get("/employees/get_all");
      if (response.status === 200) {
        // For each employee object add a name field
        for (let employee of response.data) {
          employee.name = `${employee.first_name} ${employee.last_name}`;
        }
        setEmployees(response.data);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting employees", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting employees", {
        variant: "error",
      });
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleApprove = async (id) => {
    try {
      const response = await axiosInstance.put(`/employees/approve/${id}`);
      if (response.status === 200) {
        enqueueSnackbar("Employee approved successfully", {
          variant: "success",
        });
        // Update the employee status
        const updatedEmployees = [...employees];
        const employeeIndex = updatedEmployees.findIndex(
          (employee) => employee.employee_id === id
        );
        updatedEmployees[employeeIndex].account_status = "ACTIVE";
        setEmployees(updatedEmployees);
        setReviewRequestOpen(false);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while approving employee", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while approving employee", {
        variant: "error",
      });
    }
  };

  const handleReject = async (id) => {
    try {
      const response = await axiosInstance.put(`/employees/reject/${id}`);
      if (response.status === 200) {
        enqueueSnackbar("Employee rejected successfully", {
          variant: "success",
        });
        // Remove the employee
        const updatedEmployees = employees.filter(
          (employee) => employee.employee_id !== id
        );
        setEmployees(updatedEmployees);
        setReviewRequestOpen(false);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while rejecting employee", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while rejecting employee", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    Promise.all([fetchEmployees()]).then(() => {
      setIsLoading(false);
    });

    console.log(accessLevel);
  }, []);

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const navigateToEmployeeDetails = (id) => {
    navigate(`/employees/${id}`);
  };

  const handlePrint = () => {
    window.print();
  };

  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "asc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  sortedEmployees = useMemo(() => {
    return [...employees].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [employees, sortConfig]);

  useEffect(() => {
    let updatedEmployees = [...sortedEmployees];

    // Filter logic
    if (filter) {
      updatedEmployees = updatedEmployees.filter((emp) => emp.phone === filter);
    }

    setDisplayEmployees(updatedEmployees); // setting the state here
  }, [sortedEmployees, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Employees
            </Typography>
            <Button onClick={fetchEmployees}>
              <RefreshIcon />
            </Button>
          </Box>
          {/* <TextField
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        sx={{ width: '300px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
          {/* <FormControl sx={{minWidth: 120}}>
                        <InputLabel id="filter-label">Filter</InputLabel>
                        <Select
                            labelId="filter-label"
                            value={filter}
                            label="Filter"
                            sx={{height: '50px', width: '150px'}}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="ON_STANDBY">On Standby</MenuItem>
                            <MenuItem value="DELIVERING">Delivering</MenuItem>
                            <MenuItem value="BEHIND">Behind</MenuItem>
                            <MenuItem value="OFFLINE">Offline</MenuItem>
                        </Select>
                    </FormControl> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "name"}
                    direction={
                      sortConfig.key === "name" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "email"}
                    direction={
                      sortConfig.key === "email" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("email")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "phone"}
                    direction={
                      sortConfig.key === "phone" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("phone")}
                  >
                    Phone
                  </TableSortLabel>
                </TableCell>
                {/* <TableCell align="left">
                            <TableSortLabel
                                active={sortConfig.key === 'address'}
                                direction={sortConfig.key === 'address' ? sortConfig.direction : 'asc'}
                                onClick={() => handleSort('address')}
                            >
                                Role
                            </TableSortLabel>
                        </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayEmployees.map((emp) => {
                return (
                  <TableRow
                    key={emp.employee_id}
                    hover
                    onClick={() => {
                      if (
                        emp.account_status !== "PENDING" &&
                        accessLevel <= 3
                      ) {
                        navigateToEmployeeDetails(emp.employee_id);
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        emp.account_status === "PENDING"
                          ? "rgba(255, 0, 0, 0.7)"
                          : "inherit",
                      backdropFilter:
                        emp.account_status === "PENDING" ? "blur(5px)" : "none",
                    }}
                  >
                    <TableCell sx={{ width: "20%" }}>{emp.name}</TableCell>
                    {emp.account_status === "PENDING" && accessLevel <= 3 ? (
                      <>
                        <TableCell colSpan={3}>
                          <Button
                            variant="contained"
                            onClick={() => setReviewRequestOpen(true)}
                            sx={{
                              background:
                                "rgba(255,165,0, 0.4)" /* fallback for old browsers */,
                              background:
                                "-webkit-linear-gradient(to right, rgba(255,165,0, 0.4), rgba(255,165,0, 0.2))" /* Chrome 10-25, Safari 5.1-6 */,
                              background:
                                "linear-gradient(to right, rgba(255,165,0, 0.4), rgba(255,165,0, 0.2))" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
                              backdropFilter: "blur(10px)",
                              borderRadius: "10px",
                              border: "1px solid rgba(255,165,0, 0.3)",
                              color: "#fff",
                            }}
                          >
                            Review and Approve Employee Request
                          </Button>
                          <ApprovalPopup
                            data={emp}
                            handleApprove={() => handleApprove(emp.employee_id)}
                            handleDeny={() => handleReject(emp.employee_id)}
                            open={reviewRequestOpen}
                            handleClose={() => setReviewRequestOpen(false)}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{emp.email}</TableCell>
                        <TableCell>{emp.phone}</TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default EmployeePage;

import React, { useState } from "react";
import {
  Typography,
  Paper,
  Button,
  Box,
  IconButton,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import QRCode from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";

const NoPrint = styled(Box)({
  "@media print": {
    display: "none",
  },
});

function QRCodeItemPage({ items, onClose, packageId }) {
  const [itemQuantities, setItemQuantities] = useState(
    items.reduce((acc, item) => ({ ...acc, [item.item_id]: item.quantity }), {})
  );
  const navigate = useNavigate();
  const [qrSize, setQrSize] = useState(80); // 80 pixels ~ 0.8 inches
  const [qrPerPage, setQrPerPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // <-- Search term state
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  ); // Filter items based on search term

  const handlePrintItem = (item) => {
    const itemsToPrint = [];
    for (let i = 0; i < itemQuantities[item.item_id]; i++) {
      itemsToPrint.push(item);
    }

    const data = {
      id: packageId,
      items: itemsToPrint,
      qrSize,
      qrPerPage,
    };

    navigate("/printQR", { state: { data } });
  };

  const handlePrint = async () => {
    // Set the package status as LABELED
    if (packageId === undefined) {
      console.log("Error: packageId is undefined");
      return;
    }
    const response = await axiosInstance.put(`/packages/labeled/${packageId}`);
    if (response.status !== 200) {
      console.log("Error updating package status");
    } else {
      console.log("Successfully updated package status");
    }

    console.log("Printing all QR codes");
    console.log(itemQuantities);
    // Print all items
    const newPrintItems = [];
    items.forEach((item) => {
      for (let i = 0; i < itemQuantities[item.item_id]; i++) {
        newPrintItems.push(item);
      }
    });

    console.log(newPrintItems);
    const data = {
      id: packageId,
      items: newPrintItems,
      qrSize,
      qrPerPage,
    };
    navigate("/printQR", { state: { data } });
  };

  const increaseQuantity = (itemId) => {
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: parseInt(prevQuantities[itemId], 10) + 1,
    }));
  };

  const decreaseQuantity = (itemId) => {
    if (parseInt(itemQuantities[itemId], 10) > 1) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [itemId]: parseInt(prevQuantities[itemId], 10) - 1,
      }));
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        padding: "1em",
        maxWidth: "100%",
        margin: "auto",
        marginTop: "2em",
        marginBottom: "2em",
      }}
    >
      <NoPrint>
        <IconButton
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            backgroundColor: "#e0e0e0",
            borderRadius: "50%",
          }}
          edge="end"
          color="default"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom alignContent={"center"}>
          Item QR Codes
        </Typography>
        <TextField
          variant="outlined"
          label="Search by Item Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: "1em", width: "100%" }}
        />
      </NoPrint>
      <Grid container spacing={2} alignItems="center">
        {filteredItems.map((item, index) => (
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            ml={2}
            mr={2}
            key={`${item.item_id}-${index}`}
            id={`qr-${item.item_id}`}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {item.name}
              </Typography>
              <QRCode value={`${packageId}|${item.item_id}`} size={170} />
              <Typography
                variant="caption"
                sx={{ mt: 1.0, fontSize: 8 }}
                gutterBottom
              >
                {item.item_id}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => decreaseQuantity(item.item_id)}
                >
                  -
                </Button>
                <Typography sx={{ px: 2 }}>
                  {itemQuantities[item.item_id]}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => increaseQuantity(item.item_id)}
                >
                  +
                </Button>
              </Box>
              <NoPrint>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ display: "flex", marginTop: "2em" }}
                  onClick={() => handlePrintItem(item)}
                >
                  Print QR Code
                </Button>
              </NoPrint>
            </Box>
          </Grid>
        ))}
      </Grid>
      <NoPrint>
        <Button
          variant="text"
          color="primary"
          sx={{ display: "flex", marginTop: "2em" }}
          onClick={handlePrint}
        >
          Print All QR Codes
        </Button>
      </NoPrint>
    </Paper>
  );
}

export default QRCodeItemPage;

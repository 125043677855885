import React from 'react';
import { Typography, Paper, Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import QRCode from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import './PrintStyles.css';
import { useNavigate } from 'react-router-dom';  // <-- Import useNavigate

const NoPrint = styled(Box)({
  '@media print': {
    display: 'none',
  },
});

function ReceiptPopupVendor({ data, onClose }) {

  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  const {
    sender_first_name,
    sender_last_name,
    sender_email,
    sender_phone,
    sender_address,
    recipient_first_name,
    recipient_last_name,
    recipient_email,
    recipient_phone,
    recipient_address,
    employee_name,
    package_weight,
    package_price,
    package_id,
    items
  } = data;

  const handlePrint = () => {
    const url = `/print-qr-code?packageId=${package_id}`;
    window.open(url, '_blank');
};


  

  return (
    <Paper elevation={0} sx={{ padding: '2em', maxWidth: '100%', margin: 'auto', marginTop: '2em', marginBottom: '2em' }}>
      <NoPrint>
      <IconButton
        style={{ position: "absolute", right: 20, top: 20, backgroundColor: "#e0e0e0", borderRadius: "50%" }}
        edge="end"
        color="default"
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
        <Typography variant="h4" gutterBottom>Receipt</Typography>

        <Typography variant="h6" gutterBottom>Sender Information</Typography>
        <Typography variant="body2">
          Name: {sender_first_name} {sender_last_name} <br />
          Email: {sender_email} <br />
          Phone: {sender_phone} <br />
          Address: {sender_address}
        </Typography>

        <Typography variant="h6" gutterBottom>Recipient Information</Typography>
        <Typography variant="body2">
          Name: {recipient_first_name} {recipient_last_name} <br />
          Email: {recipient_email} <br />
          Phone: {recipient_phone} <br />
          Address: {recipient_address}
        </Typography>

        <Typography variant="h6" gutterBottom>Package Information</Typography>
        <Typography variant="body2">
          Processed by: {employee_name} <br />
          Package Weight: {package_weight} <br />
          Package Price: {package_price}
        </Typography>

        <Typography variant="h6" gutterBottom>Items</Typography>
        {items.map((item, index) => (
          <Typography variant="body2" key={index}>
            Item {index + 1} <br />
            Name: {item.item_name} <br />
            Description: {item.description} <br />
            Added Insurance: {item.added_insurance} <br />
            Tax Amount: {item.tax_amount} <br />
            Quantity: {item.quantity}
          </Typography>
        ))}
      </NoPrint>
      <div className="print-content">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2em' }} id="qr-section">
          <QRCode value={package_id} size={170} />
          <Typography variant="caption" sx={{mt:1.0, fontSize: 9}} gutterBottom>{package_id}</Typography>
        </Box>
      </div>
      <NoPrint>
        <Button variant="text" color="primary" sx={{display: 'flex'}} onClick={handlePrint}>Print QR Code</Button>
      </NoPrint>
    </Paper>
  );
}

export default ReceiptPopupVendor;

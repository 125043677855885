import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EditPaymentDialog = ({
  open,
  payment,
  handleClose,
  handleSave,
  isLoading,
}) => {
  const [editedPayment, setEditedPayment] = useState({ ...payment });

  useEffect(() => {
    if (open) {
      setEditedPayment({ ...payment });
    }
  }, [open, payment]);

  const handleInputChange = (e) => {
    setEditedPayment({ ...editedPayment, [e.target.name]: e.target.value });
  };

  const handleAmountChange = (event) => {
    let val = event.target.value;
    let isNegative = false;

    if (val.startsWith("-")) {
      isNegative = true;
      val = val.substring(1);
    }

    val = val.replace(/[^0-9]/g, "");

    if (val === "") {
      val = "0.00";
    } else {
      val = parseFloat(parseInt(val, 10) / 100).toFixed(2);
      if (isNegative) {
        val = "-" + val;
      }
    }

    setEditedPayment({ ...editedPayment, amount: val });
  };

  const renderStatusOptions = () => {
    if (["pending", "failed"].includes(editedPayment.status)) {
      return (
        <>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="cancelled">Cancelled</MenuItem>
          <MenuItem value="failed">Failed</MenuItem>
        </>
      );
    }
    return (
      <MenuItem value={editedPayment.status}>
        {editedPayment.status.charAt(0).toUpperCase() +
          editedPayment.status.slice(1)}
      </MenuItem>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "90%",
          height: "90%",
          maxHeight: "100%",
          margin: 0,
          p: 0,
          borderRadius: 4,
        },
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant="h4" style={{ margin: "20px 0" }}>
          Edit Payment Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Amount"
              name="amount"
              value={editedPayment.amount}
              onChange={handleAmountChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              variant="outlined"
              label="Status"
              name="status"
              value={editedPayment.status}
              onChange={handleInputChange}
              disabled={!["pending", "failed"].includes(editedPayment.status)}
            >
              {renderStatusOptions()}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Remarks"
              name="remarks"
              value={editedPayment.remarks}
              onChange={handleInputChange}
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => handleSave(editedPayment)}
          color="primary"
          disabled={isLoading}
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default EditPaymentDialog;

import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import axiosInstance from "./axiosInstance";
import "./ReceiptStyles.css";
import QRCode from "qrcode.react";

function ReceiptPopup({ packageId, setIsLoading, isLoading }) {
  const [items, setItems] = useState(null);
  const [packageData, setPackageData] = useState(null);
  const [trackingURL, setTrackingURL] = useState(null); // TODO: Replace with actual URL

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/packages/package/${packageId}`
        );
        console.log("Package data:");
        console.log(response.data);
        setItems(response.data.items);
        setPackageData(response.data.package);
        setTrackingURL(
          `https://www.cexpress.app/track/${response.data.package.tracking_number}`
        );
        // setSenderAddress(senderAddressFormatted);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="receipt-content">
        <Typography variant="h4" gutterBottom>
          Receipt
        </Typography>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      </div>
    );
  }

  return (
    <div className="receipt-content">
      <Typography variant="h4" gutterBottom>
        Receipt
      </Typography>

      {packageData && items && trackingURL ? (
        <>
          <div className="info-wrapper">
            <Box className="info-section">
              <Typography variant="h6" gutterBottom align="left">
                Sender Information
              </Typography>
              <Typography variant="body2">
                Name: {packageData.sender_first_name}{" "}
                {packageData.sender_last_name}
              </Typography>
              <Typography variant="body2">
                Email: {packageData.sender_email}
              </Typography>
              <Typography variant="body2">
                Phone: {packageData.sender_phone}
              </Typography>
              <Typography variant="body2">
                Address: {packageData.sender_address}
              </Typography>
            </Box>

            <Box className="info-section">
              <Typography variant="h6" gutterBottom align="left">
                Recipient Information
              </Typography>
              <Typography variant="body2">
                Name: {packageData.recipient_first_name}{" "}
                {packageData.recipient_last_name}
              </Typography>
              <Typography variant="body2">
                Email: {packageData.recipient_email}
              </Typography>
              <Typography variant="body2">
                Phone: {packageData.recipient_phone}
              </Typography>
              <Typography variant="body2">
                Address: {packageData.recipient_address}
              </Typography>
            </Box>
          </div>
          <Box className="info-section">
            <Typography variant="h5" gutterBottom marginTop={4}>
              Items
            </Typography>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {item.description.length > 100
                        ? item.description.substring(0, 100) + "..."
                        : item.description}
                    </td>
                    <td>{item.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          <Box className="info-section">
            <Typography variant="h5" gutterBottom marginTop={4}>
              Package Information
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h6">Basic Details</Typography>
                  <Typography variant="body2">
                    Processed by: {packageData.employee_name}
                  </Typography>
                  <Typography variant="body2">
                    Tracking Number: {packageData.tracking_number}
                  </Typography>
                  <Typography variant="body2">
                    Package Type: {packageData.package_type}
                  </Typography>
                  <Typography variant="body2">
                    Destination Type: {packageData.destination_type}
                  </Typography>
                  <Typography variant="body2">
                    Delivery Preference: {packageData.delivery_preference}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h6">Dimensions and Weight</Typography>
                  <Typography variant="body2">
                    Package Weight: {packageData.weight} lb
                  </Typography>
                  <Typography variant="body2">
                    Package Length: {packageData.length} in
                  </Typography>
                  <Typography variant="body2">
                    Package Width: {packageData.width} in
                  </Typography>
                  <Typography variant="body2">
                    Package Height: {packageData.height} in
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h6">Special Requirements</Typography>
                  <Typography variant="body2">
                    Fragile: {packageData.is_fragile ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    Hazardous: {packageData.is_hazardous ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    Perishable: {packageData.is_perishable ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    Insured: {packageData.is_insured ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    Oversize: {packageData.is_oversize ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body2">
                    Signature Required:{" "}
                    {packageData.is_signature_required ? "Yes" : "No"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h6">Rates and Fees</Typography>
                  <Typography variant="body2">
                    Per lb Price: {packageData.price_per_pound}
                  </Typography>
                  <Typography variant="body2">
                    Dimensional Weight Rate: {packageData.dimensional_weight}
                  </Typography>
                  <Typography variant="body2">
                    Per km Rate: {packageData.per_km_rate}
                  </Typography>
                  <Typography variant="body2">
                    Package Price: {packageData.price}
                  </Typography>
                  <Typography variant="body2">Additional Charges:</Typography>
                  <div className="additional-charges">
                    {JSON.parse(packageData.additional_charges).map(
                      (charge) => {
                        const uniqueKey = Math.random()
                          .toString(36)
                          .substr(2, 9);
                        return (
                          <Typography key={uniqueKey} variant="body2">
                            {charge.type === "Other"
                              ? charge.customName
                              : charge.type}
                            : {charge.amount}
                          </Typography>
                        );
                      }
                    )}
                  </div>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h6">Other Information</Typography>
                  <Typography variant="body2">
                    Additional Delivery Instructions:{" "}
                    {packageData.additional_delivery_instructions}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box className="sub-section">
                  <Typography variant="h5" gutterBottom>
                    Tracking Link
                  </Typography>
                  <QRCode value={trackingURL} size={100} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Typography variant="h6" gutterBottom>
          Package not found
        </Typography>
      )}
    </div>
  );
}

export default ReceiptPopup;

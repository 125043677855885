import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { enqueueSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { alpha } from "@mui/material/styles";
import { FormControl } from "@mui/material";
import ApprovalPopup from "../components/ApprovalPopup";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
} from "@mui/material";
import People from "@mui/icons-material/People";

// TODO: Fix clearing of items in courier contents
// TODO: add session storage for courier data

const CourierPage = ({ history }) => {
  const { accessLevel, logoutUser } = useContext(AuthContext);
  const theme = useTheme();
  const location = useLocation();
  const [sort, setSort] = useState("created_at");
  const [filter, setFilter] = useState("");
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [couriers, setCouriers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  let [sortedCouriers, setSortedCouriers] = useState([]);
  const [displayCouriers, setDisplayCouriers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewRequestOpen, setReviewRequestOpen] = useState(false);

  const fetchCouriers = async () => {
    try {
      const response = await axiosInstance.get("/couriers/get_all_couriers");
      if (response.status === 200) {
        // For each courier object add a name field
        for (let courier of response.data) {
          courier.name = `${courier.first_name} ${courier.last_name}`;
          // Get the address by passing the coordinates to the google maps api
          if (courier.location_latitude && courier.location_longitude) {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${courier.location_latitude},${courier.location_longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
            );
            if (response.status === 200) {
              // Get the address from the response
              const address = response.data.results[0].formatted_address;
              courier.address = address;
            } else {
              console.error(response.data.message);
              enqueueSnackbar("Error occurred while getting courier address", {
                variant: "error",
              });
            }
          } else {
            courier.address = "No available address";
          }
        }
        setCouriers(response.data);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting couriers", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting couriers", {
        variant: "error",
      });
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleApprove = async (id) => {
    try {
      const response = await axiosInstance.put(`/couriers/approve/${id}`);
      if (response.status === 200) {
        enqueueSnackbar("Courier approved successfully", {
          variant: "success",
        });
        // Update the courier status
        const updatedCouriers = [...couriers];
        const courierIndex = updatedCouriers.findIndex(
          (courier) => courier.courier_id === id
        );
        updatedCouriers[courierIndex].account_status = "ACTIVE";
        setCouriers(updatedCouriers);
        setReviewRequestOpen(false);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while approving courier", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while approving courier", {
        variant: "error",
      });
    }
  };

  const handleReject = async (id) => {
    try {
      const response = await axiosInstance.put(`/couriers/reject/${id}`);
      if (response.status === 200) {
        enqueueSnackbar("Courier rejected successfully", {
          variant: "success",
        });
        // Remove the courier
        const updatedCouriers = couriers.filter(
          (courier) => courier.courier_id !== id
        );
        setCouriers(updatedCouriers);
        setReviewRequestOpen(false);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while rejecting courier", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while rejecting courier", {
        variant: "error",
      });
    }
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    Promise.all([fetchCouriers()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const navigateToCourierDetails = (id) => {
    navigate(`/couriers/${id}`);
  };

  const handlePrint = () => {
    window.print();
  };

  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "asc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  sortedCouriers = useMemo(() => {
    return [...couriers].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [couriers, sortConfig]);

  useEffect(() => {
    let updatedCouriers = [...sortedCouriers];

    // Filter logic
    if (filter) {
      updatedCouriers = updatedCouriers.filter(
        (cou) => cou.activity_status === filter
      );
    }

    // Search logic
    // if (searchTerm) {
    //
    //   updatedCouriers = updatedCouriers.filter(cou =>
    //   cou.status.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    // }

    setDisplayCouriers(updatedCouriers); // setting the state here
  }, [sortedCouriers, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Couriers
            </Typography>
            <Button onClick={fetchCouriers}>
              <RefreshIcon />
            </Button>
          </Box>
          {/* <TextField
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder="Search"
                        sx={{ width: '300px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /> */}
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              value={filter}
              label="Filter"
              sx={{ height: "50px", width: "150px" }}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="ON_STANDBY">On Standby</MenuItem>
              <MenuItem value="DELIVERING">Delivering</MenuItem>
              <MenuItem value="BEHIND">Behind</MenuItem>
              <MenuItem value="OFFLINE">Offline</MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
          // Add transition time here
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "name"}
                    direction={
                      sortConfig.key === "name" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "status_last_updated"}
                    direction={
                      sortConfig.key === "status_last_updated"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("status_last_updated")}
                  >
                    Last Active
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "activity_status"}
                    direction={
                      sortConfig.key === "activity_status"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("activity_status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  <TableSortLabel
                    active={sortConfig.key === "address"}
                    direction={
                      sortConfig.key === "address"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("address")}
                  >
                    Last Seen At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayCouriers.map((cou) => {
                return (
                  <TableRow
                    key={cou.courier_id}
                    hover
                    onClick={() =>
                      cou.account_status !== "PENDING" &&
                      accessLevel <= 3 &&
                      navigateToCourierDetails(cou.courier_id)
                    }
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        cou.account_status === "PENDING"
                          ? "rgba(255, 0, 0, 0.7)"
                          : "inherit",
                      backdropFilter:
                        cou.account_status === "PENDING" ? "blur(5px)" : "none",
                    }}
                  >
                    <TableCell sx={{ width: "20%" }}>{cou.name}</TableCell>
                    {cou.account_status === "PENDING" && accessLevel <= 3 ? (
                      <>
                        <TableCell colSpan={3}>
                          <Button
                            variant="contained"
                            onClick={() => setReviewRequestOpen(true)}
                            sx={{
                              background:
                                "rgba(255,165,0, 0.4)" /* fallback for old browsers */,
                              background:
                                "-webkit-linear-gradient(to right, rgba(255,165,0, 0.4), rgba(255,165,0, 0.2))" /* Chrome 10-25, Safari 5.1-6 */,
                              background:
                                "linear-gradient(to right, rgba(255,165,0, 0.4), rgba(255,165,0, 0.2))" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
                              backdropFilter: "blur(10px)",
                              borderRadius: "10px",
                              border: "1px solid rgba(255,165,0, 0.3)",
                              color: "#fff",
                            }}
                          >
                            Review and Approve Deliverer Request
                          </Button>
                          <ApprovalPopup
                            data={cou}
                            handleApprove={() => handleApprove(cou.courier_id)}
                            handleDeny={() => handleReject(cou.courier_id)}
                            open={reviewRequestOpen}
                            handleClose={() => setReviewRequestOpen(false)}
                          />
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell component="th" scope="row">
                          {new Date(cou.status_last_updated).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </TableCell>
                        <TableCell>{cou.activity_status}</TableCell>
                        <TableCell>{cou.address}</TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CourierPage;

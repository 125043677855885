import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import QRCodeRenderer from "qrcode.react"; // For rendering in React
import * as QRCodeGenerator from "qrcode"; // For generating QR codes to canvas
import { toDataURL } from "qrcode";
import {
  Typography,
  TextField,
  Box,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Slider,
} from "@mui/material";

function PrintPage() {
  const location = useLocation();
  const { data } = location.state || {};
  const { items: itemsToPrint = [], id: packageId } = data || {};

  const [qrSize, setQrSize] = useState(0.9);
  const [pageWidth, setPageWidth] = useState(2); // Default to 2 inches
  const [pageHeight, setPageHeight] = useState(1); // Default to 1 inch

  const generatePDF = async () => {
    console.log("Generating PDF");
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [pageWidth, pageHeight],
    });

    const marginLeft = 0.1; // Margin before the QR Code
    const marginRight = 0.1; // Margin after the QR Code
    const textBuffer = 0;
    const lineHeight = 0.125; // Constant line height in inches
    const marginTop = 0.2; // Top margin for the item name

    for (let index = 0; index < itemsToPrint.length; index++) {
      console.log("Printing item: ", index);
      console.log("Item: ", itemsToPrint[index]);
      const item = itemsToPrint[index];

      // Add a new page, but not for the first item since we already have one
      if (index !== 0) {
        console.log("Adding new page");
        doc.addPage([pageWidth, pageHeight], "landscape");
      }

      const canvas = document.createElement("canvas");
      await new Promise((resolve, reject) => {
        QRCodeGenerator.toCanvas(
          canvas,
          `${packageId}|${item.item_id}`,
          function (error) {
            if (error) {
              console.error(error);
              reject(error);
            } else {
              const imgData = canvas.toDataURL("image/png");
              doc.addImage(
                imgData,
                "PNG",
                marginLeft,
                (pageHeight - qrSize) / 2,
                qrSize,
                qrSize
              );
              resolve();
            }
          }
        );
      });

      // Calculate available space for text
      const availableSpace =
        pageWidth - (marginLeft + qrSize + textBuffer + marginRight);
      const fontSize = 10;
      doc.setFontSize(fontSize);

      let currentLinePosition = marginTop; // Initial line position

      const modifiedItemName =
        item.name.length > 45 ? item.name.substring(0, 45) + "..." : item.name;

      // Split the text into chunks that fit within the available space
      const textChunks = doc.splitTextToSize(modifiedItemName, availableSpace);

      // Print each chunk on a new line
      for (
        let i = 0;
        i < textChunks.length && currentLinePosition <= pageHeight;
        i++
      ) {
        console.log("Printing text chunk: ", textChunks[i]);
        doc.text(
          textChunks[i],
          marginLeft + qrSize + textBuffer,
          currentLinePosition
        );
        currentLinePosition += lineHeight; // Move to the next line position
      }
      console.log("Finished printing item: ", index);
    }

    console.log("Finished generating PDF");
    // output package_id_label.pdf
    const blob = new Blob([doc.output("blob")], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    link.click();
  };
  return (
    <Box display="flex" flexDirection="row" p={4}>
      {/* Settings Section */}
      <Box flex={6} pr={4}>
        <Typography gutterBottom>QR Size</Typography>
        <Slider
          value={qrSize}
          onChange={(e, newValue) => setQrSize(newValue)}
          aria-labelledby="continuous-slider"
          min={0.5}
          max={2}
          step={0.001}
        />

        <Typography gutterBottom>Page Width (in inches)</Typography>
        <TextField
          value={pageWidth}
          onChange={(e) => setPageWidth(parseFloat(e.target.value))}
          type="number"
          inputProps={{ step: 0.1, min: 0.5 }}
        />

        <Typography gutterBottom>Page Height (in inches)</Typography>
        <TextField
          value={pageHeight}
          onChange={(e) => setPageHeight(parseFloat(e.target.value))}
          type="number"
          inputProps={{ step: 0.1, min: 0.5 }}
          min={0.5}
        />

        <Button
          variant="contained"
          onClick={generatePDF}
          sx={{
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
            boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
            width: "100%",
            padding: "10px",
            marginTop: "20px",
            textTransform: "uppercase",
            color: "#ffffff",
          }}
        >
          Print PDF
        </Button>
      </Box>

      {/* Preview Section */}
      <Box
        flex={9}
        pl={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ border: "1px solid #ccc", borderRadius: "4px" }}
      >
        {/* Label Simulation */}
        <Box
          width={`${pageWidth * 96}px`} // Convert inches to pixels
          height={`${pageHeight * 96}px`} // Convert inches to pixels
          display="flex"
          alignItems="center"
          justifyContent="flex-start" // Align content to the left side
          border="1px solid #888"
          borderRadius="4px"
          overflow="hidden"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            {/* QR Code */}
            <QRCodeRenderer value={`Preview`} size={qrSize * 96} />
            <Box ml={5}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: 12.0,
                  textAlign: "center",
                }}
              >
                Item
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PrintPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import axiosInstance from '../components/axiosInstance';
import logo from '../logoClean.svg';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0070c9',
    },
    secondary: {
      main: '#aeaeae',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VerifyEmailUpdate = () => {
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const verifyEmailUpdate = async () => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    try {
      const response = await axiosInstance.get(`/verify/email-update/?token=${token}`); // CHANGE the endpoint to whatever you've named it in your Express server
      setMessage(response.data.message);
      setOpen(true);
      setSeverity('success');
      enqueueSnackbar(response.data.message, { variant: 'success' });
    } catch (err) {
      setMessage(err.response.data.error);
      setOpen(true);
      if (err.response.data.error === 'Email already updated') {
        setSeverity('info');
      } else if (err.response.data.error === 'Token expired more than a day ago, please update your email again.') {
        setSeverity('warning');
      } else {
        setSeverity('error');
        enqueueSnackbar(err.response.data.error, { variant: 'error' });
      }
  }
}

  useEffect(() => {
    verifyEmailUpdate();
  }, [location.search, enqueueSnackbar]);

  useEffect(() => {
    verifyEmailUpdate();
    }, []);


  const handleNavigate = () => {
    navigate('/login');
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{ mt: 3 }} textAlign="center">
        <Button
          onClick={() => navigate('/')}
          color="primary"
          variant="contained"
          sx={{ position: 'absolute', top: 10, left: 10, backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'}}
        >
          <img src={logo} alt="CExpress logo" height="30" /> {/* Adjust the height as necessary */}
        </Button>
        <Container component="main" maxWidth="xs" sx={{
          display: 'flex', // Add this line to set display to flex
          flexDirection: 'column', // Set flex direction to column
          justifyContent: 'center', // Center items vertically in the container
          height: '100vh', // Set the height to fill the viewport height
          mt: 0, // You can remove the top margin now as it's centered
        }}>
          <Typography component="h1" variant="h5">
            Email Update Verification
          </Typography>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          {(severity === 'success' || (severity === 'info' && message === 'Email already updated')) && (
            <>
                <Typography variant="h6" component="p" gutterBottom>
                {severity === 'success' ? 'Your new email has been successfully verified 😊' : 'Your email was already updated.'}
                </Typography>
                <Button 
                variant="contained" 
                color="primary"
                sx={{
                    mt: 3,
                    mb: 2,
                    backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)',
                    boxShadow: '0 3px 5px 2px rgba(0, 112, 201, .3)',
                }}
                onClick={handleNavigate}>
                Go to Login
                </Button>
            </>
            )}
          {severity === 'warning' && message === 'Token expired more than a week ago, please sign up again.' && (
            <Typography variant="h6" component="p" gutterBottom>
              Sorry, your verification token has expired more than a week ago, please sign up again.
            </Typography>
          )}
          </Container>
        </Box>
    </ThemeProvider>
  );
};

export default VerifyEmailUpdate;

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  createTheme,
  ThemeProvider,
  Link,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Image from "../background.jpeg";
import logo from "../logo.svg";
import axiosInstance from "../components/axiosInstance";
import { CircularProgress } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#ffffff",
    },
    button: {
      main: "#0f0f0f",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Background = styled("div")({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  backgroundColor: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
  minHeight: "100vh",
  width: "100%",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  zIndex: -1,
});

const Home = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [trackers, setTrackers] = useState({
    totalPounds: 0,
    deliveriesMade: 0,
    itemsSent: 0,
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      axiosInstance.get("/packages/get_total_pounds_tracked"),
      axiosInstance.get("/packages/get_total_deliveries"),
      axiosInstance.get("/packages/get_all_items_sent"),
    ])
      .then(([poundsResponse, deliveriesResponse, itemsResponse]) => {
        setTrackers((prevState) => ({
          ...prevState,
          totalPounds: poundsResponse.data.totalPoundsTracked || 0,
          deliveriesMade: deliveriesResponse.data.totalPackagesTracked || 0,
          itemsSent: itemsResponse.data.totalItemsSent || 0,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Container
          style={{
            maxHeight: "100vh",
            width: "100%",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src={logo}
              alt="CExpress Logo"
              style={{ width: "250px", height: "auto" }}
            />
            <Typography variant="h4" align="center" mt={4}>
              Manage, Track, and Deliver Your Packages Effortlessly
            </Typography>
            <Typography variant="subtitle1" align="center" mt={2} mb={4}>
              With Cexpress, you're always in control. Join the revolution in
              package handling today.
            </Typography>
            <Box
              mt={4}
              mb={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h6">
                    Total Pounds Tracked: {trackers.totalPounds}
                  </Typography>
                  <Typography variant="h6">
                    Deliveries Made: {trackers.deliveriesMade}
                  </Typography>
                  <Typography variant="h6">
                    Items Sent: {trackers.itemsSent}
                  </Typography>
                </>
              )}
            </Box>

            <Divider
              variant="middle"
              style={{ width: "80%", marginBottom: "20px" }}
            />
            <Box
              mt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/login")}
              >
                Log In
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/register_business")}
              >
                Register Business
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/track")}
              >
                Track Package
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/shipment_options")}
              >
                Shipment Options
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 1,
                  p: 2,
                  width: 200,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                }}
                onClick={() => navigate("/app_download")}
              >
                Download App
              </Button>
            </Box>
            <Typography variant="body1" color="text.secondary" mt={8} mb={4}>
              Need assistance? email us at{" "}
              <Link href="mailto:support@cexpress.app" color="primary">
                support@cexpress.app
              </Link>
              .
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={8}>
              By using this service, you agree to the{" "}
              <Link href="/privacy_policy" color="primary">
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Container>
      </Background>
    </ThemeProvider>
  );
};

export default Home;

import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CountUp from "react-countup";
import "react-circular-progressbar/dist/styles.css";

const WalletBalance = ({ balance, totalBalance, previousBalance }) => {
  const percentage = (balance / totalBalance) * 100;
  const halfPercentage = percentage / 2;

  // Styles for the progress bar halves
  const progressStyles = buildStyles({
    strokeLinecap: "butt",
    pathColor: "white",
    trailColor: "white",
    backgroundColor: "transparent",
  });

  return (
    <div style={{ position: "relative", width: 200, height: 200 }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          color: "black",
          background: "white",
          borderRadius: "50%",
        }}
      >
        <div style={{ width: "100%", height: "100%", position: "absolute" }}>
          {/* Left semi-circle will fill up the left side of the circle */}
          <CircularProgressbar
            value={halfPercentage}
            styles={buildStyles({
              ...progressStyles,
              rotation: 0.5,
              pathColor: "green",
            })}
          />
        </div>
        <div style={{ width: "100%", height: "100%", position: "absolute" }}>
          {/* Right semi-circle will fill up the right side of the circle */}
          <CircularProgressbar
            value={halfPercentage}
            styles={buildStyles({
              ...progressStyles,
              pathColor: "green",
              rotation: 0.5,
              backgroundColor: "transparent",
              trailColor: "transparent",
            })}
            counterClockwise
          />
        </div>
        {/* Animated balance number */}
        <CountUp
          start={previousBalance}
          end={balance}
          duration={0.6}
          prefix="$"
          separator=","
          decimals={2}
          useEasing={true}
        />
      </div>
    </div>
  );
};

export default WalletBalance;

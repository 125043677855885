import React, { useEffect, useState } from 'react';
import axiosInstance from '../components/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Box, Grid, AppBar, Toolbar, IconButton, Button, TextField, Select, MenuItem, Dialog, Divider, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import markerIcon from '../deliverer.svg';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';
import ReceiptPopupVendor from '../components/PackageReceiptVendor';
import QRCodeItemPage from '../components/ItemsReceiptVendor';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import { parse } from 'libphonenumber-js';

// TODO: Handle edit logic for each field
// TODO: Fix package Icon not rendering

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};



const CourierDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [currentLocation, setCurrentLocation] = useState('');


  const navigate = useNavigate();
  // map related state
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const navigateToPackage = (id) => {
    navigate(`/packages/${id}`);
};

  const fetchData = async () => {
    try {
        const response = await axiosInstance.get(`/couriers/extended_info/${id}`);
        setData(response.data);
        
        setIsLoading(false);
    } catch (err) {
        
    }
  };

  const fetchAddress = async () => {
    try {
        if (data.location_latitude === null || data.location_longitude === null) {
            return;
        }
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.location_latitude},${data.location_longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`);
        if (response.data.results[0]) {
            setCurrentLocation(response.data.results[0].formatted_address);
        } else {
            
        }
    } catch(error) {
        
    }
  };


  useEffect(() => {
      fetchData();
  }, []);

if (!data) {
    return (
        <Box sx={{ display: 'flex' }}>
        <Box
            sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
            visibility: isLoading ? 'visible' : 'hidden'
            }}
        >
            <CircularProgress />
        </Box>
        </Box>
    )
}

return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed" display="flex" width="100%"
    sx={{
      backgroundImage:'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'
    }}>
        <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                Back
            </Typography>
            <Typography variant="h6" component="div" sx={{ mr:8.0, flexGrow: 1, textAlign: 'left' }}>
                Courier Details
            </Typography>
        </Toolbar>
        </AppBar>

        <Container maxWidth="100%" sx={{ mt: '64px' }}>
        <Paper sx={{ p: 3 }}>
            {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress />
            </Box>
            ) : (
            <>
                <Typography variant="h4" gutterBottom component="div">
                {`${data.first_name} ${data.last_name}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                {`Email: ${data.email}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                {`Phone: ${data.phone}`}
                </Typography>
                <Typography variant="body1" gutterBottom>
                {`Status: ${data.activity_status}`}
                </Typography>
                <Divider sx={{ my: 2 }} />

                <TableContainer component={Paper}>
                <Typography variant="h5" gutterBottom>
                    Assigned Packages:
                </Typography>
                <Typography variant="body1">
                    Total Packages: {data.total_packages.length}
                </Typography>
                <Typography variant="body1">
                    Total Weight: {data.total_weight} kg
                </Typography>
                <Typography variant="body1">
                    Total Value: ${data.total_price}
                </Typography>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Package ID</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.total_packages && data.total_packages.map((row) => (
                        <TableRow key={row.package_id} onClick={() => navigateToPackage(row.package_id)} style={{cursor: 'pointer'}}>
                        <TableCell>{row.package_id}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                <Typography variant="h5" gutterBottom>
                    Delivering Packages:
                </Typography>
                <Typography variant="body1">
                    Packages: {data.total_packages_current.length}
                </Typography>
                <Typography variant="body1">
                    Weight: {data.total_weight_current} kg
                </Typography>
                <Typography variant="body1">
                    Value: ${data.total_price_current}
                </Typography>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Package ID</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.total_packages_current && data.total_packages_current.map((row) => (
                        <TableRow key={row.package_id} onClick={() => navigateToPackage(row.package_id)} style={{cursor: 'pointer'}}>
                        <TableCell>{row.package_id}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>

                <TableContainer component={Paper}>
                <Typography variant="h5" gutterBottom>
                    Delivered Packages:
                </Typography>
                <Typography variant="body1">
                    Packages: {data.total_packages_completed.length}
                </Typography>
                <Typography variant="body1">
                    Weight: {data.total_weight_completed} kg
                </Typography>
                <Typography variant="body1">
                    Value: ${data.total_price_completed}
                </Typography>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Package ID</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.total_packages_completed && data.total_packages_completed.map((row) => (
                        <TableRow key={row.package_id} onClick={() => navigateToPackage(row.package_id)} style={{cursor: 'pointer'}}>
                        <TableCell>{row.package_id}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h5" gutterBottom>
                Current Location:
                </Typography>

                <GoogleMap mapContainerStyle={mapContainerStyle} center={{ lat: data.location_latitude, lng: data.location_longitude }} zoom={10}>
                <MarkerF
                    position={{ lat: Number(data.location_latitude), lng: Number(data.location_longitude) }}
                    icon={{
                    url: markerIcon,
                    scaledSize: new window.google.maps.Size(50, 50),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(25, 25),
                    }}
                />
                </GoogleMap>
            </>
            )}
        </Paper>
        </Container>
    </Box>
    );
};

export default CourierDetails;

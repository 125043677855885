import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { enqueueSnackbar } from "notistack";
import SenderInfoForm from "../components/SenderInfoForm";
import PackageDetails from "../components/PackageDetails";
import RecipientInfoForm from "../components/RecipientInfoForm";
import PackageContents from "../components/PackageContents";
import DeliveryOptions from "../components/DeliveryOptions";
import ReviewForm from "../components/ReviewForm";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import PackageReceipt from "../components/PackageReceipt";
import Modal from "react-modal";
import PaymentEditDialog from "./PackagePaymentsPage";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  Tooltip,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Step,
  StepLabel,
  Stepper,
  TableSortLabel,
  TablePagination,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Tabs,
  Tab,
  FormControl,
  Checkbox,
} from "@mui/material";

const PackagePayments = ({ history }) => {
  const theme = useTheme();
  const location = useLocation();
  const [payments, setPayments] = useState([]);
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: localStorage.getItem("sortKey") || "created_at",
    direction: localStorage.getItem("sortDirection") || "asc",
  });
  const [filter, setFilter] = useState(localStorage.getItem("filter") || "");
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );

  let [sortedPayments, setSortedPayments] = useState([]);
  const [displayPayments, setDisplayPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  // Batch actions
  let [selectedPayments, setSelectedPayments] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Tabs
  const appBarHeight = 64;
  const [tabValue, setTabValue] = useState(1); // State to track the current tab

  // Dialog
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleRowClick = (payment) => {
    setSelectedPayment(payment);
    setIsDialogOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Navigate to the correct tab
    switch (newValue) {
      case 0:
        navigate(`/other_packages`);
        break;
      case 1:
        navigate(`/package_payments`);
        break;
      default:
        navigate(`/other_packages`);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await axiosInstance.get(
        "/payments/get_all_owed_payments"
      );
      console.log("Payments are:", response);
      setPayments(response.data.data);
      console.log("Payments:", payments);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  useEffect(() => {
    fetchPayments();
    setIsLoading(false);
  }, []);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    // Update localStorage
    localStorage.setItem("sortKey", key);
    localStorage.setItem("sortDirection", direction);
  };

  sortedPayments = useMemo(() => {
    return [...payments].sort((a, b) => {
      if (sortConfig.key === "weight" || sortConfig.key === "amount") {
        let numA = parseFloat(a[sortConfig.key]);
        let numB = parseFloat(b[sortConfig.key]);

        if (numA < numB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (numA > numB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      } else if (
        sortConfig.key === "date_added" ||
        sortConfig.key === "date_updated"
      ) {
        let dateA = new Date(a[sortConfig.key]);
        let dateB = new Date(b[sortConfig.key]);

        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [payments, sortConfig]);

  useEffect(() => {
    let updatedPayments = [...sortedPayments];

    // Filter logic
    if (filter) {
      updatedPayments = updatedPayments.filter((pkg) => pkg.status === filter);
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      updatedPayments = updatedPayments.filter((pkg) => {
        return (
          pkg.original_vendor_name.toLowerCase().includes(lowerSearchTerm) ||
          pkg.package_id.toLowerCase().includes(lowerSearchTerm) ||
          pkg.tracking_number.toLowerCase().includes(lowerSearchTerm) ||
          pkg.status.toLowerCase().includes(lowerSearchTerm) ||
          String(pkg.weight).includes(searchTerm) ||
          String(pkg.amount).includes(searchTerm)
        );
      });
    }

    // Reset page number and update localStorage when filter or searchTerm changes
    setPage(0);
    localStorage.setItem("currentPage", "0");

    if (filter) {
      // Update localStorage when filter changes
      localStorage.setItem("filter", filter);
    } else {
      localStorage.setItem("filter", "");
    }

    if (searchTerm) {
      // Update localStorage when searchTerm changes
      localStorage.setItem("searchTerm", searchTerm);
    } else {
      localStorage.setItem("searchTerm", "");
    }

    setDisplayPayments(updatedPayments); // setting the state here
  }, [sortedPayments, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Initialize page and rowsPerPage from localStorage
    const savedPage = parseInt(localStorage.getItem("currentPage"), 10) || 0;
    const savedRowsPerPage =
      parseInt(localStorage.getItem("currentRowsPerPage"), 10) || 20;
    setPage(savedPage);
    setRowsPerPage(savedRowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("currentPage", newPage.toString());
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page changes
    localStorage.setItem("currentRowsPerPage", newRowsPerPage.toString());
    localStorage.setItem("currentPage", "0");
  };

  const displayedPayments = displayPayments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Package Details" />
          <Tab label="Package Payments" />
        </Tabs>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Other Package Payments
            </Typography>
            <Button onClick={fetchPayments}>
              <RefreshIcon />
            </Button>
          </Box>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            sx={{ width: "300px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ minWidth: 120, height: "40px" }}>
            <Select
              labelId="filter-label"
              value={filter}
              displayEmpty
              sx={{ height: "40px", width: "150px" }}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="">
                <em>Status...</em>
              </MenuItem>
              <MenuItem value="pending"> Pending </MenuItem>
              <MenuItem value="completed"> Completed </MenuItem>
              <MenuItem value="failed"> Failed </MenuItem>
              <MenuItem value="cancelled"> Cancelled </MenuItem>
              <MenuItem value="awaiting_payment"> Awaiting Payment </MenuItem>
              <MenuItem value="awaiting_price"> Awaiting Price </MenuItem>
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: `${appBarHeight}px`, // Set marginTop to the AppBar's height
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650, marginTop: 5 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {accessLevel <= 3 && (
                    <Checkbox
                      checked={isAllSelected}
                      onChange={() => {
                        setIsAllSelected(!isAllSelected);
                        setSelectedPayments(
                          isAllSelected
                            ? []
                            : displayPayments.map((pkg) => pkg.package_id)
                        );
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>Action Items</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "created_at"}
                    direction={
                      sortConfig.key === "created_at"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("created_at")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "status"}
                    direction={
                      sortConfig.key === "status" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "payer_vendor_name"}
                    direction={
                      sortConfig.key === "payer_vendor_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("payer_vendor_name")}
                  >
                    Payer Vendor Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "amount"}
                    direction={
                      sortConfig.key === "amount" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("amount")}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "tracking_number"}
                    direction={
                      sortConfig.key === "tracking_number"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("tracking_number")}
                  >
                    Tracking Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "weight"}
                    direction={
                      sortConfig.key === "weight" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("weight")}
                  >
                    Weight
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedPayments.map((pkg) => {
                // // Check if the package has not been delivered yet
                // const currentDate = new Date();
                // const estDeliveryDate = new Date(pkg.est_delivery);
                // const isDelayed =
                //   currentDate > estDeliveryDate && pkg.status !== "DELIVERED";
                // let delayInDays = 0;
                // if (isDelayed) {
                //   delayInDays = Math.ceil(
                //     (currentDate - estDeliveryDate) / (1000 * 60 * 60 * 24)
                //   );
                // }

                // // Determine the background color
                // let backgroundColor = "inherit";
                // if (
                //   isDelayed ||
                //   pkg.status === "canceled"
                // ) {
                //   backgroundColor = "rgba(255, 0, 0, 0.2)"; // Red for urgent issues
                // } else if (
                //   pkg.status !== "DELIVERED" &&
                //   (!pkg.couriers || pkg.couriers.length === 0)
                // ) {
                //   backgroundColor = "rgba(255, 165, 0, 0.2)"; // Orange for missing courier
                // }

                return (
                  <TableRow
                    key={pkg.package_id}
                    hover
                    onClick={() => handleRowClick(pkg)}
                    sx={{
                      cursor: "pointer",
                      //   backgroundColor: backgroundColor,
                    }}
                  >
                    <TableCell>
                      {accessLevel <= 3 && (
                        <Checkbox
                          checked={selectedPayments.includes(pkg.package_id)}
                          onChange={() => {
                            const newSelectedPayments =
                              selectedPayments.includes(pkg.package_id)
                                ? selectedPayments.filter(
                                    (id) => id !== pkg.package_id
                                  )
                                : [...selectedPayments, pkg.package_id];
                            setSelectedPayments(newSelectedPayments);
                            setIsAllSelected(
                              newSelectedPayments.length ===
                                displayPayments.length
                            );
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {/* {pkg.couriers.length !== 0 &&
                      pkg.status !== "LOST" &&
                      pkg.status !== "INCOMPLETE" ? (
                        <span style={{ color: "green" }}>
                          All good here! 😊
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {(!pkg.couriers || pkg.couriers.length === 0) && (
                            <>
                              No Courier Assigned
                              <br />
                            </>
                          )}
                          {pkg.status === "LOST" && (
                            <>
                              Package Lost
                              <br />
                            </>
                          )}
                          {pkg.status === "INCOMPLETE" && (
                            <>
                              Package Incomplete
                              <br />
                            </>
                          )}
                        </span>
                      )} */}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {new Date(pkg.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      {(() => {
                        switch (pkg.status) {
                          case "pending":
                            return "Pending";
                          case "completed":
                            return "Completed";
                          case "failed":
                            return "Failed";
                          default:
                            return pkg.status;
                        }
                      })()}
                    </TableCell>
                    <TableCell>{pkg.original_vendor_name}</TableCell>
                    <TableCell>{pkg.amount}</TableCell>
                    <TableCell>{pkg.tracking_number}</TableCell>
                    <TableCell>{pkg.weight}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 80, 100]}
            component="div"
            count={displayPayments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        {selectedPayment && (
          <PaymentEditDialog
            payment={selectedPayment}
            open={isDialogOpen}
            handleClose={() => setIsDialogOpen(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default PackagePayments;

import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Typography,
  Grid,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { isValidNumber } from "libphonenumber-js";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
import * as yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import axios from "axios";

const mapStyles = {
  borderRadius: "15px",
  // Add any other desired properties here.
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden", // This ensures the borderRadius affects the inner map.
  height: "400px",
  width: "100%",
};

const EditCustomerDialog = ({
  isOpen,
  handleClose,
  handleSave,
  customer_id,
  first_name,
  last_name,
  email,
  phone,
  foreign_id,
  address,
  address_latitude,
  address_longitude,
}) => {
  const [addressError, setAddressError] = useState(false);

  const [formData, setFormData] = useState({
    customerId: customer_id || "",
    firstName: first_name || "",
    lastName: last_name || "",
    email: email || "",
    phone: phone || "",
    foreignId: foreign_id || "",
    address: address || "",
    address_latitude: address_latitude || "",
    address_longitude: address_longitude || "",
  });

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [eitherPhoneOrEmailError, setEitherPhoneOrEmailError] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const initialDataRef = useRef({
    customerId: customer_id,
    firstName: first_name,
    lastName: last_name,
    email: email,
    phone: phone,
    foreignId: foreign_id,
    address: address,
    address_latitude: address_latitude,
    address_longitude: address_longitude,
  });

  const hasDataChanged = () => {
    const initialData = initialDataRef.current;
    console.log("Initial data:", initialData);
    console.log("Form data:", formData);

    return (
      initialData.firstName !== formData.firstName ||
      initialData.lastName !== formData.lastName ||
      (initialData.email && initialData.email !== formData.email) ||
      (initialData.phone && initialData.phone !== formData.phone) ||
      initialData.foreignId !== formData.foreignId ||
      initialData.address !== formData.address ||
      initialData.address_latitude !== formData.address_latitude ||
      initialData.address_longitude !== formData.address_longitude
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (value) => yup.string().email().isValidSync(value);
  const isValidPhone = (phone) => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    return phoneNumber ? phoneNumber.isValid() : false;
  };

  useEffect(() => {
    setFirstNameError(!formData.firstName.trim());
    setLastNameError(!formData.lastName.trim());

    const emailIsValid = formData.email ? isValidEmail(formData.email) : true;
    setEmailError(!emailIsValid);

    // Ensure the phone number is processed correctly before validation
    const phoneIsValid = formData.phone ? isValidPhone(formData.phone) : true;
    setPhoneError(!phoneIsValid);

    setEitherPhoneOrEmailError(
      !((formData.email && emailIsValid) || (formData.phone && phoneIsValid))
    );
  }, [formData.firstName, formData.lastName, formData.email, formData.phone]);

  useEffect(() => {
    console.log("Checking if data has changed");
    const dataChanged = hasDataChanged();
    console.log("Data has changed:", dataChanged);
    setIsUpdated(dataChanged);
  }, [
    formData.firstName,
    formData.lastName,
    formData.email,
    formData.phone,
    formData.foreignId,
    formData.address,
    formData.address_latitude,
    formData.address_longitude,
  ]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value, countryData) => {
    let newPhoneValue = value;
    if (newPhoneValue[0] !== "+") {
      newPhoneValue = "+" + newPhoneValue;
    }

    const phoneNumber = parsePhoneNumberFromString(
      newPhoneValue,
      countryData?.countryCode
    );
    const isValid = phoneNumber ? phoneNumber.isValid() : false;

    setPhoneError(!isValid);

    // Update formData with the formatted phone number or the raw input
    setFormData({
      ...formData,
      phone: isValid ? phoneNumber.formatInternational() : newPhoneValue,
    });
  };

  const handleAddressInputChange = (value) => {
    setFormData({ ...formData, address: value });

    // if the address is equal to the original address then it is valid
    if (value === initialDataRef.current.address) {
      setAddressError(false);
      return;
    }

    setAddressError(true);
  };

  const handleAddressSelect = (address) => {
    setFormData((prevData) => ({
      ...prevData,
      address: address,
    }));
    getLatLngFromAddress(address).then((location) => {
      setFormData((prevData) => ({
        ...prevData,
        address_latitude: location.lat,
        address_longitude: location.lng,
      }));
    });
    setAddressError(false);
  };

  const handleMapClick = async (e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    await generateAddressFromCoordinates(latitude, longitude);
  };

  const getLatLngFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
      );
      const data = await response.json();
      const location = data.results[0].geometry.location;
      return location;
    } catch (err) {
      console.log(err);
    }
  };

  const generateAddressFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await axios(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
      );
      if (response.data.results[0]) {
        const newAddress = response.data.results[0].formatted_address;
        setFormData((prevData) => ({
          ...prevData,
          address: newAddress,
          address_latitude: latitude,
          address_longitude: longitude,
        }));
        setAddressError(false);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          address: "",
          address_latitude: "",
          address_longitude: "",
        }));
      }
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "90%",
          height: "90%",
          maxHeight: "100%",
          margin: 0,
          p: 0,
          borderRadius: 4,
        },
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant="h4" style={{ margin: "20px 0" }}>
          Edit Customer Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              error={firstNameError}
              helperText={firstNameError && "First name is required"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              error={lastNameError}
              helperText={lastNameError && "Last name is required"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              error={emailError || eitherPhoneOrEmailError}
              helperText={
                (emailError && "Email is invalid") ||
                (eitherPhoneOrEmailError && "Email or phone is required")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              value={formData.phone}
              onChange={handlePhoneChange}
              inputStyle={{ width: "100%" }}
              countryCodeEditable={false}
              enableCountryCode={true}
              enableSearch={true}
              isValid={() => !phoneError}
              placeholder="Enter phone number"
              error={phoneError || eitherPhoneOrEmailError}
              helperText={
                (phoneError && "Phone number is invalid") ||
                (eitherPhoneOrEmailError && "Email or phone is required")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Foreign ID"
              name="foreignId"
              value={formData.foreignId}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PlacesAutocomplete
              value={formData.address}
              onChange={(address) => handleAddressInputChange(address)}
              onSelect={(address) => handleAddressSelect(address)}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextField
                    {...getInputProps({ placeholder: "Search Places ..." })}
                    fullWidth
                    variant="outlined"
                    label="Address"
                    error={addressError}
                    helperText={
                      addressError &&
                      "Please select an address from the dropdown"
                    }
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.placeId}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">Map:</Typography>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={18}
              center={{
                lat: formData.address_latitude,
                lng: formData.address_longitude,
              }}
              // onClick={handleMapClick}
            >
              {formData.address_latitude && formData.address_longitude && (
                <MarkerF
                  position={{
                    lat: formData.address_latitude,
                    lng: formData.address_longitude,
                  }}
                />
              )}
            </GoogleMap>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            handleSave(
              formData.customerId,
              formData.firstName,
              formData.lastName,
              formData.email,
              formData.phone,
              formData.foreignId,
              formData.address,
              formData.address_latitude,
              formData.address_longitude
            )
          }
          color="primary"
          disabled={
            firstNameError ||
            lastNameError ||
            emailError ||
            phoneError ||
            eitherPhoneOrEmailError ||
            addressError ||
            !isUpdated ||
            isLoading
          }
        >
          Save
        </Button>
      </DialogActions>

      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default EditCustomerDialog;

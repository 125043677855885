import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { useFormData } from "../FormDataContext";
import { enqueueSnackbar } from "notistack";
import SenderInfoForm from "../components/SenderInfoForm";
import PackageDetails from "../components/PackageDetails";
import RecipientInfoForm from "../components/RecipientInfoForm";
import PackageContents from "../components/PackageContents";
import DeliveryOptions from "../components/DeliveryOptions";
import ReviewForm from "../components/ReviewForm";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import PackageReceipt from "../components/PackageReceipt";
import Modal from "react-modal";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";

import CreateManifestDialog from "../components/NewManifestDialogComponent";
import ManifestDetailsPopup from "../components/ManifestDetailsPopup";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  Tooltip,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Step,
  StepLabel,
  Stepper,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  FormControl,
  Checkbox,
  Tab,
  Tabs,
} from "@mui/material";

// TODO: add session storage for package data
// TODO: add current location to package data

const Manifests = () => {
  const theme = useTheme();
  const location = useLocation();
  const [manifests, setManifests] = useState([]);
  const { open, setOpen } = useContext(DrawerContext);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: localStorage.getItem("sortKey") || "createdAt",
    direction: localStorage.getItem("sortDirection") || "asc",
  });
  const [currManifestId, setCurrManifestId] = useState(null);
  const [selectedManifests, setselectedManifests] = useState([]);
  const [filter, setFilter] = useState(
    localStorage.getItem("manifestFilter") || ""
  );
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );

  let [sortedManifests, setSortedManifests] = useState([]);
  const [displayManifests, setDisplayManifests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  // Manifests
  const currentTab = location.pathname === "/manifests" ? 1 : 0;
  const [tabValue, setTabValue] = useState(currentTab);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    // Navigate to the correct tab
    switch (newValue) {
      case 0:
        navigate(`/packages`);
        break;
      case 1:
        navigate(`/manifests`);
        break;
      default:
        navigate(`/packages`);
    }
  };

  useEffect(() => {
    // Initialize page and rowsPerPage from localStorage
    const savedPage = parseInt(localStorage.getItem("currentPage"), 10) || 0;
    const savedRowsPerPage =
      parseInt(localStorage.getItem("currentRowsPerPage"), 10) || 20;
    setPage(savedPage);
    setRowsPerPage(savedRowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("currentPage", newPage.toString());
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page changes
    localStorage.setItem("currentRowsPerPage", newRowsPerPage.toString());
    localStorage.setItem("currentPage", "0");
  };

  const fetchManifests = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/packages/get_manifests");
      if (response.status === 200) {
        const parsedManifests = response.data.map((manifest) => ({
          ...manifest,
          packages: JSON.parse(manifest.packages),
        }));
        console.log("Manifests:", parsedManifests);
        setManifests(parsedManifests);
      } else {
        enqueueSnackbar("Error occurred while getting manifests", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Error occurred while getting manifests", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchShipmentTypes = async () => {
    try {
      const response = await axiosInstance.get("/packages/get_shipments");
      if (response.status === 200) {
        setShipmentTypes(response.data);
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting shipment types", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting shipment types", {
        variant: "error",
      });
    }
  };

  const navigateToCreateManifests = () => {
    setOpenDialog(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    setIsLoading(true); // Set loading to true at the beginning of data fetching
    Promise.all([fetchManifests(), fetchShipmentTypes()])
      .catch((error) => {
        console.error("Failed to fetch initial data:", error);
        enqueueSnackbar("Error occurred while getting initial data", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClose = () => {
    setActiveStep(0);
    setOpenDialog(false);
  };

  const navigateToManifestDetails = (id) => {
    navigate(`/manifests/${id}`);
  };

  const handleReceiptClose = () => {
    setCurrManifestId(null);
    setIsReceiptOpen(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    // Update localStorage
    localStorage.setItem("sortKey", key);
    localStorage.setItem("sortDirection", direction);
  };

  sortedManifests = useMemo(() => {
    return [...manifests].sort((a, b) => {
      if (sortConfig.key === "weight" || sortConfig.key === "price") {
        let numA = parseFloat(a[sortConfig.key]);
        let numB = parseFloat(b[sortConfig.key]);

        if (numA < numB) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (numA > numB) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      } else if (
        sortConfig.key === "createdAt" ||
        sortConfig.key === "updatedAt"
      ) {
        let dateA = new Date(a[sortConfig.key]);
        let dateB = new Date(b[sortConfig.key]);

        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });
  }, [manifests, sortConfig]);

  useEffect(() => {
    let updatedManifests = [...sortedManifests];

    // Filter logic
    if (filter) {
      updatedManifests = updatedManifests.filter(
        (manifest) => manifest.status === filter
      );
    }

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      updatedManifests = updatedManifests.filter((manifest) => {
        return (
          manifest.employee_name.toLowerCase().includes(lowerSearchTerm) ||
          manifest.sender_name.toLowerCase().includes(lowerSearchTerm) ||
          manifest.recipient_name.toLowerCase().includes(lowerSearchTerm) ||
          manifest.package_id.toLowerCase().includes(lowerSearchTerm) ||
          // Enhanced Search logic for array of courier names
          (manifest.courier_names &&
            manifest.courier_names.some((name) =>
              name.toLowerCase().includes(lowerSearchTerm)
            )) ||
          manifest.shipment_type_name.toLowerCase().includes(lowerSearchTerm) ||
          manifest.tracking_number.toLowerCase().includes(lowerSearchTerm) ||
          manifest.status.toLowerCase().includes(lowerSearchTerm) ||
          String(manifest.weight).includes(searchTerm) ||
          String(manifest.price).includes(searchTerm)
        );
      });
    }

    // Reset page number and update localStorage when filter or searchTerm changes
    setPage(0);
    localStorage.setItem("currentPage", "0");

    if (filter) {
      // Update localStorage when filter changes
      localStorage.setItem("manifestFilter", filter);
    } else {
      localStorage.setItem("manifestFilter", "");
    }

    if (searchTerm) {
      // Update localStorage when searchTerm changes
      localStorage.setItem("searchTerm", searchTerm);
    } else {
      localStorage.setItem("searchTerm", "");
    }

    setDisplayManifests(updatedManifests); // setting the state here
  }, [sortedManifests, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/manifests",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const displayedManifests = displayManifests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Packages" />
          <Tab label="Manifests" />
        </Tabs>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Manifests
            </Typography>
            <Button onClick={fetchManifests}>
              <RefreshIcon />
            </Button>
          </Box>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            sx={{ width: "300px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={navigateToCreateManifests}
            sx={{
              mt: 1.5,
              mb: 1.5,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
              boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
            }}
          >
            + New Manifest
          </Button>
          <CreateManifestDialog
            openDialog={openDialog}
            handleClose={handleClose}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
          // Add transition time here
        }}
      >
        <Dialog
          open={isReceiptOpen}
          onClose={handleReceiptClose}
          aria-labelledby="receipt-dialog"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            {currManifestId && (
              <ManifestDetailsPopup manifestId={currManifestId} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleReceiptClose}
              color="primary"
              disabled={isLoading}
            >
              Close
            </Button>
            <Button onClick={handlePrint} color="primary" disabled={isLoading}>
              Print
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer>
          <Table sx={{ minWidth: 650, marginTop: 6 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "createdAt"}
                    direction={
                      sortConfig.key === "createdAt"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("createdAt")}
                  >
                    Date Added
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "shipment_type_name"}
                    direction={
                      sortConfig.key === "shipment_type_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("shipment_type_name")}
                  >
                    Shipment Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "manifest_id"}
                    direction={
                      sortConfig.key === "manifest_id"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("manifest_id")}
                  >
                    Manifest ID
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">Receipt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedManifests.map((manifest) => {
                return (
                  <TableRow
                    key={manifest.manifest_id}
                    hover
                    // navigate to manifest details page
                    onClick={() =>
                      navigateToManifestDetails(manifest.manifest_id)
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(manifest.createdAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </TableCell>
                    <TableCell>{manifest.shipment_type_name}</TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        transition: "opacity 0.5s",
                      }}
                    >
                      {manifest.manifest_id}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      onMouseEnter={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.cursor = "default";
                      }}
                    >
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrManifestId(manifest.manifest_id);
                          setIsReceiptOpen(true);
                        }}
                      >
                        <span>📃</span>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 80, 100]}
            component="div"
            count={displayManifests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Manifests;

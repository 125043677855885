import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";
import { styled } from "@mui/system";
import Image from "../background.jpeg";
import logo from "../logo.svg";
import logoClean from "../logoClean.svg";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#ffffff",
    },
    button: {
      main: "#0f0f0f",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Background = styled("div")({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  width: "100%",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  zIndex: -1,
});

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Button
          onClick={() => navigate("/")}
          color="primary"
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
          }}
        >
          <img src={logoClean} alt="CExpress logo Clean" height="30" />{" "}
        </Button>
        <Container
          style={{
            maxHeight: "90vh",
            width: "60%",
            overflowY: "auto",
            position: "relative",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src={logo}
              alt="CExpress Logo"
              style={{ width: "150px", height: "auto" }}
            />

            <Typography variant="h4" align="center" mt={4} mb={4}>
              Privacy Policy
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              Introduction
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              Welcome to CExpress. This Privacy Policy outlines our practices
              concerning the use, access, and disclosure of your personal
              information. By visiting our website and using our services, you
              agree to the terms outlined in this policy.
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              Information We Collect
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              We collect various types of information, including your name,
              email address, and usage data to improve our services and your
              user experience.
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              How We Use Your Information
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              Your information is used to personalize your experience, provide
              customer support, and improve our services.
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              How We Share Your Information
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              We do not share your personal information with third parties
              without your consent, except to comply with laws or to protect our
              rights.
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              Your Rights and Choices
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              You have the right to access, modify, or delete your personal
              information at any time. Please contact us to exercise these
              rights.
            </Typography>

            <Typography variant="h6" mt={2} mb={2}>
              Security
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              We prioritize the protection of your data and implement robust
              security measures to safeguard it. However, we cannot guarantee
              its absolute security.
            </Typography>

            <Typography variant="body1" color="text.secondary" mt={4} mb={8}>
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <Link href="mailto:support@cexpress.app" color="primary">
                support@cexpress.app
              </Link>
              .
            </Typography>
          </Box>
        </Container>
      </Background>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;

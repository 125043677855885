import {
  TextField,
  Paper,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  snackbarClasses,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useEffect, useState, useRef, useCallback } from "react";
import * as yup from "yup";
import axiosInstance from "../components/axiosInstance";
import { enqueueSnackbar } from "notistack";
import PlacesAutocomplete from "react-places-autocomplete";
import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { FormHelperText } from "@mui/material";
import { isValidNumber } from "libphonenumber-js";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { GoogleMap, MarkerF, InfoWindow } from "@react-google-maps/api";
import debounce from "lodash/debounce";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";

// TODO: if in edit mode, put phone or email already exist error messages in handlephone input and handle email input change and disable autocomplete selection
// TODO: Propagate enrollment logic to the receiver form
// TODO: Add option to Edit information even if not enrolled, make form immutable if autocomplete is used

const mapStyles = {
  borderRadius: "15px",
  // Add any other desired properties here.
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden", // This ensures the borderRadius affects the inner map.
  height: "400px",
  width: "100%",
};

const SenderInfoForm = ({
  formData,
  handleInputChange,
  setFormData,
  handleFormCompletion,
  customerEmails,
  customerPhones,
  handleClose,
}) => {
  const [missingEmailorPhone, setMissingEmailorPhone] = useState(true);
  const [country, setCountry] = useState("us");
  const [dialCode, setDialCode] = useState("1");
  const [isUpdated, setIsUpdated] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(
    formData.sender_first_name === "" ? true : false
  );
  const [lastNameError, setLastNameError] = useState(
    formData.sender_last_name === "" ? true : false
  );
  const [senderAddress, setSenderAddress] = useState(formData.sender_address);
  const [addressError, setAddressError] = useState(
    senderAddress === "" ? true : false
  );
  const [completeForm, setCompleteForm] = useState(false);
  const [allowClear, setAllowClear] = useState(false);
  const [initialCustomerInfo, setInitialCustomerInfo] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formCleared, setFormCleared] = useState(false);

  const generateSenderAddressFromCoordinates = async (latitude, longitude) => {
    try {
      console.log("fetching address");
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
      );
      if (response.data.results[0]) {
        console.log("response is not empty");
        console.log(response.data.results[0].formatted_address);
        setSenderAddress(response.data.results[0].formatted_address);
        handleInputChange({
          target: {
            name: "sender_address",
            value: response.data.results[0].formatted_address,
          },
        });
        setAddressError(false);
      } else {
        setSenderAddress("");
        handleInputChange({
          target: {
            name: "sender_address",
            value: "",
          },
        });
      }
    } catch (err) {
      handleInputChange({
        target: {
          name: "sender_address",
          value: "",
        },
      });
    }
  };

  // missing email or phone
  useEffect(() => {
    if (
      (!emailError &&
        formData.sender_email === "" &&
        !phoneError &&
        formData.sender_phone === "+" + dialCode) ||
      (phoneError && formData.sender_email === "") ||
      (emailError && formData.sender_phone === "+" + dialCode) ||
      (phoneError && emailError)
    ) {
      setMissingEmailorPhone(true);
    } else {
      setMissingEmailorPhone(false);
    }
  }, [
    emailError,
    phoneError,
    formData.sender_email,
    formData.sender_phone,
    dialCode,
  ]);

  // Editing the form
  const handleEditInfo = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    // Revert formData to initial state
    let canceledFields = { ...formData };
    canceledFields.sender_email = initialCustomerInfo.email;
    canceledFields.sender_phone = initialCustomerInfo.phone;
    canceledFields.sender_first_name = initialCustomerInfo.first_name;
    canceledFields.sender_last_name = initialCustomerInfo.last_name;
    canceledFields.sender_address_latitude =
      initialCustomerInfo.address_latitude;
    canceledFields.sender_address_longitude =
      initialCustomerInfo.address_longitude;
    canceledFields.sender_addres = initialCustomerInfo.address;
    setFormData(canceledFields);
    setIsUpdated(false);
    setEditMode(false);
  };

  const handleConfirmUpdates = () => {
    // Set update_sender_info to true
    formData.update_sender_info = true;
    setEditMode(false);
  };

  // Check if the form has been updated
  useEffect(() => {
    if (editMode && !isUpdated) {
      const isFormDataUpdated =
        initialCustomerInfo.first_name !== formData.sender_first_name ||
        initialCustomerInfo.last_name !== formData.sender_last_name ||
        initialCustomerInfo.address_latitude !==
          formData.sender_address_latitude ||
        initialCustomerInfo.address_longitude !==
          formData.sender_address_longitude ||
        initialCustomerInfo.address !== formData.sender_address ||
        initialCustomerInfo.email !== formData.sender_email ||
        initialCustomerInfo.phone !== formData.sender_phone ||
        initialCustomerInfo.foreign_id !== formData.sender_foreign_id;
      setIsUpdated(isFormDataUpdated);
    }
  }, [
    formData.sender_first_name,
    formData.sender_last_name,
    formData.sender_address_latitude,
    formData.sender_address_longitude,
    formData.sender_address,
    formData.sender_email,
    formData.sender_phone,
    formData.sender_foreign_id,
    initialCustomerInfo,
    editMode,
  ]);

  const debouncedHandleEmailInputChange = useCallback(
    debounce((event, value, reason) => {
      if (formCleared) {
        return;
      }
      handleEmailInputChange(event, value, reason);
    }, 100),
    []
  );

  // Debounced version of phone input change handler
  const debouncedHandlePhoneInputChange = useCallback(
    debounce((value, country) => {
      if (formCleared) {
        return;
      }
      handlePhoneInputChange(value, country);
    }, 100),
    []
  );

  // Handle email input change
  const handleEmailInputChange = (event, value, reason) => {
    // if (isLoading) {
    //   setIsLoading(false);
    //   return;
    // }

    // Check if email is valid
    const isValidEmail = value ? yup.string().email().isValidSync(value) : true;
    setEmailError(!isValidEmail);

    handleInputChange({
      target: {
        name: "sender_email",
        value: value,
      },
    });

    if (customerEmails.includes(value)) {
      console.log("email is in customer emails");
      autoCompleteFormEmail(value);
    } else if (!formData.new_sender && !customerEmails.includes(value)) {
      clearForm(true, false);
    }
  };

  // Handle phone input change
  const handlePhoneInputChange = (value, country) => {
    // if (isLoading) {
    //   setIsLoading(false);
    //   return;
    // }

    // setCountry(country.countryCode);
    setDialCode(country.dialCode);
    if (value[0] !== "+") {
      value = "+" + value;
    }

    const isValid = isValidNumber(value, country.countryCode.toUpperCase());
    // Phone can be empty

    setPhoneError(!isValid);
    if (value === "+" + dialCode) {
      setPhoneError(false);
    }

    if (!isValid) {
      // If value does not have a + sign, add it
      formData.sender_phone = value;
      handleInputChange({
        target: {
          name: "sender_phone",
          value: value,
        },
      });

      if (!formData.new_sender && !customerPhones.includes(value)) {
        clearForm(false, true);
      }
      return;
    }

    // If valid continue to format number
    // convert value to E.164 format
    // String to value append +

    let phoneString = value.toString();

    const phoneNumber = parsePhoneNumberFromString(phoneString);

    let e164format = phoneNumber.formatInternational(); // converts to E.164 forma

    handleInputChange({
      target: {
        name: "sender_phone",
        value: e164format,
      },
    });

    if (!formData.new_sender && !customerPhones.includes(e164format)) {
      clearForm(false, true);
    } else if (customerPhones.includes(e164format)) {
      autoCompleteFormPhone(e164format);
    }
  };

  // Allow clearing of form
  useEffect(() => {
    if (
      formData.new_sender &&
      !allowClear &&
      (formData.sender_first_name !== "" ||
        formData.sender_last_name !== "" ||
        formData.sender_email !== "" ||
        formData.sender_address_latitude !== "" ||
        formData.sender_address_longitude !== "" ||
        formData.sender_address !== "" ||
        formData.sender_phone !== "+" + dialCode)
    ) {
      setFormCleared(false);
      setAllowClear(true);
    } else if (allowClear) {
      setFormCleared(true);
      setAllowClear(false);
    }
  }, [
    formData.sender_email,
    formData.sender_phone,
    formData.sender_address_latitude,
    formData.sender_address_longitude,
    formData.sender_address,
    formData.sender_first_name,
    formData.sender_last_name,
    formData.new_sender,
    editMode,
    dialCode,
  ]);

  // Check if all the fields are filled and valid, not enrolled costumer, manual input
  useEffect(() => {
    if (
      !firstNameError &&
      !lastNameError &&
      !emailError &&
      !phoneError &&
      !addressError &&
      !missingEmailorPhone &&
      !editMode
    ) {
      handleFormCompletion(true);
      setCompleteForm(true);
    } else if (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneError ||
      addressError ||
      missingEmailorPhone ||
      editMode
    ) {
      handleFormCompletion(false);
      setCompleteForm(false);
    }
  }, [
    firstNameError,
    lastNameError,
    emailError,
    phoneError,
    addressError,
    handleFormCompletion,
    missingEmailorPhone,
    editMode,
  ]);

  // Auto populate form with customer info
  const handleEmailSelect = (event, value, reason) => {
    // Populate sender_email when an option is selected from Autocomplete
    if (reason === "selectOption") {
      handleInputChange({
        target: {
          name: "sender_email",
          value: value,
        },
      });
      // Check if the selected email is part of customer emails
      const isEmailValid = customerEmails.includes(value);

      if (isEmailValid) {
        autoCompleteFormEmail(value);
      }
    }
    if (reason === "clear" || reason === "removeOption") {
      if (!formData.new_sender) {
        clearForm(true, true);
      }
    }
  };

  const handleAddressSelect = (address) => {
    setSenderAddress(address);
    handleInputChange({
      target: {
        name: "sender_address",
        value: address,
      },
    });
    getLatLngFromAddress(address).then((location) => {
      setFormData((prevData) => ({
        ...prevData,
        sender_address_latitude: location.lat,
        sender_address_longitude: location.lng,
      }));
    });
    setAddressError(false);
  };

  const handleAddressInputChange = (address, clear = false) => {
    if (!addressError) {
      setAddressError(true);
    }

    setSenderAddress(address);

    handleInputChange({
      target: {
        name: "sender_address",
        value: address,
      },
    });

    if (clear) {
      setSenderAddress("");
      handleInputChange({
        target: {
          name: "sender_address",
          value: "",
        },
      });
    }
  };

  const handleForeignIdChange = (event) => {
    handleInputChange({
      target: {
        name: "sender_foreign_id",
        value: event.target.value,
      },
    });
  };

  const clearAddress = () => {
    handleAddressInputChange("", true);
  };

  const autocompleteFields = (customer) => {
    const updatedFields = { ...formData };
    updatedFields.new_sender = false;
    updatedFields.sender_id = customer.id;
    updatedFields.sender_email = customer.email === null ? "" : customer.email;
    updatedFields.sender_phone =
      customer.phone === null ? "+" + dialCode : customer.phone;
    updatedFields.sender_first_name = customer.first_name;
    updatedFields.sender_last_name = customer.last_name;
    updatedFields.sender_foreign_id = customer.foreign_id;
    updatedFields.sender_address_latitude = customer.address_latitude;
    updatedFields.sender_address_longitude = customer.address_longitude;
    updatedFields.sender_address = customer.address;
    updatedFields.sender_enrollment_email = false;

    console.log("Sender address is: " + customer.address);
    // Manually update the address field
    setSenderAddress(customer.address);

    let autocompletedInitialInfo = {
      first_name: updatedFields.sender_first_name,
      last_name: updatedFields.sender_last_name,
      email: updatedFields.sender_email,
      phone: updatedFields.sender_phone,
      address_latitude: updatedFields.sender_address_latitude,
      address_longitude: updatedFields.sender_address_longitude,
      address: updatedFields.sender_address,
      foreign_id: updatedFields.sender_foreign_id,
    };

    setInitialCustomerInfo(autocompletedInitialInfo);

    // generateSenderAddressFromCoordinates(
    //   updatedFields.sender_address_latitude,
    //   updatedFields.sender_address_longitude
    // );

    if (customer.status !== "UNVERIFIED") {
      updatedFields.is_sender_enrolled = true;
    } else {
      updatedFields.is_sender_enrolled = false;
    }

    // Use this method to update all fields at once.
    setFormData(updatedFields);
    setFirstNameError(false);
    setLastNameError(false);
    setAddressError(false);
    setEmailError(false);
    setPhoneError(false);
  };

  const autoCompleteFormPhone = (phone) => {
    console.log("auto complete form phone");
    console.log("Setting is loading to true");
    setIsLoading(true);
    axiosInstance
      .get(`/customers/get_by_phone_withID`, { params: { phone: phone } })
      .then((response) => {
        if (response.status === 200) {
          const customer = response.data;
          // Update the form with the customer's info
          autocompleteFields(customer);
          console.log("Setting is loading to false");
          setIsLoading(false);
        } else {
          console.log("Setting is loading to false");
          setIsLoading(false);
          enqueueSnackbar("Error occurred while getting customer info", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log("Setting is loading to false");
        setIsLoading(false);
        enqueueSnackbar("Error occurred while getting customer info", {
          variant: "error",
        });
      });
  };

  // Autocomplete Form Email

  const autoCompleteFormEmail = (email) => {
    console.log("auto complete form email");
    console.log("Setting is loading to true");
    setIsLoading(true);
    axiosInstance
      .get(`/customers/get_by_email_withID`, { params: { email: email } })
      .then((response) => {
        if (response.status === 200) {
          const customer = response.data;
          // Update the form with the customer's info
          autocompleteFields(customer);
          console.log("Setting is loading to false");
          setIsLoading(false);
        } else {
          console.log("Setting is loading to false");
          setIsLoading(false);
          enqueueSnackbar("Error occurred while getting customer info", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.log("Setting is loading to false");
        setIsLoading(false);
        enqueueSnackbar("Error occurred while getting customer info:" + error, {
          variant: "error",
        });
      });
  };

  const clearForm = (phone = true, email = true) => {
    console.log("clearing form");
    setFormCleared(true);
    const clearedFields = { ...formData };

    clearedFields.new_sender = true;
    clearedFields.is_sender_enrolled = false;
    clearedFields.sender_first_name = "";
    clearedFields.sender_last_name = "";

    if (email) {
      clearedFields.sender_email = "";
    }

    if (phone) {
      clearedFields.sender_phone = "+" + dialCode;
    }
    clearedFields.sender_id = "";
    clearedFields.sender_foreign_id = "";
    clearedFields.sender_address_latitude = "";
    clearedFields.sender_address_longitude = "";
    clearedFields.sender_address = "";
    clearedFields.sender_enrollment_email = false;

    // Use this method to clear all fields at once.
    console.log("setting form data");
    setFormData(clearedFields);
    setFirstNameError(true);
    setLastNameError(true);
    setAddressError(true);
    setSenderAddress("");

    // Clear logic
    setCompleteForm(false);
    handleFormCompletion(false);
    setInitialCustomerInfo({});
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;

    handleInputChange({
      target: {
        name: "sender_first_name",
        value,
      },
    });

    if (value !== "") {
      setFirstNameError(false);
    } else {
      setFirstNameError(true);
    }
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;

    handleInputChange({
      target: {
        name: "sender_last_name",
        value,
      },
    });

    if (value !== "") {
      setLastNameError(false);
    } else {
      setLastNameError(true);
    }
  };

  const getLatLngFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
      );
      const data = await response.json();
      const location = data.results[0].geometry.location;
      return location;
    } catch (err) {
      console.log(err);
    }
  };

  const formStyle = {
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#F9F9F9",
  };
  const titleStyle = { marginBottom: "30px", fontWeight: 500, color: "#333" };
  const subtextStyle = { marginBottom: "30px", fontWeight: 300, color: "#666" };

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <Paper style={formStyle}>
        <IconButton
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            backgroundColor: "#e0e0e0",
            borderRadius: "50%",
          }}
          edge="end"
          color="default"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" style={titleStyle}>
          Sender Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={subtextStyle}>
              Please, provide the customer's email:
            </Typography>
            <Autocomplete
              options={customerEmails}
              country={country}
              getOptionLabel={(option) => option}
              value={formData.sender_email}
              onInputChange={debouncedHandleEmailInputChange}
              onChange={handleEmailSelect}
              selectOnFocus
              clearOnBlur
              freeSolo
              disabled={editMode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sender Email"
                  variant="outlined"
                  error={emailError || missingEmailorPhone}
                  helperText={
                    (emailError && "Invalid email") ||
                    (missingEmailorPhone &&
                      "Please, provide an email or phone number")
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={subtextStyle}>
              Or, phone number:
            </Typography>
            <PhoneInput
              country={country} // default country
              value={formData.sender_phone}
              onChange={debouncedHandlePhoneInputChange}
              countryCodeEditable={false}
              enableCountryCode={true}
              enableSearch={true}
              isValid={() => !phoneError}
              disabled={editMode}
              placeholder="Enter phone number"
              inputStyle={{
                width: "95%",
                height: "56px",
                fontSize: "1rem",
                marginLeft: "40px",
                padding: "18.5px 14px",
                border: "1px solid #ced4da",
                borderRadius: "4px",
              }}
            />
            <FormHelperText error={phoneError || missingEmailorPhone}>
              {(phoneError && "Invalid phone number") ||
                (missingEmailorPhone &&
                  "Please, provide an email or phone number")}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Sender First Name"
              name="sender_first_name"
              value={formData.sender_first_name}
              onChange={handleFirstNameChange}
              disabled={!formData.new_sender && !editMode}
              error={firstNameError}
              helperText={firstNameError && "Please enter a first name"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Sender Last Name"
              name="sender_last_name"
              value={formData.sender_last_name}
              onChange={handleLastNameChange}
              disabled={!formData.new_sender && !editMode}
              error={lastNameError}
              helperText={lastNameError && "Please enter a last name"}
            />
          </Grid>
          <Grid item xs={12}>
            <PlacesAutocomplete
              value={senderAddress}
              onChange={(address) => handleAddressInputChange(address)}
              onSelect={(address) => handleAddressSelect(address)}
              disabled={!formData.new_sender && !editMode}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <TextField
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input",
                    })}
                    fullWidth
                    variant="outlined"
                    label="Sender Address"
                    name="sender_address"
                    error={addressError}
                    helperText={
                      addressError &&
                      "Please select an address from the dropdown"
                    }
                    disabled={!formData.new_sender && !editMode}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formData.new_sender && senderAddress && (
                            <IconButton onClick={clearAddress}>
                              <CloseIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            padding: "10px",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            padding: "10px",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={subtextStyle}>
              Map:
            </Typography>
            {formData.sender_address_latitude &&
              formData.sender_address_longitude && (
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={18}
                  center={{
                    lat: formData.sender_address_latitude,
                    lng: formData.sender_address_longitude,
                  }}
                  // onClick={async (e) => {
                  //   if (!formData.new_sender && !editMode) return;
                  //   console.log("clicked");
                  //   console.log(e);
                  //   console.log(e.latLng.lat(), e.latLng.lng());
                  //   const clickedPosition = {
                  //     lat: e.latLng.lat(),
                  //     lng: e.latLng.lng(),
                  //   };
                  //   generateSenderAddressFromCoordinates(
                  //     e.latLng.lat(),
                  //     e.latLng.lng()
                  //   );
                  //   setFormData({
                  //     ...formData,
                  //     sender_address_latitude: clickedPosition.lat,
                  //     sender_address_longitude: clickedPosition.lng,
                  //   });
                  // }}
                >
                  {formData.sender_address_latitude &&
                    formData.sender_address_longitude && (
                      <MarkerF
                        position={{
                          lat: formData.sender_address_latitude,
                          lng: formData.sender_address_longitude,
                        }}
                        draggable={editMode || formData.new_sender}
                        onDragEnd={async (e) => {
                          if (!formData.new_sender && !editMode) return;
                          const newPosition = {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          };
                          generateSenderAddressFromCoordinates(
                            e.latLng.lat(),
                            e.latLng.lng()
                          );
                          setFormData({
                            ...formData,
                            sender_address_latitude: newPosition.lat,
                            sender_address_longitude: newPosition.lng,
                          });
                        }}
                      />
                    )}
                </GoogleMap>
              )}
          </Grid>
          {!formData.is_sender_enrolled && completeForm && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.sender_enrollment_email}
                    onChange={(event) =>
                      handleInputChange({
                        target: {
                          name: "sender_enrollment_email",
                          value: event.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Send customer enrollment email?"
              />
            </Grid>
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-start" marginTop={3}>
          {allowClear && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={clearForm}
              style={{ marginRight: "10px" }}
            >
              Clear Form
            </Button>
          )}
          {!formData.new_sender && editMode === false && (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={handleEditInfo}
            >
              Edit Info
            </Button>
          )}
          {editMode && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleConfirmUpdates}
                disabled={
                  !isUpdated ||
                  emailError ||
                  addressError ||
                  firstNameError ||
                  lastNameError
                }
                style={{ marginRight: "10px" }}
              >
                Confirm Updates
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default SenderInfoForm;

import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, PolylineF, MarkerF } from "@react-google-maps/api";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment from "moment";

const LocationTrail = ({ locationAuditTrail }) => {
  const [trailPoints, setTrailPoints] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const mapRef = useRef(null);
  const listRef = useRef([]);

  useEffect(() => {
    // First, sort the locationAuditTrail array by createdAt in chronological order
    const sortedTrail = [...locationAuditTrail].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    // Then, filter out the redundant points
    const filteredTrail = sortedTrail.filter(
      (entry, index, self) =>
        index === 0 ||
        entry.new_latitude !== self[index - 1].new_latitude ||
        entry.new_longitude !== self[index - 1].new_longitude
    );

    setTrailPoints(filteredTrail);

    if (filteredTrail.length > 0) {
      const lastPoint = filteredTrail[filteredTrail.length - 1];
      setMapCenter({
        lat: parseFloat(lastPoint.new_latitude),
        lng: parseFloat(lastPoint.new_longitude),
      });
    }
  }, [locationAuditTrail]);

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = { lat: 0, lng: 0 };
  const zoomLevel = 8;

  const center =
    trailPoints.length > 0
      ? { lat: trailPoints[0].new_latitude, lng: trailPoints[0].new_longitude }
      : defaultCenter;

  const path = trailPoints.map((point) => ({
    lat: parseFloat(point.new_latitude),
    lng: parseFloat(point.new_longitude),
  }));

  // Initial state of mapCenter is set in the useEffect
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  // Function to get emoji based on action
  const getEmojiForAction = (action) => {
    switch (action) {
      case "VERIFY":
        return "🔍"; // Magnifying glass emoji for VERIFY
      case "PICKUP":
        return "📦"; // Package emoji for PICKUP
      case "DELIVER":
        return "🚚"; // Delivery truck emoji for DELIVER
      default:
        return ""; // Default to no emoji
    }
  };

  const handleMarkerClick = (point, index) => {
    setSelectedPoint(index);

    setMapCenter({
      lat: parseFloat(point.new_latitude),
      lng: parseFloat(point.new_longitude),
    });

    listRef.current[index].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const handleListItemClick = (point, index) => {
    setSelectedPoint(index);
    setMapCenter({
      lat: parseFloat(point.new_latitude),
      lng: parseFloat(point.new_longitude),
    });
  };

  return (
    <div>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={mapContainerStyle}
        zoom={zoomLevel}
        center={mapCenter}
        mapTypeId={"satellite"}
      >
        <PolylineF path={path} options={polylineOptions} />

        {trailPoints.map(
          (point, index) =>
            point.action !== "UPDATE" && (
              <MarkerF
                key={index}
                position={{
                  lat: parseFloat(point.new_latitude),
                  lng: parseFloat(point.new_longitude),
                }}
                onClick={() => handleMarkerClick(point, index)}
                label={getEmojiForAction(point.action)}
              />
            )
        )}
      </GoogleMap>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflow: "auto",
          maxHeight: 300,
        }}
      >
        {trailPoints.map((point, index) => (
          <ListItem
            key={index}
            ref={(el) => (listRef.current[index] = el)}
            selected={index === selectedPoint}
            onClick={() => handleListItemClick(point, index)}
            style={{
              backgroundColor: index === selectedPoint ? "#f0f0f0" : "white",
            }}
          >
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText
              primary={`${moment(point.createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              )} - ${point.courier_name}`}
              secondary={point.action}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default LocationTrail;

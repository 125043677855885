import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
  CircularProgress,
  Container,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tab,
} from "@mui/material";
import PenIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../components/AuthProvider";
import { enqueueSnackbar } from "notistack";
import axiosInstance from "../components/axiosInstance";
import { useSnackbar } from "notistack";
import EditManifestDialog from "../components/ManifestEditDialog";

function ManifestDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [manifestData, setManifestData] = useState(null);
  const [package_ids, setPackageIds] = useState([]);
  const { id } = useParams();
  const { accessLevel, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const fetchManifestData = async () => {
    setIsLoading(true);
    try {
      console.log("Fetching manifest details...");
      console.log("Manifest ID:", id);
      const response = await axiosInstance.get(
        `/packages/get_manifest_details/${id}`
      );
      console.log("Manifest data:", response.data);
      setManifestData(response.data);
      setPackageIds(response.data.packages.map((pkg) => pkg.package_id));
      enqueueSnackbar("Manifest details fetched successfully", {
        variant: "success",
      });
    } catch (err) {
      console.error("Error fetching manifest details:", err);
      enqueueSnackbar("Failed to fetch manifest details.", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchManifestData();
  }, []);

  const closeDialog = (isUpdated) => {
    setEditDialogOpen(false);
    if (isUpdated) {
      fetchManifestData();
    }
  };

  const deleteManifest = async () => {
    // Logic to delete manifest
    setIsLoading(true);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Manifest?"
    );
    if (isConfirmed) {
      try {
        const response = await axiosInstance.delete(
          `/packages/delete_manifest/${id}`
        );
        if (response.status === 200) {
          setIsLoading(false);
          enqueueSnackbar("Package deleted successfully", {
            variant: "success",
          });
          navigate(-1);
        } else {
          setIsLoading(false);
          enqueueSnackbar("Failed to delete manifest", {
            variant: "error",
          });
        }
      } catch (err) {
        setIsLoading(false);
        enqueueSnackbar("Failed to delete manifest", {
          variant: "error",
        });
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleEditManifest = () => {
    setEditDialogOpen(true);
  };

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        display="flex"
        width="100%"
        sx={{
          backgroundImage: "linear-gradient(225deg, #0070c9 30%, #00a8e8 90%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            Back
          </Typography>
          <Typography
            variant="h6"
            sx={{ ml: 4.0, flexGrow: 1, textAlign: "left" }}
          >
            <Grid container alignItems="center">
              <Grid item>Manifest Details</Grid>
            </Grid>
          </Typography>

          {accessLevel < 4 && (
            <Button
              variant="contained"
              color="error"
              onClick={deleteManifest}
              sx={{
                ml: 1.5,
                backgroundImage:
                  "linear-gradient(45deg, #ef5350 30%, #e53935 90%)",
                boxShadow: "0 3px 5px 2px rgba(239, 83, 80, .3)",
              }}
            >
              <DeleteIcon />
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Editable Content */}
      <Container maxWidth="md" sx={{ marginTop: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Typography variant="h5" gutterBottom>
              Manifest ID
            </Typography>
            <Typography variant="body1">
              {manifestData?.manifest.manifest_id}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4}>
            <Typography variant="h5" gutterBottom>
              Contents and Details
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item #</TableCell>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Brand</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manifestData?.manifestItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.item.name}</TableCell>
                      <TableCell>{item.item.category}</TableCell>
                      <TableCell>{item.item.description}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{item.item.brand}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4, mb: 8 }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Manifest Packages
            </Typography>
            <Button
              startIcon={<PenIcon />}
              variant="contained"
              color="primary"
              onClick={handleEditManifest}
              sx={{
                mb: 2,
                backgroundImage:
                  "linear-gradient(225deg, #0070c9 30%, #00a8e8 90%)",
              }}
            >
              Edit Manifest
            </Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tracking Number</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Date Added</TableCell>
                    <TableCell align="right">Weight (lb)</TableCell>
                    <TableCell align="right">Sender</TableCell>
                    <TableCell align="right">Recipient</TableCell>
                    <TableCell align="right">Vendor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manifestData?.packages.map((pkg, index) => (
                    <TableRow key={pkg.package_id}>
                      <TableCell>{pkg.tracking_number}</TableCell>
                      <TableCell align="right">{pkg.status}</TableCell>
                      <TableCell align="right">{pkg.created_at}</TableCell>
                      <TableCell align="right">{pkg.weight}</TableCell>
                      <TableCell align="right">
                        {pkg.sender_first_name + " " + pkg.sender_last_name}
                      </TableCell>
                      <TableCell align="right">
                        {pkg.recipient_first_name +
                          " " +
                          pkg.recipient_last_name}
                      </TableCell>
                      <TableCell align="right">
                        {pkg.original_vendor_name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      {editDialogOpen && manifestData && manifestData.packages && (
        <EditManifestDialog
          openDialog={editDialogOpen}
          handleClose={closeDialog}
          original_packages={manifestData.packages}
          shipment_type_id={manifestData?.manifest.shipment_type_id}
          manifest_id={id}
        />
      )}
    </div>
  );
}

export default ManifestDetails;

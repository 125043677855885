import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const drawerWidth = 240;

const UserDrawerComponent = ({ open }) => {
  const navigate = useNavigate(); // Moved useNavigate inside the component body
  const { logoutUser } = useContext(AuthContext);

  const handleMenuItemClick = async (menuItem) => {
    // Internal handling of menu item clicks
    switch (menuItem) {
      case "Account":
        console.log("Account clicked");
        navigate("/user_account");
        // Navigate to the account page or perform the action
        break;
      case "Logout":
        console.log("Logging out...");
        // call the logout function
        try {
          await axiosInstance.post("/auth/logout/user");
          logoutUser();
          navigate("/user_login");
        } catch (error) {
          console.error("Error logging out:", error);
        }

        break;
      default:
        console.log("Menu item clicked:", menuItem);
    }
  };

  const menuItems = [
    {
      text: "Account",
      icon: <AccountCircleIcon />,
    },
    {
      text: "Logout",
      icon: <LogoutIcon />,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : 70,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : 70,
          boxSizing: "border-box",
        },
      }}
    >
      <DrawerHeader />
      <List>
        {menuItems.map(({ text, icon }) => (
          <ListItem button key={text} onClick={() => handleMenuItemClick(text)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default UserDrawerComponent;

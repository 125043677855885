import React, { useState, useContext } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  createTheme,
  ThemeProvider,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { useSnackbar } from "notistack";
import { AuthContext } from "../components/AuthProvider";
import { Link as RouterLink } from "react-router-dom";
import logo from "../logoClean.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#aeaeae",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { loginUser } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/auth/login/user", {
        email,
        password,
      });

      if (response.status === 200) {
        enqueueSnackbar("Login successful", { variant: "success" });
        loginUser({
          user_id: response.data.user_id,
          access_level: null,
        });
        navigate("/user_dashboard");
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        if (
          response.data.message ===
          "Please verify your account. Check your email for a verification link."
        ) {
          // You can provide a link for resending the verification link here.
          enqueueSnackbar(
            "If you can't find the verification email, click here to resend.",
            { variant: "info" }
          );
        }
      }
    } catch (error) {
      // Show the error message from the server, if available. Otherwise, show a generic error message.
      console.error(error);
      const errorMessage =
        error.response?.data?.message ||
        "An unknown error occurred. Please try again.";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Button
          onClick={() => navigate("/")}
          color="primary"
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
          }}
        >
          <img src={logo} alt="CExpress logo" height="30" />{" "}
        </Button>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                mt: 3,
                mb: 2,
                backgroundImage:
                  "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
              }}
            >
              {isLoading ? "Logging In..." : "Log In"}
            </Button>

            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Link component={RouterLink} to="/forgot_password_user">
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Container>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Typography variant="body2" color="text.secondary" align="center">
            Need an account?{" "}
            <Link component={RouterLink} to="/user_signup">
              Sign up!
            </Link>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default UserLogin;

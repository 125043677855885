import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import './PrintStyles.css';

function PrintQrCode() {
    const location = useLocation();

    // Extract packageId from the query parameter
    const params = new URLSearchParams(location.search);
    const packageId = params.get('packageId');

    useEffect(() => {
        window.print();
    }, []);

    return (
        <div className="print-wrapper">
            <div className="print-content">
                <QRCode value={packageId} size={170} />
                <Typography 
                    variant="caption" 
                    sx={{ mt: 1.0, fontSize: 9, display: 'block' }} 
                    gutterBottom
                >
                    {packageId}
                </Typography>
            </div>
        </div>
    );
}

export default PrintQrCode;

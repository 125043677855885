import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import Image from "../background.jpeg";
import logo from "../logo.svg";
import logoClean from "../logoClean.svg";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

// Google Play and App Store logos can be imported if available in the project
import googlePlayBadge from "../google-play-badge.png";
import appStoreBadge from "../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#ffffff",
    },
    button: {
      main: "#0f0f0f",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Background = styled("div")({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  minHeight: "100vh",
  width: "100%",
  position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  zIndex: -1,
});

const AppDownload = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Button
          onClick={() => navigate("/")}
          color="primary"
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
          }}
        >
          <img src={logoClean} alt="CExpress logo Clean" height="30" />{" "}
        </Button>
        <Container
          style={{
            maxHeight: "90vh",
            width: "60%",
            overflowY: "auto",
            position: "relative",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img
              src={logo}
              alt="CExpress Logo"
              style={{ width: "150px", height: "auto", marginBottom: "20px" }}
            />
            <Typography variant="h4" align="center" mt={2} mb={4}>
              Download Our App
            </Typography>
            <Typography variant="body1" align="center" mt={2} mb={4}>
              Experience seamless delivery services with Cexpress Courier.
              Available on both Android and iOS for registered couriers and
              employees.
            </Typography>

            <Box mt={4} mb={4} display="flex" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.noelsarduy.CExpressMobileCourierExpo&pli=1",
                    "_blank"
                  )
                }
              >
                <img
                  src={googlePlayBadge}
                  alt="Download on Google Play"
                  style={{ width: "160px" }}
                />
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://testflight.apple.com/join/y93cvlYB",
                    "_blank"
                  )
                }
              >
                <img
                  src={appStoreBadge}
                  alt="Download on App Store"
                  style={{ width: "140px" }}
                />
              </Button>
            </Box>
          </Box>
        </Container>
      </Background>
    </ThemeProvider>
  );
};

export default AppDownload;

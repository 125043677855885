// AuditTrailList.js
import React from "react";
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const AuditTrailList = ({ auditTrail }) => {
  return (
    <Paper style={{ margin: "20px 0", padding: "15px" }}>
      <Typography variant="h6">Package Audit Trail</Typography>
      <List>
        {auditTrail.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider />}
            <ListItem>
              <ListItemText
                primary={`Action: ${item.action}`}
                secondary={`Performed by: ${item.employee_name} (${
                  item.employee_email
                }) on ${new Date(item.createdAt).toLocaleString()}`}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default AuditTrailList;

import React from 'react';

const PrivatePage = () => {
    return (
        <div>
            <h2>Generic Private Page</h2>
            {/* Your employee registration form goes here */}
        </div>
    );
};

export default PrivatePage;

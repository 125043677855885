import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../components/axiosInstance";
import { useNavigate } from "react-router-dom";
import { BusinessRegistrationContext } from "../BusinessRegistrationContext";
import {
  TextField,
  Button,
  Container,
  Typography,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import logo from "../logoClean.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#aeaeae",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "30px", // Apple-like rounded corners
        },
      },
    },
  },
});

const BusinessRegister = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [license, setLicense] = useState("");
  const [data, setData] = useState(null);
  const [selectedManager, setSelectedManager] = useState("");
  const { setBusinessRegistered } = useContext(BusinessRegistrationContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleEmailUsClick = () => {
    const subject = encodeURIComponent("Registration Request");
    const body = encodeURIComponent(
      "Dear Support Team,\n\n" +
        "I would like to request registration assistance as I do not have a Seller of Travel ID.\n\n" +
        "Below are the details of my business:\n" +
        "- FEI/EIN Number: [Your FEI/EIN Number]\n" +
        "- Business Phone Number: [Your Business Phone Number]\n" +
        "- Business Email: [Your Business Email]\n" +
        "- Your First Name: [Your First Name]\n" +
        "- Your Last Name: [Your Last Name]\n\n" +
        "Attached is a photo of my valid government-issued ID.\n\n" +
        "Thank you for your assistance.\n"
    );
    window.location.href = `mailto:support@cexpress.app?subject=${subject}&body=${body}`;
  };

  useEffect(() => {
    if (data) {
      const managerNames = Object.keys(data)
        .filter((key) => key.startsWith("manager"))
        .map((managerKey) => `${data[managerKey][0]} ${data[managerKey][1]}`);
      if (managerNames.length > 0) {
        setSelectedManager(managerNames[0]);
      }
    }
  }, [data]);

  const submitForm = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    console.log("Submitting form...");
    console.log(license);
    axiosInstance
      .post("/auth/register/businessRegister", { license })
      .then((response) => {
        console.log("Received Response:");
        console.log(response.data);
        setData(response.data.vendorInfo);
        setIsLoading(false);
        enqueueSnackbar("Data successfully fetched", { variant: "success" });
      })
      .catch((error) => {
        // handle error
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          enqueueSnackbar(error.response.data.error, { variant: "error" });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          enqueueSnackbar("Error occurred while fetching data", {
            variant: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          enqueueSnackbar("An unexpected error occurred", { variant: "error" });
        }
      });
  };

  const handleManagerSelection = (event) => {
    setSelectedManager(event.target.value);
  };

  const handleConfirmation = () => {
    setOpenDialog(true);
  };

  const handleConfirmationClose = async (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      try {
        setBusinessRegistered(true);
        // turn selected manager back into an array, only split on the first space, since some last names have spaces and we don't want to split those
        const splitName = selectedManager.split(" ");
        const selectedManagerName = [
          splitName[0],
          splitName.slice(1).join(" "),
        ];
        navigate("/register_business/manager", {
          state: { manager: selectedManagerName },
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error occurred during confirmation", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
          maxHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
      <Button
        onClick={() => navigate("/")}
        color="primary"
        variant="contained"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
        }}
      >
        <img src={logo} alt="CExpress logo" height="30" />{" "}
        {/* Adjust the height as necessary */}
      </Button>
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5" sx={{ mt: "30px" }}>
          Business Registration
        </Typography>
        <Box component="form" onSubmit={submitForm} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="license"
            label="Enter Seller of Travel ID"
            value={license}
            onChange={(e) => setLicense(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={license === ""}
            sx={{
              mt: 3,
              mb: 2,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
              boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
            }}
          >
            Submit
          </Button>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              mt: 3,
              mb: 6,
            }}
          >
            <Typography variant="h6" align="center" mt={2} mb={2}>
              Registering Without a Seller of Travel ID
            </Typography>
            <Typography variant="body1" align="justify" mt={2} mb={4}>
              In case you do not possess a Seller of Travel ID, please don't
              worry. We can guide you through an alternate registration process.
              To facilitate this, you'll need to send us an email with the
              following details:
              <Box component="ul" sx={{ pl: 3, mt: 2, mb: 3 }}>
                <li>Your FEI/EIN Number</li>
                <li>Your Business Phone Number</li>
                <li>Your Business Email</li>
                <li>Your First Name</li>
                <li>Your Last Name</li>
                <li>A photo of your valid government-issued ID</li>
              </Box>
              Upon receiving your email, our team will review the provided
              information and get back to you within 2-3 business days.
            </Typography>

            <Button
              onClick={handleEmailUsClick}
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                backgroundImage:
                  "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
              }}
            >
              Email Us
            </Button>
          </Box>
        </Box>
        {data && (
          <>
            <Dialog
              open={openDialog}
              onClose={() => handleConfirmationClose(false)}
            >
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you indeed {selectedManager}? Please confirm the following
                  information:
                </DialogContentText>
                <List>
                  <ListItem>Business Name: {data.businessName}</ListItem>
                  <ListItem>Address: {data.address}</ListItem>
                  <ListItem>Phone Number: {data.phoneNumber}</ListItem>
                  <ListItem>Date Issued: {data.dateIssued}</ListItem>
                  <ListItem>
                    Date of Expiration: {data.dateOfExpiration}
                  </ListItem>
                  <ListItem>Status: {data.status}</ListItem>
                  <ListItem>
                    Authorized Managers: {data.authorizedManagers}
                  </ListItem>
                </List>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleConfirmationClose(false)}
                  color="primary"
                >
                  No
                </Button>
                <Button
                  onClick={() => handleConfirmationClose(true)}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Retrieved Information
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Business Name"
                  secondary={data.businessName}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Address" secondary={data.address} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Phone Number"
                  secondary={data.phoneNumber}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="License #"
                  secondary={data.licenseNumber}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Date Issued"
                  secondary={data.dateIssued}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Date of Expiration"
                  secondary={data.dateOfExpiration}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Status" secondary={data.status} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Authorized Managers"
                  secondary={data.authorizedManagers}
                />
              </ListItem>
            </List>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="manager-select-label">Manager</InputLabel>
              <Select
                labelId="manager-select-label"
                id="manager-select"
                value={selectedManager}
                label="Manager"
                onChange={handleManagerSelection}
              >
                {Object.keys(data)
                  .filter((key) => key.startsWith("manager"))
                  .map((managerKey) => (
                    <MenuItem
                      key={managerKey}
                      value={`${data[managerKey][0]} ${data[managerKey][1]}`}
                    >
                      {`${data[managerKey][0]} ${data[managerKey][1]}`}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>Select who you are</FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedManager}
              onClick={handleConfirmation}
              sx={{
                mt: 3,
                mb: 2,
                backgroundImage:
                  "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
              }}
            >
              Next
            </Button>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default BusinessRegister;

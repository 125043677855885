import React from "react";
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ReviewForm = ({ formData, setStep, handleClose }) => {
  const formatBooleanField = (value) => (value ? "Yes" : "No");

  const formSections = [
    {
      title: "Sender Information",
      fields: [
        "sender_first_name",
        "sender_last_name",
        "sender_email",
        "sender_phone",
        "sender_address",
      ],
      step: 0,
    },
    {
      title: "Recipient Information",
      fields: [
        "recipient_first_name",
        "recipient_last_name",
        "recipient_email",
        "recipient_foreign_id",
        "recipient_phone",
        "recipient_address",
      ],
      step: 1,
    },
    {
      title: "Package Details",
      fields: [
        { name: "shipment_type_name", path: "shipment_type_name" },
        {
          name: "shipment_type_price_per_pound",
          path: "shipment_type_price_per_pound",
        },
        { name: "weight", path: "package.weight" },
        { name: "length", path: "package.length" },
        { name: "width", path: "package.width" },
        { name: "height", path: "package.height" },
        { name: "package_type", path: "package.package_type" },
        { name: "destination_type", path: "package.destination_type" },
        { name: "delivery_preference", path: "package.delivery_preference" },
        { name: "is_fragile", path: "package.is_fragile" },
        { name: "is_hazardous", path: "package.is_hazardous" },
        { name: "is_perishable", path: "package.is_perishable" },
        { name: "is_insured", path: "package.is_insured" },
        { name: "is_oversize", path: "package.is_oversize" },
        {
          name: "is_signature_required",
          path: "package.is_signature_required",
        },
        { name: "additional_charges", path: "package.additional_charges" },
        { name: "items", path: "package.items" },
        { name: "price", path: "package.price" },
        {
          name: "additional_delivery_instructions",
          path: "package.additional_delivery_instructions",
        },
      ],
      step: 2,
    },
  ];

  const formatItemsField = (items) => {
    if (Array.isArray(items)) {
      return (
        <div>
          {items.map((item, index) => (
            <List key={`item-${index}`}>
              <ListItem
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "lightgray",
                }}
              >
                Name: {item.name}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: index % 2 === 1 ? "white" : "lightgray",
                }}
              >
                Description: {item.description}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "lightgray",
                }}
              >
                Quantity: {item.quantity}
              </ListItem>
            </List>
          ))}
        </div>
      );
    }
    return "No Items";
  };

  // New formatting function for additional_charges
  const formatAdditionalCharges = (additionalCharges) => {
    if (Array.isArray(additionalCharges)) {
      return additionalCharges.map((charge, index) => (
        <ListItem
          key={`charge-${index}`}
          style={{ backgroundColor: index % 2 === 0 ? "white" : "lightgray" }}
        >
          Type: {charge.type === "Other" ? charge.customName : charge.type},
          Amount: {charge.amount}
        </ListItem>
      ));
    }
    return "No Additional Charges";
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const formatFieldName = (name) => name.split("_").map(capitalize).join(" ");

  const getValueFromPath = (object, path) => {
    if (object && path) {
      return path.split(".").reduce((o, p) => (o || {})[p], object);
    }
    return "";
  };

  const formStyle = {
    padding: "30px",
    borderRadius: "15px",
    backgroundColor: "#F9F9F9",
  };
  const titleStyle = { marginBottom: "30px", fontWeight: 500, color: "#333" };

  return (
    <Paper style={formStyle}>
      <IconButton
        style={{
          position: "absolute",
          right: 20,
          top: 20,
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
        }}
        edge="end"
        color="default"
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={3} style={{ padding: "30px" }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Review your information
          </Typography>
        </Grid>
        {formSections.map((section, index) => (
          <Grid item xs={12} key={`section-${index}`}>
            <Typography variant="h5" style={titleStyle}>
              {section.title}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setStep(section.step)}
              style={{ marginBottom: "30px" }}
            >
              Edit
            </Button>
            <List>
              {section.fields.map((field, index) => {
                const fieldData =
                  typeof field === "string"
                    ? { name: field, path: field }
                    : field;
                const value = getValueFromPath(formData, fieldData.path);
                return (
                  <ListItem
                    key={`field-${index}`}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "white" : "lightgray",
                    }}
                  >
                    <div variant="body1">
                      {formatFieldName(fieldData.name)}:{" "}
                      {fieldData.name === "items"
                        ? formatItemsField(value)
                        : fieldData.name === "additional_charges"
                        ? formatAdditionalCharges(value) // New condition for additional_charges
                        : typeof value === "boolean"
                        ? formatBooleanField(value)
                        : value}
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default ReviewForm;

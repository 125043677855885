import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  Box,
  Container,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import axiosInstance from "../components/axiosInstance";
import logo from "../logoClean.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#aeaeae",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Verify = () => {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const verifyEmail = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get("token");

      try {
        const response = await axiosInstance.get(
          `/verify/email?token=${token}`
        );
        setMessage(response.data.message);
        setOpen(true);
        setSeverity("success");
        enqueueSnackbar(response.data.message, { variant: "success" });
      } catch (err) {
        setMessage(err.response.data.error);
        setOpen(true);
        if (err.response.data.error === "Account already verified") {
          setSeverity("info");
        } else if (
          err.response.data.error ===
          "Token expired more than a week ago, please sign up again."
        ) {
          setSeverity("warning");
        } else {
          setSeverity("error");
          enqueueSnackbar(err.response.data.error, { variant: "error" });
        }
      }
    };
    verifyEmail();
  }, [location.search, enqueueSnackbar]);

  const handleNavigate = () => {
    navigate("/");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3 }} textAlign="center">
        <Button
          onClick={() => navigate("/")}
          color="primary"
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
          }}
        >
          <img src={logo} alt="CExpress logo" height="30" />{" "}
          {/* Adjust the height as necessary */}
        </Button>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            display: "flex", // Add this line to set display to flex
            flexDirection: "column", // Set flex direction to column
            justifyContent: "center", // Center items vertically in the container
            height: "100vh", // Set the height to fill the viewport height
            mt: 0, // You can remove the top margin now as it's centered
          }}
        >
          <Typography component="h1" variant="h5">
            Email Verification
          </Typography>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          {(severity === "success" ||
            (severity === "info" &&
              message === "Account already verified")) && (
            <>
              <Typography variant="h6" component="p" gutterBottom>
                {severity === "success"
                  ? "Your account has been successfully verified 😊"
                  : "Your account has already been verified. You can now log in."}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundImage:
                    "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                  boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
                }}
                onClick={handleNavigate}
              >
                Go to Login
              </Button>
            </>
          )}
          {severity === "warning" &&
            message ===
              "Token expired more than a week ago, please sign up again." && (
              <Typography variant="h6" component="p" gutterBottom>
                Sorry, your verification token has expired more than a week ago,
                please sign up again.
              </Typography>
            )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Verify;

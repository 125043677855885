import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import axiosInstance from "./axiosInstance"; // Assuming this is correctly set up for making API requests
import "./ReceiptStyles.css"; // Reusing your CSS for styling consistency

function ManifestDetailsPopup({ manifestId }) {
  const [manifestData, setManifestData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchManifestData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/packages/get_manifest_details/${manifestId}`
        );
        console.log("Manifest data:", response.data);
        setManifestData(response.data);
      } catch (err) {
        console.error("Error fetching manifest details:", err);
        setError("Failed to fetch manifest details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchManifestData();
  }, [manifestId]);

  if (isLoading) {
    return (
      <div className="receipt-content">
        <Typography variant="h4" gutterBottom>
          Manifest Details
        </Typography>
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className="receipt-content">
        <Typography variant="h4" gutterBottom>
          Error
        </Typography>
        <Typography variant="h6" gutterBottom>
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div className="receipt-content">
      <Typography variant="h4" gutterBottom>
        Manifest Details
      </Typography>

      {manifestData ? (
        <>
          <TableContainer component={Paper} className="info-section">
            <Table aria-label="manifest details">
              <TableHead>
                <TableRow>
                  <TableCell>Manifest ID</TableCell>
                  <TableCell align="right">Shipment Type</TableCell>
                  <TableCell align="right">Total Weight (lb)</TableCell>
                  <TableCell align="right">Number of Packages</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {manifestData.manifest.manifest_id}
                  </TableCell>
                  <TableCell align="right">
                    {manifestData.manifest.shipment_type_name}
                  </TableCell>
                  <TableCell align="right">{manifestData.weight}</TableCell>
                  <TableCell align="right">
                    {manifestData.totalPackages}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" gutterBottom marginTop={4}>
            Items
          </Typography>
          <TableContainer component={Paper} className="info-section">
            <Table aria-label="manifest items">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Brand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manifestData.manifestItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.item.name}
                    </TableCell>
                    <TableCell>{item.item.description}</TableCell>
                    <TableCell align="right">{item.item.category}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="right">{item.item.brand}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography variant="h6" gutterBottom>
          Manifest not found.
        </Typography>
      )}
    </div>
  );
}

export default ManifestDetailsPopup;

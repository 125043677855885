import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Paper,
  Container,
  createTheme,
  CssBaseline,
  List,
  ListItem,
  ListItemText,
  Divider,
  ThemeProvider,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import axiosInstance from "./axiosInstance";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Image from "../background.jpeg";
import logo from "../logoClean.svg";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily:
      '"SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
  },
});

const Background = styled("div")({
  backgroundImage: `url(${Image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backdropFilter: "blur(10px)",
  overflow: "auto",
  maxHeight: "200vh",
});

const StyledPaper = styled(Paper)({
  padding: "20px",
  borderRadius: "20px",
  backgroundColor: "rgba(255,255,255,0.8)",
  marginTop: "20px",
});

const ShipmentOptionsPage = () => {
  const [vendors, setVendors] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchShipmentOptions() {
      try {
        const response = await axiosInstance.get(
          "/packages/get_vendors_shipment_options"
        );

        if (sortBy === "name") {
          response.data.vendors.sort((a, b) =>
            a.vendor_name.localeCompare(b.vendor_name)
          );
        } else if (sortBy === "lowToHigh") {
          response.data.vendors.sort((a, b) => {
            const aMinPrice = Math.min(
              ...a.shipmentOptions.map((opt) => parseFloat(opt.price_per_pound))
            );
            const bMinPrice = Math.min(
              ...b.shipmentOptions.map((opt) => parseFloat(opt.price_per_pound))
            );
            return aMinPrice - bMinPrice;
          });
        } else if (sortBy === "highToLow") {
          response.data.vendors.sort((a, b) => {
            const aMaxPrice = Math.max(
              ...a.shipmentOptions.map((opt) => parseFloat(opt.price_per_pound))
            );
            const bMaxPrice = Math.max(
              ...b.shipmentOptions.map((opt) => parseFloat(opt.price_per_pound))
            );
            return bMaxPrice - aMaxPrice;
          });
        }

        // For each vendor format the phone number to american format
        response.data.vendors.forEach((vendor) => {
          try {
            const phoneNumber = parsePhoneNumberFromString(vendor.phone);
            vendor.phone = phoneNumber.formatNational();
          } catch (e) {
            console.warn(
              `Failed to format phone number for vendor ${vendor.vendor_id}`
            );
          }
        });

        console.log(response.data.vendors);

        setVendors(response.data.vendors);
      } catch (error) {
        const errorMessage =
          error.response?.data?.error || "Error fetching shipment options";
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
    fetchShipmentOptions();
  }, [enqueueSnackbar, sortBy]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Background>
        <Container maxWidth="md">
          <Button
            onClick={() => navigate("/")}
            color="primary"
            variant="contained"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundImage:
                "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
            }}
          >
            <img src={logo} alt="CExpress logo" height="30" />
          </Button>
          <Typography variant="h4" gutterBottom>
            Available Shipment Options
          </Typography>
          <Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Sort By</em>
            </MenuItem>
            <MenuItem value="name">Vendor Name</MenuItem>
            <MenuItem value="lowToHigh">Price: Low to High</MenuItem>
            <MenuItem value="highToLow">Price: High to Low</MenuItem>
          </Select>
          {vendors &&
            vendors.length > 0 &&
            vendors.map((vendor) => (
              <StyledPaper key={vendor.vendor_id}>
                <Typography variant="h5">{vendor.vendor_name}</Typography>
                <Typography variant="subtitle1">
                  Address: {vendor.address}
                </Typography>
                <Typography variant="subtitle1">
                  Email: {vendor.email}
                </Typography>
                <Typography variant="subtitle1">
                  Phone: {vendor.phone}
                </Typography>
                <Divider />
                <List>
                  {vendor.shipmentOptions.map((shipmentType) => (
                    <ListItem key={shipmentType.shipment_type_id}>
                      <ListItemText
                        primary={shipmentType.shipment_type_name}
                        secondary={`Price per pound: ${
                          shipmentType.price_per_pound
                        } | Package types: ${shipmentType.package_type.join(
                          ", "
                        )}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </StyledPaper>
            ))}
        </Container>
      </Background>
    </ThemeProvider>
  );
};

export default ShipmentOptionsPage;

import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { DrawerContext } from "../DrawerContext";
import { useFormData } from "../FormDataContext";
import { enqueueSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "../components/AuthProvider";
import { ReactComponent as CourierDrawerIcon } from "../courierDrawerIcon.svg";
import { ReactComponent as PackagesDrawerIcon } from "../packagesDrawerIcon.svg";
import { ReactComponent as EmployeesDrawerIcon } from "../employeesDrawerIcon.svg";
import { ReactComponent as DashboardDrawerIcon } from "../dashboardDrawerIcon.svg";
import { ReactComponent as SettingsDrawerIcon } from "../settingsDrawerIcon.svg";
import { ReactComponent as CustomerDrawerIcon } from "../customerDrawerIcon.svg";
import { ReactComponent as OtherPackagesDrawerIcon } from "../otherPackagesDrawerIcon.svg";
import PeopleIcon from "@mui/icons-material/People";
import EditCustomerDialog from "../components/EditCustomerDialog";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Drawer,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  Divider,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TablePagination,
  Tab,
} from "@mui/material";

const CustomerPage = ({ history }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { open, setOpen } = useContext(DrawerContext);
  const [sortConfig, setSortConfig] = useState({
    key: localStorage.getItem("sortKey") || "created_at",
    direction: localStorage.getItem("sortDirection") || "asc",
  });
  const [customers, setCustomers] = useState([]);
  let [sortedCustomers, setSortedCustomers] = useState([]);
  const [displayCustomers, setDisplayCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  const [filter, setFilter] = useState(localStorage.getItem("filter") || "");
  const [searchTerm, setSearchTerm] = useState(
    localStorage.getItem("searchTerm") || ""
  );
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Function to open the edit dialog with the customer's data
  const openEditDialog = (customer) => {
    setSelectedCustomer(customer);
    setEditDialogOpen(true);
  };

  // Function to close the edit dialog
  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setSelectedCustomer(null);
  };

  const handleSave = async (
    customer_id,
    customer_first_name,
    customer_last_name,
    customer_email,
    customer_phone,
    customer_foreign_id,
    customer_address,
    customer_address_latitude,
    customer_address_longitude
  ) => {
    try {
      const response = await axiosInstance.put(
        "/customers/update_customer_from_vendor",
        {
          customer_id,
          customer_first_name,
          customer_last_name,
          customer_address_latitude,
          customer_address_longitude,
          customer_address,
          customer_email,
          customer_phone,
          customer_foreign_id,
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        enqueueSnackbar("Successfully updated customer", {
          variant: "success",
        });
        closeEditDialog();
        fetchCustomers();
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while updating customer", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while updating customer", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    // Initialize page and rowsPerPage from localStorage
    const savedPage = parseInt(localStorage.getItem("currentPage"), 10) || 0;
    const savedRowsPerPage =
      parseInt(localStorage.getItem("currentRowsPerPage"), 10) || 20;
    setPage(savedPage);
    setRowsPerPage(savedRowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("currentPage", newPage.toString());
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when rows per page changes
    localStorage.setItem("currentRowsPerPage", newRowsPerPage.toString());
    localStorage.setItem("currentPage", "0");
  };

  const fetchCustomers = async () => {
    try {
      console.log("Fetching customers");
      const response = await axiosInstance.get(
        "/customers/get_all_customer_profiles"
      );
      if (response.status === 200) {
        console.log(response.data);
        setCustomers(response.data);
        enqueueSnackbar("Successfully fetched customers", {
          variant: "success",
        });
      } else {
        console.error(response.data.message);
        enqueueSnackbar("Error occurred while getting customers", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while getting customers", {
        variant: "error",
      });
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    Promise.all([fetchCustomers()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    localStorage.setItem("sortKey", key);
    localStorage.setItem("sortDirection", direction);
  };

  sortedCustomers = useMemo(() => {
    return [...customers].sort((a, b) => {
      const aValue = a[sortConfig.key] ? a[sortConfig.key].toLowerCase() : "";
      const bValue = b[sortConfig.key] ? b[sortConfig.key].toLowerCase() : "";

      if (aValue === "" && bValue !== "") return 1;
      if (aValue !== "" && bValue === "") return -1;

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [customers, sortConfig]);

  useEffect(() => {
    let updatedCustomers = [...sortedCustomers];

    if (filter) {
      updatedCustomers = updatedCustomers.filter(
        (customer) => customer.status === filter
      );
    }

    if (searchTerm) {
      console.log("Searching for", searchTerm);
      const lowerSearchTerm = searchTerm.toLowerCase();
      updatedCustomers = updatedCustomers.filter((customer) => {
        // Check for null values and include in search if present
        return (
          (customer.first_name &&
            customer.first_name.toLowerCase().includes(lowerSearchTerm)) ||
          (customer.last_name &&
            customer.last_name.toLowerCase().includes(lowerSearchTerm)) ||
          (customer.email &&
            customer.email.toLowerCase().includes(lowerSearchTerm)) ||
          (customer.phone &&
            customer.phone.toLowerCase().includes(lowerSearchTerm)) ||
          (customer.address &&
            customer.address.toLowerCase().includes(lowerSearchTerm)) ||
          (customer.foreign_id &&
            customer.foreign_id.toLowerCase().includes(lowerSearchTerm))
        );
      });
    }

    // Reset page number and update localStorage when filter or searchTerm changes
    setPage(0);
    localStorage.setItem("currentPage", "0");

    if (filter) {
      // Update localStorage when filter changes
      localStorage.setItem("filter", filter);
    } else {
      localStorage.setItem("filter", "");
    }

    if (searchTerm) {
      // Update localStorage when searchTerm changes
      localStorage.setItem("searchTerm", searchTerm);
    } else {
      localStorage.setItem("searchTerm", "");
    }

    setDisplayCustomers(updatedCustomers);
  }, [sortedCustomers, filter, searchTerm]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <DashboardDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/dashboard",
    },
    {
      text: "Employees",
      icon: (
        <EmployeesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/employees",
    },
    {
      text: "Packages",
      icon: (
        <PackagesDrawerIcon
          style={{ fill: "red", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/packages",
    },
    {
      text: "Couriers",
      icon: (
        <CourierDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/couriers",
    },
    {
      text: "Customers",
      icon: (
        <CustomerDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/customers",
    },
    {
      text: "Other Packages",
      icon: (
        <OtherPackagesDrawerIcon
          style={{ fill: "white", height: "35", width: "35", marginLeft: "-5" }}
        />
      ),
      path: "/other_packages",
    },
  ];

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/auth/logout");
      logoutUser();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const displayedCustomers = displayCustomers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: open ? `calc(100% - 240px)` : `calc(100% - 50px)`,
          ml: open ? "240px" : "0",
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color={theme.palette.primary.main}
              variant="h6"
              noWrap
              component="div"
            >
              Customers
            </Typography>
            <Button onClick={fetchCustomers}>
              <RefreshIcon />
            </Button>
          </Box>
          <TextField
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            placeholder="Search"
            sx={{ width: "300px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "240px" : theme.spacing(7),
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? "240px" : theme.spacing(7),
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            overflowX: "hidden",
            bgcolor: "rgb(20, 20, 20)",
            color: theme.palette.common.white,
          },
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggle}
          sx={{
            mt: 2,
            ml: 0.5,
            display: "block",
            color: theme.palette.common.white,
            borderRadius: "25%",
          }}
        >
          <MenuIcon />
        </IconButton>
        <List>
          {menuItems.map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleMenuClick(path)}
              selected={location.pathname === path}
            >
              <ListItemIcon
                sx={{
                  mt: 1,
                  mb: 0.5,
                  display: "ruby",
                  borderRadius: "25%",
                  color: theme.palette.common.white,
                }}
              >
                {icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  primary={text}
                  sx={{
                    mt: 1,
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <List>
            <ListItem button onClick={() => handleMenuClick("/settings")}>
              <ListItemIcon sx={{ color: "white" }}>
                {" "}
                <SettingsDrawerIcon
                  style={{
                    fill: "white",
                    height: "35",
                    width: "35",
                    marginLeft: "-5",
                  }}
                />{" "}
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "white" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: { sm: 8 },
          width: open
            ? `calc(100% - 240px)`
            : `calc(100% - ${theme.spacing(7) + 1}px)`,
          ml: open ? "0px" : `${theme.spacing(7) + 1}px`,
          // Add transition time here
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "first_name"}
                    direction={
                      sortConfig.key === "first_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("first_name")}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "last_name"}
                    direction={
                      sortConfig.key === "last_name"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("last_name")}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "email"}
                    direction={
                      sortConfig.key === "email" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("email")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "phone"}
                    direction={
                      sortConfig.key === "phone" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("phone")}
                  >
                    Phone
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "address"}
                    direction={
                      sortConfig.key === "address"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("address")}
                  >
                    Address
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "foreign_id"}
                    direction={
                      sortConfig.key === "foreign_id"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("foreign_id")}
                  >
                    Foreign ID
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedCustomers.map((customer) => (
                <TableRow
                  key={customer.customer_id}
                  hover
                  onClick={() => openEditDialog(customer)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "inherit",
                  }}
                >
                  <TableCell>{customer.first_name}</TableCell>
                  <TableCell>{customer.last_name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{customer.address}</TableCell>
                  <TableCell>{customer.foreign_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 80, 100]}
            component="div"
            count={displayCustomers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        {selectedCustomer && (
          <EditCustomerDialog
            isOpen={editDialogOpen}
            handleClose={closeEditDialog}
            handleSave={handleSave}
            customer_id={selectedCustomer.customer_id}
            first_name={selectedCustomer.first_name}
            last_name={selectedCustomer.last_name}
            address_latitude={selectedCustomer.address_latitude}
            address_longitude={selectedCustomer.address_longitude}
            address={selectedCustomer.address}
            email={selectedCustomer.email}
            phone={selectedCustomer.phone}
            foreign_id={selectedCustomer.foreign_id}
            // Include other props and callback functions as necessary
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomerPage;

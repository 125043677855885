import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, createTheme, ThemeProvider, Link, FormHelperText } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../components/axiosInstance';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../components/AuthProvider';
import {Link as RouterLink} from 'react-router-dom';
import * as yup from 'yup';
import logo from '../logoClean.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0070c9',
    },
    secondary: {
      main: '#aeaeae',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const passwordSchema = yup.string().min(8).matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    "Password must be at least 8 characters, have one uppercase, one lowercase, one number, and one special character"
);

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = (event) => {
    const { value } = event.target;

    setPassword(value);

    const isValidPassword = value ? passwordSchema.isValidSync(value) : true;
    setPasswordError(!isValidPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  useEffect(() => {
    setConfirmPasswordError(confirmPassword !== password);
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    try {
      const response = await axiosInstance.post('/auth/reset_password', {
        token,
        password
      });

      if (response.status === 200) {
        enqueueSnackbar('Password reset successful', { variant: 'success' });
        navigate('/login');
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
  
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.message || 'An unknown error occurred. Please try again.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Button
          onClick={() => navigate('/')}
          color="primary"
          variant="contained"
          sx={{ position: 'absolute', top: 10, left: 10, backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'}}
        >
          <img src={logo} alt="CExpress logo" height="30" /> {/* Adjust the height as necessary */}
        </Button>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label="New Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError || password === ''}
              helperText={password === '' ? 'Password field cannot be empty' : (passwordError ? 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character' : '')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={confirmPasswordError || confirmPassword === ''}
              helperText={confirmPassword === '' ? 'Confirm Password field cannot be empty' : (confirmPasswordError ? 'Passwords do not match' : '')}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading || password === '' || confirmPassword === '' || passwordError || confirmPasswordError}
              sx={{
                mt: 3,
                mb: 2,
                backgroundImage: 'linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)'
              }}
            >
              Reset Password
            </Button>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body2" color="text.secondary" align="center">
                Remember your password? <Link component={RouterLink} to="/login">Log In!</Link>
                </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;
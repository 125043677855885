import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  createTheme,
  ThemeProvider,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../components/axiosInstance";
import { useSnackbar } from "notistack";
import { AuthContext } from "../components/AuthProvider";
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { isValidNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { FormHelperText } from "@mui/material";
import logo from "../logoClean.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0070c9",
    },
    secondary: {
      main: "#aeaeae",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const passwordSchema = yup
  .string()
  .min(8)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
    "Password must be at least 8 characters, have one uppercase, one lowercase, one number, and one special character"
  );

const UserSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/auth/signup/user", {
        email: email,
        password: password,
        first_name: firstName,
        last_name: lastName,
        phone: phone,
      });
      if (response.status === 200) {
        enqueueSnackbar(
          "Registration successful. Check your email for verification.",
          { variant: "success" }
        );
        navigate("/");
      } else {
        enqueueSnackbar(response.data.error, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.error ||
        "An unknown error occurred. Please try again.";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;

    setEmail(value);

    const isValidEmail = value ? yup.string().email().isValidSync(value) : true;
    setEmailError(!isValidEmail);
  };

  const handleFirstNameChange = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/[^a-zA-Z\u00C0-\u024F\s]/g, "");
    setFirstName(updatedValue);
  };

  const handleLastNameChange = (event) => {
    const { value } = event.target;
    const updatedValue = value.replace(/[^a-zA-Z\u00C0-\u024F\s]/g, "");
    setLastName(updatedValue);
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const isValid = isValidNumber(value, country.countryCode.toUpperCase());

    setPhoneError(!isValid);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;

    setPassword(value);

    const isValidPassword = value ? passwordSchema.isValidSync(value) : true;
    setPasswordError(!isValidPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const { value } = event.target;
    setConfirmPassword(value);
    setConfirmPasswordError(value !== password);
  };

  useEffect(() => {
    setConfirmPasswordError(confirmPassword !== password);
  }, [password, confirmPassword]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Button
          onClick={() => navigate("/users_home_options")}
          color="primary"
          variant="contained"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundImage: "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
          }}
        >
          <img src={logo} alt="CExpress logo" height="30" />{" "}
          {/* Adjust the height as necessary */}
        </Button>
        <Container component="main" maxWidth="xs">
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="firstName"
              label="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              error={firstName === ""}
              helperText={firstName === "" && "Please enter your first name"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="lastName"
              label="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
              error={lastName === ""}
              helperText={lastName === "" && "Please enter your last name"}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              onChange={handleEmailChange}
              error={emailError || email === ""}
              helperText={
                email === ""
                  ? "Email field cannot be empty"
                  : emailError
                  ? "Please enter a valid email address"
                  : ""
              }
            />
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={handlePhoneChange}
              countryCodeEditable={false}
              enableCountryCode={true}
              enableSearch={true}
              isValid={() => !phoneError}
              placeholder="Enter phone number"
              inputStyle={{
                width: "90%",
                height: "56px",
                fontSize: "1rem",
                marginLeft: "40px",
                padding: "18.5px 14px",
                border: "1px solid #ced4da",
                borderRadius: "4px",
              }}
            />
            <FormHelperText error={phoneError || phone === ""}>
              {(phoneError && "Invalid phone number") ||
                (phone === "" && "Please, provide a valid phone number")}
            </FormHelperText>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError || password === ""}
              helperText={
                password === ""
                  ? "Password field cannot be empty"
                  : passwordError
                  ? "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character"
                  : ""
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={confirmPasswordError || confirmPassword === ""}
              helperText={
                confirmPassword === ""
                  ? "Confirm Password field cannot be empty"
                  : confirmPasswordError
                  ? "Passwords do not match"
                  : ""
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                isLoading ||
                email === "" ||
                password === "" ||
                confirmPassword === "" ||
                phone === "" ||
                firstName === "" ||
                lastName === "" ||
                emailError ||
                passwordError ||
                confirmPasswordError ||
                phoneError
              }
              sx={{
                mt: 3,
                mb: 2,
                backgroundImage:
                  "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
              }}
            >
              {isLoading ? "Signing Up..." : "Sign Up"}
            </Button>
          </Box>
        </Container>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Typography variant="body2" color="text.secondary" align="center">
            Already have an account?{" "}
            <Link component={RouterLink} to="/user_login">
              Log In!
            </Link>
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default UserSignUp;

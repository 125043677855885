import React, { useContext, useEffect, useState, useRef } from "react";
import axiosInstance from "../components/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { MarkerF } from "@react-google-maps/api";
import markerIcon from "../package_container.svg";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import ReceiptPopupVendor from "../components/PackageReceiptVendor";
import QRCodeItemPage from "../components/ItemsReceiptVendor";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import { isValidNumber, parse } from "libphonenumber-js";
import { AuthContext } from "../components/AuthProvider";
import PlacesAutocomplete from "react-places-autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneInput from "react-phone-input-2";
import cloneDeep from "lodash/cloneDeep";
import PackageReceipt from "../components/PackageReceipt";
import { useSnackbar } from "notistack";
import RefreshIcon from "@mui/icons-material/Refresh";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AuditTrailList from "../utils/AuditTrailList";
import LocationTrail from "../utils/LocationTrail";

// TODO: Handle edit logic for each field
// TODO: add email input check

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const mapStyles = {
  borderRadius: "15px",
  // Add any other desired properties here.
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden", // This ensures the borderRadius affects the inner map.
  height: "200px",
  width: "100%",
};

const PackageDetail = ({ googleMapsApiKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [currentLocation, setCurrentLocation] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [couriers, setCouriers] = useState([]);
  const courierMapping = couriers.reduce((acc, cur) => {
    acc[cur.courier_id] = cur;
    return acc;
  }, {});
  const [customers, setCustomers] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [editingSender, setEditingSender] = useState(false);
  const [editingRecipient, setEditingRecipient] = useState(false);
  const [changingSender, setChangingSender] = useState(false);
  const [changingRecipient, setChangingRecipient] = useState(false);
  const [initialPackageData, setInitialPackageData] = useState(null);
  const [senderUpdated, setSenderUpdated] = useState(false); // true if sender is updated
  const [recipientUpdated, setRecipientUpdated] = useState(false); // true if recipient is updated
  const [packageStatuses, setPackageStatuses] = useState([
    "PROCESSED",
    "VERIFIED",
    "DELIVERING",
    "RETURNED",
    "CANCELLED",
    "LOST",
    "DELIVERED",
    "SHIPPED",
    "INCOMPLETE",
    "LABELED",
  ]);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [isItemsReceiptOpen, setIsItemsReceiptOpen] = useState(false);
  const { accessLevel, logoutUser } = useContext(AuthContext);
  // Form input checking sender
  const [senderAddress, setSenderAddress] = useState("");
  const [senderAddressError, setSenderAddressError] = useState(false);
  const [senderPhoneError, setSenderPhoneError] = useState(false);
  // Form input checking recipient
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientAddressError, setRecipientAddressError] = useState(false);
  const [recipientPhoneError, setRecipientPhoneError] = useState(false);
  // Api response data
  const [packageData, setPackageData] = useState(null);
  const [isSSEOpen, setIsSSEOpen] = useState(false);

  // 3rd party shipping
  const [vendors, setVendors] = useState([]);

  const sseRef = useRef(null);

  const [isListening, setIsListening] = useState(false);
  const [connectionError, setConnectionError] = useState(false);

  const destinationTypes = ["RESIDENTIAL", "COMMERCIAL", "OTHER"];
  const deliveryPreferences = ["ANYTIME", "MORNING", "AFTERNOON", "EVENING"];

  const chargeTypeDisplayNames = {
    Tax: "Tax",
    Insurance: "Insurance",
    Discount: "Discount",
    Fee: "Fee",
    "Handling Fee": "Handling Fee",
    "Remote Area Charge": "Remote Area Charge",
    "Fuel Surcharge": "Fuel Surcharge",
    "Peak Season Charge": "Peak Season Charge",
    "Oversize Charge": "Oversize Charge",
    "Storage Fee": "Storage Fee",
    "Customs Charge": "Customs Charge",
    Other: "Other",
  };

  // Function to map package types to emojis
  const mapPackageTypeToEmoji = (type) => {
    const emojiMap = {
      ENVELOPE: "✉️",
      PACKAGE: "🛍️",
      BOX: "📦",
      PALLET: "📋",
      CRATE: "🚧",
      OTHER: "🔍",
    };
    return `${emojiMap[type] || ""} ${type}`;
  };

  const destinationTypeOptions = (type) => {
    const destinationTypeMap = {
      RESIDENTIAL: "🏠",
      COMMERCIAL: "🏢",
      OTHER: "🔍",
    };
    return `${destinationTypeMap[type] || ""} ${type}`;
  };

  const deliveryPreferenceOptions = (type) => {
    const deliveryPreferenceMap = {
      ANYTIME: "🕒",
      MORNING: "🌅",
      AFTERNOON: "🌇",
      EVENING: "🌃",
    };
    return `${deliveryPreferenceMap[type] || ""} ${type}`;
  };

  // Audit Trails
  const [auditTrail, setAuditTrail] = useState([]);
  const [locationAuditTrail, setLocationAuditTrail] = useState([]);

  const generateSenderAddressFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapsApiKey}`
      );
      if (response.data.results[0]) {
        setSenderAddress(response.data.results[0].formatted_address);
        setSenderAddressError(false);
      } else {
        setSenderAddress("");
      }
    } catch (err) {
      setSenderAddress("");
    }
  };

  const generateRecipientAddressFromCoordinates = async (
    latitude,
    longitude
  ) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleMapsApiKey}`
      );
      if (response.data.results[0]) {
        setRecipientAddress(response.data.results[0].formatted_address);
        setRecipientAddressError(false);
      } else {
        setRecipientAddress("");
      }
    } catch (err) {
      setRecipientAddress("");
    }
  };

  const openReceipt = () => {
    setIsReceiptOpen(true);
  };

  const closeReceipt = () => {
    setIsReceiptOpen(false);
  };

  const openItemsReceipt = () => {
    setIsItemsReceiptOpen(true);
  };

  const closeItemsReceipt = () => {
    setIsItemsReceiptOpen(false);
  };

  const navigate = useNavigate();
  // map related state
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const fetchPackage = async () => {
    try {
      // Fetch package data
      const packageResponse = await axiosInstance.get(
        `/packages/package/${id}`
      );
      let newPackageData = packageResponse.data.package;
      newPackageData.items = packageResponse.data.items;
      newPackageData.couriers = packageResponse.data.couriers;

      setAuditTrail(packageResponse.data.packageAuditTrail);
      setLocationAuditTrail(packageResponse.data.packageLocationAuditTrail);
      console.log("Audit trail is: ", packageResponse.data.packageAuditTrail);
      console.log(
        "Location audit trail is: ",
        packageResponse.data.packageLocationAuditTrail
      );

      console.log("Response is : ", packageResponse);

      // Format the sender and recipient first name and last name, that is split on the first " "
      console.log("Package data is: ", newPackageData);
      const deepCopiedPackageData = cloneDeep(newPackageData);
      setPackageData(newPackageData);
      setInitialPackageData(deepCopiedPackageData);
    } catch (err) {
      console.log("Error fetching data. Error is: ", err);
      enqueueSnackbar("Error fetching data", { variant: "error" });
    }
  };

  // TODO: add change customers
  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/customers/get_all_customers");
      // For each Customer, append their first name and last names into a single string
      response.data.forEach((customer) => {
        customer.name = customer.first_name + " " + customer.last_name;
      });

      setCustomers(response.data);
    } catch (err) {}
  };

  const fetchVendors = async () => {
    try {
      const response = await axiosInstance.get(
        "/vendor/get_vendors_that_deliver_in_cuba"
      );
      console.log("Vendors are: ", response.data);
      setVendors(response.data);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      enqueueSnackbar("Error fetching vendors.", { variant: "error" });
    }
  };

  const fetchAddress = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${packageData.latitude},${packageData.longitude}&key=${googleMapsApiKey}`
      );
      if (response.data.results[0]) {
        setCurrentLocation(response.data.results[0].formatted_address);
      } else {
      }
    } catch (error) {}
  };

  const fetchCouriers = async () => {
    try {
      const response = await axiosInstance.get(`/couriers/get_couriers`);
      // For each Courier, append their first name and last names into a single string
      response.data.forEach((courier) => {
        courier.name = courier.first_name + " " + courier.last_name;
      });

      console.log("Couriers are: ", response.data);

      setCouriers(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchDataAndDependencies = async () => {
      setIsLoading(true);
      await fetchPackage(); // Fetch package data and shipment types
      await fetchCustomers(); // Then fetch customers
      await fetchCouriers(); // Then fetch couriers
      await fetchVendors(); // Then fetch vendors
      setIsLoading(false);
      // DEBUG:
      console.log("Access level is: ", accessLevel);
      console.log(
        "Manager access level is: ",
        process.env.REACT_APP_ACCESS_LEVEL_MANAGER
      );
    };

    fetchDataAndDependencies(); // Invoke the wrapper function
  }, []);

  useEffect(() => {
    if (packageData) {
      fetchAddress();
    }
  }, [packageData]);

  // use effect for detecting changes in sender and recipient info
  useEffect(() => {
    if (initialPackageData && packageData) {
      // For comparison round address latitudes and longitudes to 5 decimal places
      if (
        packageData.sender_first_name !==
          initialPackageData.sender_first_name ||
        packageData.sender_last_name !== initialPackageData.sender_last_name ||
        packageData.sender_phone !== initialPackageData.sender_phone ||
        packageData.sender_email !== initialPackageData.sender_email ||
        packageData.sender_address_latitude !==
          initialPackageData.sender_address_latitude ||
        packageData.sender_address_longitude !==
          initialPackageData.sender_address_longitude
      ) {
        setSenderUpdated(true);
      } else {
        console.log("Sender not updated");
        setSenderUpdated(false);
      }
      if (
        packageData.recipient_first_name !==
          initialPackageData.recipient_first_name ||
        packageData.recipient_last_name !==
          initialPackageData.recipient_last_name ||
        packageData.recipient_phone !== initialPackageData.recipient_phone ||
        packageData.recipient_email !== initialPackageData.recipient_email ||
        packageData.recipient_address_latitude !==
          initialPackageData.recipient_address_latitude ||
        packageData.recipient_address_longitude !==
          initialPackageData.recipient_address_longitude ||
        packageData.recipient_foreign_id !==
          initialPackageData.recipient_foreign_id
      ) {
        setRecipientUpdated(true);
      } else {
        setRecipientUpdated(false);
      }
    }
  }, [packageData, initialPackageData]);

  // Compute Price use effect
  useEffect(() => {
    if (packageData) {
      // Iterate over every item and add its tax and insurance to the total price
      let totalPrice = 0.0;
      // Finally multiply the weight of the package to the shipment_type_price
      // Weight price is in dollars per pound
      let packageWeight = parseFloat(packageData.weight);
      let shipmentTypePrice = parseFloat(packageData.price_per_pound);
      totalPrice += packageWeight * shipmentTypePrice;
      // Compute all added charges
      let additionalCharges = 0.0;
      // Json parse additional charges
      let additionalChargesJson = JSON.parse(packageData.additional_charges);
      // Iterate over each additional charge and add the amoun of it to the total price
      for (let i = 0; i < additionalChargesJson.length; i++) {
        additionalCharges += parseFloat(additionalChargesJson[i].amount);
      }
      totalPrice += additionalCharges;
      // Dimensional Weight price is in dollars per pound
      let dimensionalWeight = parseFloat(packageData.dimensional_weight);
      let dimensionalWeightPrice = 0.0;

      if (dimensionalWeight > 0.0) {
        dimensionalWeightPrice =
          (parseFloat(packageData.width) *
            parseFloat(packageData.height) *
            parseFloat(packageData.length)) /
          dimensionalWeight;
      }

      totalPrice += dimensionalWeightPrice;
      let totalPriceString = totalPrice.toFixed(2);

      // Set the total price
      setPackageData((prevData) => ({
        ...prevData,
        price: totalPriceString,
      }));
    }
  }, [
    packageData?.weight,
    packageData?.additional_charges,
    packageData?.width,
    packageData?.height,
    packageData?.length,
  ]);

  useEffect(() => {
    if (packageData && initialPackageData) {
      console.log("Data is: ", packageData);
      console.log("Initial data is: ", initialPackageData);
      if (!_.isEqual(packageData, initialPackageData)) {
        console.log("Data is updated");

        // Log the differences
        for (const key in packageData) {
          if (packageData[key] !== initialPackageData[key]) {
            console.log(`Differing key: ${key}`);
            console.log(`New Value:`);
            console.log(packageData[key]);
            console.log(`Old Value:`);
            console.log(initialPackageData[key]);
          }
        }

        setIsUpdated(true);
      } else {
        setIsUpdated(false);
      }
    }
  }, [packageData, initialPackageData]);

  useEffect(() => {
    if (editMode) {
      console.log("Opening SSE");
      console.log("Edit mode is: ", editMode);
      initializeSSE();
    } else {
      console.log("Closing SSE");
      console.log("Edit mode is: ", editMode);
      closeSSE();
    }
  }, [editMode]);

  const triggerRefresh = () => {
    console.log("Attempting to refresh SSE connection...");

    // Close the current connection
    closeSSE();

    // Wait a little bit before trying to re-establish, to ensure everything has closed properly
    setTimeout(() => {
      // Try to re-establish the connection
      initializeSSE();
    }, 1000);
  };

  const handleCreateNewItem = () => {
    let itemsCopy = [...packageData.items];
    itemsCopy.push({
      item_id: "",
      barcode: "",
      name: "",
      quantity: 1,
      price: "",
      brand: "",
      description: "",
      category: "",
      image_url: "",
    });
    setPackageData((prevData) => ({ ...prevData, items: itemsCopy }));
  };

  const handleMultipleCourierChange = (event, newValue) => {
    // `newValue` contains the new set of selected couriers

    // If you want to add or remove couriers based on changes, you can compare `newValue` with `packageData.couriers`
    // Here, we're simply setting the new value directly
    setPackageData((prevData) => ({ ...prevData, couriers: newValue }));
  };

  const handleActiveVendorChange = (event, newValue) => {
    console.log("Active vendor changed to: ", newValue);

    console.log("New active vendor id: ", newValue?.value);
    console.log("New active vendor name: ", newValue?.children);

    setPackageData((prevData) => ({
      ...prevData,
      active_vendor_id: newValue?.props.value || "",
      active_vendor_name: newValue?.props.children || "",
    }));
  };

  const handleChange = (event, itemIndex = null) => {
    let { name, value } = event.target;
    if (itemIndex !== null) {
      let itemsCopy = [...packageData.items];
      itemsCopy[itemIndex][name] = value;
      setPackageData((prevData) => ({ ...prevData, items: itemsCopy }));
    } else {
      setPackageData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCancelEditSender = () => {
    handleChange({
      target: {
        name: "sender_first_name",
        value: initialPackageData.sender_first_name,
      },
    });
    handleChange({
      target: {
        name: "sender_last_name",
        value: initialPackageData.sender_last_name,
      },
    });
    handleChange({
      target: { name: "sender_phone", value: initialPackageData.sender_phone },
    });
    handleChange({
      target: { name: "sender_email", value: initialPackageData.sender_email },
    });
    handleChange({
      target: {
        name: "sender_address_latitude",
        value: initialPackageData.sender_address_latitude,
      },
    });
    handleChange({
      target: {
        name: "sender_address_longitude",
        value: initialPackageData.sender_address_longitude,
      },
    });
    generateSenderAddressFromCoordinates(
      initialPackageData.sender_address_latitude,
      initialPackageData.sender_address_longitude
    );
    setEditingSender(false);
  };

  const handleCancelEditRecipient = () => {
    handleChange({
      target: {
        name: "recipient_first_name",
        value: initialPackageData.recipient_first_name,
      },
    });
    handleChange({
      target: {
        name: "recipient_last_name",
        value: initialPackageData.recipient_last_name,
      },
    });
    handleChange({
      target: {
        name: "recipient_phone",
        value: initialPackageData.recipient_phone,
      },
    });
    handleChange({
      target: {
        name: "recipient_email",
        value: initialPackageData.recipient_email,
      },
    });
    handleChange({
      target: {
        name: "recipient_address_latitude",
        value: initialPackageData.recipient_address_latitude,
      },
    });
    handleChange({
      target: {
        name: "recipient_address_longitude",
        value: initialPackageData.recipient_address_longitude,
      },
    });
    generateRecipientAddressFromCoordinates(
      initialPackageData.recipient_address_latitude,
      initialPackageData.recipient_address_longitude
    );
    setEditingRecipient(false);
  };

  const handleConfirmEditSender = async () => {
    let senderData = {
      customer_id: packageData.sender_id,
      customer_first_name: packageData.sender_first_name,
      customer_last_name: packageData.sender_last_name,
      customer_phone: packageData.sender_phone,
      customer_email: packageData.sender_email,
      customer_address_latitude: packageData.sender_address_latitude,
      customer_address_longitude: packageData.sender_address_longitude,
      customer_address: packageData.sender_address,
      customer_foreign_id: packageData.sender_foreign_id,
    };
    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        "/customers/update_customer_from_vendor",
        senderData
      );
      if (response.status === 200) {
        // update sender in package data
        const senderResponse = await axiosInstance.put(
          "/packages/update_sender",
          {
            package_id: packageData.package_id,
            sender_id: packageData.sender_id,
          }
        );
        if (senderResponse.status === 200) {
          // update initial package data
          initialPackageData.sender_first_name = packageData.sender_first_name;
          initialPackageData.sender_last_name = packageData.sender_last_name;
          initialPackageData.sender_phone = packageData.sender_phone;
          initialPackageData.sender_email = packageData.sender_email;
          console.log("Updating initial package Data");
          console.log(
            "Sender Address Latitude: ",
            packageData.sender_address_latitude
          );
          console.log(
            "Sender Address Longitude: ",
            packageData.sender_address_longitude
          );
          initialPackageData.sender_address_latitude =
            packageData.sender_address_latitude;
          initialPackageData.sender_address_longitude =
            packageData.sender_address_longitude;
          initialPackageData.sender_address = packageData.sender_address;
          initialPackageData.sender_foreign_id = packageData.sender_foreign_id;
          setSenderUpdated(false);
          setIsLoading(false);
          enqueueSnackbar("Sender updated successfully", {
            variant: "success",
          });
          setEditingSender(false);
        } else {
          setIsLoading(false);
          enqueueSnackbar("Error updating sender", { variant: "error" });
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Error updating sender", { variant: "error" });
      }
      console.log("Initial Package Data: ", initialPackageData);
      console.log("Current Package Data: ", packageData);
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar("Error updating sender", { variant: "error" });
    }
  };

  const handleConfirmEditRecipient = async () => {
    let recipientData = {
      customer_id: packageData.recipient_id,
      customer_first_name: packageData.recipient_first_name,
      customer_last_name: packageData.recipient_last_name,
      customer_phone: packageData.recipient_phone,
      customer_email: packageData.recipient_email,
      customer_address_latitude: packageData.recipient_address_latitude,
      customer_address_longitude: packageData.recipient_address_longitude,
      customer_address: packageData.recipient_address,
      customer_foreign_id: packageData.recipient_foreign_id,
    };
    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        "/customers/update_customer_from_vendor",
        recipientData
      );
      if (response.status === 200) {
        // update recipient in package data
        const recipientResponse = await axiosInstance.put(
          "/packages/update_recipient",
          {
            package_id: packageData.package_id,
            recipient_id: packageData.recipient_id,
          }
        );
        if (recipientResponse.status === 200) {
          // update initial package data
          initialPackageData.recipient_first_name =
            packageData.recipient_first_name;
          initialPackageData.recipient_last_name =
            packageData.recipient_last_name;
          initialPackageData.recipient_phone = packageData.recipient_phone;
          initialPackageData.recipient_email = packageData.recipient_email;
          initialPackageData.recipient_address_latitude =
            packageData.recipient_address_latitude;
          initialPackageData.recipient_address_longitude =
            packageData.recipient_address_longitude;
          initialPackageData.recipient_address = packageData.recipient_address;
          initialPackageData.recipient_foreign_id =
            packageData.recipient_foreign_id;
          setIsLoading(false);
          setRecipientUpdated(false);
          enqueueSnackbar("Recipient updated successfully", {
            variant: "success",
          });
          setEditingRecipient(false);
        } else {
          setIsLoading(false);
          enqueueSnackbar("Error updating recipient", { variant: "error" });
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Error updating recipient", { variant: "error" });
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar("Error updating recipient", { variant: "error" });
    }
  };

  const handleDeleteItem = (index) => {
    setPackageData((prevData) => {
      let itemsCopy = [...prevData.items];
      itemsCopy.splice(index, 1);
      return { ...prevData, items: itemsCopy };
    });
  };

  const handleCourierSelect = (event) => {
    const { value } = event.target;
    // Find the selected courier name in the couriers array
    const selectedCourier = couriers.find((courier) => courier.name === value);

    if (selectedCourier) {
      setPackageData((prevData) => ({
        ...prevData,
        couriers: selectedCourier.courier_id,
      }));
    }
  };

  // TODO: modify additional charges to not be a json string

  const handleAddCharge = () => {
    const newCharges = [
      ...JSON.parse(packageData.additional_charges || "[]"),
      { type: "Tax", amount: "0.00" },
    ];
    setPackageData({
      ...packageData,
      additional_charges: JSON.stringify(newCharges),
    });
  };

  const handleChargeChange = (index, field, value) => {
    const newCharges = [...JSON.parse(packageData.additional_charges || "[]")];
    newCharges[index][field] = value;
    setPackageData({
      ...packageData,
      additional_charges: JSON.stringify(newCharges),
    });
  };

  const handleRemoveCharge = (index) => {
    const newCharges = [...JSON.parse(packageData.additional_charges || "[]")];
    newCharges.splice(index, 1);
    setPackageData({
      ...packageData,
      additional_charges: JSON.stringify(newCharges),
    });
  };

  const handleStatusSelect = (event) => {
    const newStatus = event.target.value;

    setPackageData((prevData) => {
      if (prevData.status === "DELIVERING" && newStatus !== "DELIVERING") {
        // If the status was "DELIVERING" and is now being changed, set courier_in_possession_id to null
        return {
          ...prevData,
          status: newStatus,
          courier_in_possession_id: null,
        };
      } else {
        // Otherwise, just change the status
        return {
          ...prevData,
          status: newStatus,
        };
      }
    });
  };

  const enableEditMode = () => {
    setEditMode(true);
  };

  const cancelEdits = () => {
    setIsLoading(true);
    fetchPackage();
    setEditMode(false);
    setIsLoading(false);
  };

  const deletePackage = async () => {
    setIsLoading(true);
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this package? This action is not reversible."
    );
    if (isConfirmed) {
      try {
        const response = await axiosInstance.delete(
          `/packages/delete/${packageData.package_id}`
        );
        if (response.status === 200) {
          setIsLoading(false);
          enqueueSnackbar("Package deleted successfully", {
            variant: "success",
          });
          navigate(-1);
        } else {
          setIsLoading(false);
          enqueueSnackbar("Error deleting package", { variant: "error" });
        }
      } catch (err) {
        setIsLoading(false);
        enqueueSnackbar("Error deleting package", { variant: "error" });
      }
    } else {
      setIsLoading(false);
    }
  };

  const updatePackage = async () => {
    let updatedData = {
      couriers: packageData.couriers,
      shipment_type_id: packageData.shipment_type_id,
      package_id: packageData.package_id,
      status: packageData.status,
      status_message: packageData.status_message,
      weight: packageData.weight,
      height: packageData.height,
      width: packageData.width,
      length: packageData.length,
      package_type: packageData.price,
      destination_type: packageData.destination_type,
      delivery_preference: packageData.delivery_preference,
      is_fragile: packageData.is_fragile,
      is_hazardous: packageData.is_hazardous,
      is_perishable: packageData.is_perishable,
      is_oversize: packageData.is_oversize,
      is_insured: packageData.is_insured,
      is_signature_required: packageData.is_signature_required,
      commission: packageData.commission,
      additional_charges: packageData.additional_charges,
      price: packageData.price,
      marketplace_status: packageData.marketplace_status,
      items: packageData.items,
      additional_delivery_instructions:
        packageData.additional_delivery_instructions,
      courier_in_possession_id: packageData.courier_in_possession_id,
      active_vendor_id: packageData.active_vendor_id,
      active_vendor_name: packageData.active_vendor_name,
    };

    setIsLoading(true);
    try {
      const response = await axiosInstance.put("/packages/update", updatedData);
      if (response.status === 200) {
        setEditMode(false);
        // Query the package again to get the updated data
        const freshData = await axiosInstance.get(
          `/packages/package/${packageData.package_id}`
        );
        let updatedPackageData = freshData.data.package;
        updatedPackageData.items = freshData.data.items;
        updatedPackageData.couriers = freshData.data.couriers;
        // Deep copy the response data using lodash's cloneDeep
        const deepCopiedData = cloneDeep(updatedPackageData);
        console.log("Updated package data is: ", updatedPackageData);
        setInitialPackageData(updatedPackageData);
        setPackageData(deepCopiedData);
        enqueueSnackbar("Package updated successfully", { variant: "success" });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        enqueueSnackbar("Error updating package", { variant: "error" });
      }
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar("Error updating package", { variant: "error" });
    }
  };

  const getLatLngFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyCIY84dPOGgBgaaazmQ-ESEofTQ0GM9Mq0`
      );
      const data = await response.json();
      const location = data.results[0].geometry.location;
      return location;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSenderAddressInputChange = (address) => {
    if (!senderAddressError) {
      setSenderAddressError(true);
    }
    setSenderAddress(address);
  };

  const handleSenderAddressSelect = (address) => {
    setSenderAddressError(false);
    // turn the address into latitute and longitude
    console.log("Turning address into lat and lng");
    console.log(address);
    setSenderAddress(address);
    getLatLngFromAddress(address).then((location) => {
      setPackageData((prevData) => ({
        ...prevData,
        sender_address_latitude: location.lat,
        sender_address_longitude: location.lng,
      }));
    });
  };

  const handleRecipientAddressInputChange = (address) => {
    if (!recipientAddressError) {
      setRecipientAddressError(true);
    }
    setRecipientAddress(address);
  };

  const handleRecipientAddressSelect = (address) => {
    setRecipientAddressError(false);
    // turn the address into latitute and longitude
    console.log("Turning address into lat and lng");
    console.log(address);
    setRecipientAddress(address);
    getLatLngFromAddress(address).then((location) => {
      setPackageData((prevData) => ({
        ...prevData,
        recipient_address_latitude: location.lat,
        recipient_address_longitude: location.lng,
      }));

      console.log(packageData);
    });
  };

  const handleSenderPhoneInputChange = (value, country) => {
    handleChange({ target: { name: "sender_phone", value: value } });

    const isValid = isValidNumber(value, country.countryCode.toUpperCase());

    setSenderPhoneError(!isValid);
  };

  const handleRecipientPhoneInputChange = (value, country) => {
    handleChange({ target: { name: "recipient_phone", value: value } });
    const isValid = isValidNumber(value, country.countryCode.toUpperCase());
    setRecipientPhoneError(!isValid);
  };

  const initializeSSE = () => {
    console.log("Initializing SSE...");

    setIsListening(true);

    // Comment out below for production
    // sseRef.current = new EventSource(
    //   "http://localhost:8080/scanner/listen_scanned_data",
    //   { withCredentials: true }
    // );
    // Uncomment below for production
    sseRef.current = new EventSource(
      "https://api.cexpress.app/scanner/listen_scanned_data",
      { withCredentials: true }
    );

    sseRef.current.onmessage = (event) => {
      console.log("Scanned data received...");
      const scannedItem = JSON.parse(event.data);

      if (scannedItem && scannedItem.barcode) {
        setPackageData((prevData) => {
          const itemsCopy = [...prevData.items];
          const itemIndex = itemsCopy.findIndex(
            (item) => item.barcode === scannedItem.barcode
          );

          if (itemIndex !== -1) {
            // Item exists
            itemsCopy[itemIndex].quantity = String(
              parseInt(itemsCopy[itemIndex].quantity) + 1
            );
          } else {
            // New item
            const newItem = {
              item_id: scannedItem.item_id,
              name: scannedItem.name,
              description: scannedItem.description,
              category: scannedItem.category,
              barcode: scannedItem.barcode,
              quantity: 1,
              image_url: scannedItem.image_url,
              price: scannedItem.price,
              brand: scannedItem.brand,
            };
            itemsCopy.push(newItem);
          }

          return { ...prevData, items: itemsCopy };
        });
      } else {
        console.log("Scanned data is not a valid item...");
      }
    };

    sseRef.current.onopen = () => {
      console.log("Listening for scanned data...");
      setIsListening(true);
      setIsSSEOpen(true);
    };

    sseRef.current.onerror = () => {
      console.log("Error listening for scanned data...");
      setConnectionError(true);
      setIsListening(false);
      setIsSSEOpen(false);
      sseRef.current.close();
    };

    return sseRef.current;
  };

  const closeSSE = () => {
    if (sseRef.current) {
      console.log("Closing SSE connection...");
      sseRef.current.close();
      setIsListening(false);
      setIsSSEOpen(false);
      console.log("SSE connection closed.");
    } else {
      console.log("SSE connection not initialized.");
    }
  };

  const handleCourierInPossessionChange = (event, newValue) => {
    console.log("Handling courier change with value: ", newValue); // Debug log
    setPackageData((prevData) => {
      const updatedData = {
        ...prevData,
        courier_in_possession_id: newValue?.courier_id || null,
      };
      console.log("Updated data: ", updatedData); // Debug log
      return updatedData;
    });
  };

  const printReceipt = () => {
    window.print();
  };

  // TODO: add phone sanitization, input validation, address validation, etc.

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9999,
          visibility: isLoading ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </Box>
      <AppBar
        position="fixed"
        display="flex"
        width="100%"
        sx={{
          backgroundImage: "linear-gradient(225deg, #0070c9 30%, #00a8e8 90%)",
        }}
      >
        <Toolbar sx={{ justifyContent: "center" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            Back
          </Typography>
          <Typography
            variant="h6"
            sx={{ ml: 4.0, flexGrow: 1, textAlign: "left" }}
          >
            <Grid container alignItems="center">
              <Grid item>
                {editMode && isListening && !connectionError ? (
                  <FiberManualRecordIcon
                    color="success"
                    fontSize="small"
                    sx={{
                      marginRight: "8px", // Space it a bit from the text
                      animation: "blink 1s infinite",
                      borderRadius: "50%", // Makes the background circular
                      padding: "1px", // Space between the icon and the circular background

                      "@keyframes blink": {
                        "0%": { opacity: 0.2 },
                        "50%": { opacity: 1 },
                        "100%": { opacity: 0.2 },
                      },
                    }}
                  />
                ) : (
                  editMode && (
                    <IconButton onClick={triggerRefresh} color="error">
                      <RefreshIcon />
                    </IconButton>
                  )
                )}
              </Grid>
              <Grid item>Package Details</Grid>
            </Grid>
          </Typography>

          {!editMode ? (
            <Button
              variant="contained"
              color="primary"
              disabled={editMode}
              onClick={enableEditMode}
              sx={{
                mt: 1.5,
                mb: 1.5,
                backgroundImage:
                  "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
              }}
            >
              Edit Details
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="success"
                disabled={
                  !editMode ||
                  (editMode && !isUpdated) ||
                  (editMode &&
                    packageData.status === "DELIVERING" &&
                    (packageData.courier_in_possession_id === null ||
                      packageData.courier_in_possession_id === ""))
                }
                onClick={updatePackage}
                sx={{
                  backgroundImage:
                    "linear-gradient(45deg, #66bb6a 30%, #43a047 90%)",
                  boxShadow: "0 3px 5px 2px rgba(239, 83, 80, .3)",
                }}
              >
                <CheckIcon />
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={!editMode}
                onClick={cancelEdits}
                sx={{
                  ml: 1.5,
                  backgroundImage:
                    "linear-gradient(45deg, #ef5350 30%, #e53935 90%)",
                  boxShadow: "0 3px 5px 2px rgba(239, 83, 80, .3)",
                }}
              >
                <CloseIcon />
              </Button>
            </>
          )}
          {editMode && accessLevel < 4 && (
            <Button
              variant="contained"
              color="error"
              disabled={!editMode}
              onClick={deletePackage}
              sx={{
                ml: 1.5,
                backgroundImage:
                  "linear-gradient(45deg, #ef5350 30%, #e53935 90%)",
                boxShadow: "0 3px 5px 2px rgba(239, 83, 80, .3)",
              }}
            >
              <DeleteIcon />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "64px" }} />{" "}
      {/* 64px is the typical height of an AppBar */}
      {packageData && (
        <>
          <Container maxWidth="lg">
            <Paper elevation={3}>
              <Box p={3}>
                <Grid container spacing={3}>
                  {/* Sender and Recipient section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5">Sender</Typography>
                    <TextField
                      name="sender_first_name"
                      label="Sender First Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_first_name || ""}
                      disabled={!editingSender}
                      onChange={handleChange}
                      fullWidth
                    />
                    <TextField
                      name="sender_last_name"
                      label="Sender Last Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_last_name || ""}
                      disabled={!editingSender}
                      onChange={handleChange}
                      fullWidth
                    />
                    <PhoneInput
                      name="sender_phone"
                      country={"us"}
                      value={packageData.sender_phone || ""}
                      onChange={handleSenderPhoneInputChange}
                      countryCodeEditable={false}
                      enableCountryCode={true}
                      enableSearch={true}
                      isValid={() => !senderPhoneError}
                      disabled={!editingSender}
                      placeholder="Enter phone number"
                      inputStyle={{
                        width: "91.5%",
                        height: "56px",
                        fontSize: "1rem",
                        marginLeft: "40px",
                        padding: "18.5px 14px",
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        variant: "filled",
                      }}
                    />
                    <TextField
                      name="sender_email"
                      label="Sender Email"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.sender_email || ""}
                      disabled={!editingSender}
                      onChange={handleChange}
                      fullWidth
                    />
                    <PlacesAutocomplete
                      value={packageData.sender_address}
                      onChange={(address) =>
                        handleSenderAddressInputChange(address)
                      }
                      onSelect={async (address) => {
                        handleSenderAddressSelect(address);
                      }}
                      disabled={!editingSender}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                            sx={{ mt: 1.5, mb: 1.5 }}
                            fullWidth
                            variant="filled"
                            label="Sender Address"
                            name="sender_address"
                            error={senderAddressError}
                            helperText={
                              senderAddressError &&
                              "Please select an address from the dropdown"
                            }
                            disabled={!editingSender}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: "10px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={suggestion.placeId}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={18}
                      center={{
                        lat: packageData.sender_address_latitude,
                        lng: packageData.sender_address_longitude,
                      }}
                      // onClick={async (e) => {
                      //   if (!editingSender) return;
                      //   console.log("clicked");
                      //   console.log(e);
                      //   console.log(e.latLng.lat(), e.latLng.lng());
                      //   const clickedPosition = {
                      //     lat: e.latLng.lat(),
                      //     lng: e.latLng.lng(),
                      //   };
                      //   setPackageData((prevData) => ({
                      //     ...prevData,
                      //     sender_address_latitude: clickedPosition.lat,
                      //     sender_address_longitude: clickedPosition.lng,
                      //   }));
                      // }}
                    >
                      <MarkerF
                        position={{
                          lat: packageData.sender_address_latitude,
                          lng: packageData.sender_address_longitude,
                        }}
                        draggable={editingSender}
                        onDragEnd={async (e) => {
                          if (!editingSender) return;
                          console.log("dragged");
                          console.log(e);
                          console.log(e.latLng.lat(), e.latLng.lng());
                          const draggedPosition = {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          };
                          generateSenderAddressFromCoordinates(
                            e.latLng.lat(),
                            e.latLng.lng()
                          );
                          setPackageData((prevData) => ({
                            ...prevData,
                            sender_address_latitude: draggedPosition.lat,
                            sender_address_longitude: draggedPosition.lng,
                          }));
                        }}
                      />
                    </GoogleMap>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5">Recipient</Typography>
                    <TextField
                      name="recipient_first_name"
                      label="Recipient First Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_first_name || ""}
                      disabled={!editingRecipient}
                      onChange={handleChange}
                      fullWidth
                    />
                    <TextField
                      name="recipient_last_name"
                      label="Recipient Last Name"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_last_name || ""}
                      disabled={!editingRecipient}
                      onChange={handleChange}
                      fullWidth
                    />
                    <PhoneInput
                      name="recipient_phone"
                      country={"us"}
                      value={packageData.recipient_phone || ""}
                      onChange={handleRecipientPhoneInputChange}
                      countryCodeEditable={false}
                      enableCountryCode={true}
                      enableSearch={true}
                      isValid={() => !recipientPhoneError}
                      disabled={!editingRecipient}
                      placeholder="Enter phone number"
                      inputStyle={{
                        width: "91.5%",
                        height: "56px",
                        fontSize: "1rem",
                        marginLeft: "40px",
                        padding: "18.5px 14px",
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        variant: "filled",
                      }}
                    />
                    <TextField
                      name="recipient_email"
                      label="Recipient Email"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_email || ""}
                      disabled={!editingRecipient}
                      onChange={handleChange}
                      fullWidth
                    />
                    <PlacesAutocomplete
                      value={packageData.recipient_address}
                      onChange={(address) =>
                        handleRecipientAddressInputChange(address)
                      }
                      onSelect={async (address) => {
                        handleRecipientAddressSelect(address);
                      }}
                      disabled={!editingSender}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input",
                            })}
                            sx={{ mt: 1.5, mb: 1.5 }}
                            fullWidth
                            variant="filled"
                            label="Recipient Address"
                            name="recipient_address"
                            error={recipientAddressError}
                            helperText={
                              recipientAddressError &&
                              "Please select an address from the dropdown"
                            }
                            disabled={!editingRecipient}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    padding: "10px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    padding: "10px",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  key={suggestion.placeId}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={18}
                      center={{
                        lat: packageData.recipient_address_latitude,
                        lng: packageData.recipient_address_longitude,
                      }}
                      // onClick={async (e) => {
                      //   if (!editingRecipient) return;
                      //   console.log("clicked");
                      //   console.log(e);
                      //   console.log(e.latLng.lat(), e.latLng.lng());
                      //   const clickedPosition = {
                      //     lat: e.latLng.lat(),
                      //     lng: e.latLng.lng(),
                      //   };
                      //   generateRecipientAddressFromCoordinates(
                      //     e.latLng.lat(),
                      //     e.latLng.lng()
                      //   );
                      //   setPackageData((prevData) => ({
                      //     ...prevData,
                      //     recipient_address_latitude: clickedPosition.lat,
                      //     recipient_address_longitude: clickedPosition.lng,
                      //   }));
                      // }}
                    >
                      <MarkerF
                        position={{
                          lat: packageData.recipient_address_latitude,
                          lng: packageData.recipient_address_longitude,
                        }}
                        draggable={editingRecipient}
                        onDragEnd={async (e) => {
                          if (!editingRecipient) return;
                          console.log("dragged");
                          console.log(e);
                          console.log(e.latLng.lat(), e.latLng.lng());
                          const draggedPosition = {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          };
                          generateRecipientAddressFromCoordinates(
                            e.latLng.lat(),
                            e.latLng.lng()
                          );
                          setPackageData((prevData) => ({
                            ...prevData,
                            recipient_address_latitude: draggedPosition.lat,
                            recipient_address_longitude: draggedPosition.lng,
                          }));
                        }}
                      />
                    </GoogleMap>
                    <TextField
                      name="recipient_foreign_id"
                      label="Recipient Foreign ID"
                      variant="filled"
                      sx={{ mt: 1.5, mb: 1.5 }}
                      value={packageData.recipient_foreign_id || ""}
                      disabled={!editingRecipient}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={6}>
                      {editingSender ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1.5, mr: 1.5 }}
                            disabled={!senderUpdated || senderAddressError}
                            onClick={handleConfirmEditSender}
                          >
                            Confirm
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onClick={handleCancelEditSender}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="filled"
                            color="primary"
                            sx={{ mt: 1.5, mr: 1.5 }}
                            onClick={() => setEditingSender(true)}
                            disabled={changingSender}
                          >
                            Edit Sender Info
                          </Button>
                          <Button
                            variant="filled"
                            color="secondary"
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onClick={() => setChangingSender(true)}
                            disabled={true}
                          >
                            Change Sender
                          </Button>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {editingRecipient ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mt: 1.5, mr: 1.5 }}
                            disabled={!recipientUpdated}
                            onClick={handleConfirmEditRecipient}
                          >
                            Confirm
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onClick={handleCancelEditRecipient}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="filled"
                            color="primary"
                            sx={{ mt: 1.5, mr: 1.5 }}
                            onClick={() => setEditingRecipient(true)}
                            disabled={changingRecipient}
                          >
                            Edit Recipient Info
                          </Button>
                          <Button
                            variant="filled"
                            color="secondary"
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onClick={() => setChangingRecipient(true)}
                            disabled={true}
                          >
                            Change Recipient
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {/* Contents section */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Contents and Details</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Item #</TableCell>
                            <TableCell>Item Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {packageData.items.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <TextField
                                  name="name"
                                  variant="filled"
                                  value={item.name || ""}
                                  disabled={
                                    !editMode || item.barcode
                                      ? item.barcode !== ""
                                      : false
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="description"
                                  variant="filled"
                                  value={item.description || ""}
                                  disabled={
                                    !editMode || item.barcode
                                      ? item.barcode !== ""
                                      : false
                                  }
                                  onChange={(e) => handleChange(e, index)}
                                  fullWidth
                                  multiline
                                  rows={4}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name="quantity"
                                  variant="filled"
                                  type="number"
                                  inputProps={{ min: "1", step: "1" }}
                                  value={item.quantity || 1}
                                  disabled={!editMode}
                                  onChange={(e) => handleChange(e, index)}
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell>
                                <img
                                  src={item.image_url}
                                  alt={item.name}
                                  style={{ width: "100px", height: "auto" }}
                                />
                              </TableCell>

                              <TableCell>
                                <IconButton
                                  color="primary"
                                  onClick={() => handleDeleteItem(index)}
                                  disabled={!editMode}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {editMode && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleCreateNewItem}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        Add New Item
                      </Button>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        label="Shipment Type"
                        name="shipment_type_name"
                        variant="filled"
                        value={packageData.shipment_type_name || ""}
                        disabled
                        sx={{ mt: 1.5, mb: 1.5 }}
                        fullWidth
                      />
                      <TextField
                        name="shipment_type_price_per_pound"
                        label="Shipment Type Price per Pound"
                        variant="filled"
                        value={packageData.price_per_pound}
                        disabled
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        name="shipment_type_dimensional_weight"
                        label="Shipment Type Dimensional Weight Rate"
                        variant="filled"
                        value={packageData.dimensional_weight}
                        disabled={true}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        name="weight"
                        label="Package Weight"
                        variant="filled"
                        value={packageData.weight || ""}
                        disabled={!editMode}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        name="height"
                        label="Package Height"
                        variant="filled"
                        value={packageData.height || ""}
                        disabled={!editMode}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        name="width"
                        label="Package Width"
                        variant="filled"
                        value={packageData.width || ""}
                        disabled={!editMode}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        name="length"
                        label="Package Length"
                        variant="filled"
                        value={packageData.length || ""}
                        disabled={!editMode}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width={200}>Type</TableCell>
                              <TableCell width={200}>Name</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(
                              packageData.additional_charges || "[]"
                            ).map((charge, index) => (
                              <TableRow key={index}>
                                <TableCell width={200}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    disabled={!editMode}
                                  >
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                      value={charge.type}
                                      onChange={(e) =>
                                        handleChargeChange(
                                          index,
                                          "type",
                                          e.target.value
                                        )
                                      }
                                      label="Type"
                                    >
                                      {Object.keys(chargeTypeDisplayNames).map(
                                        (type) => (
                                          <MenuItem key={type} value={type}>
                                            {chargeTypeDisplayNames[type]}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell width={200}>
                                  {charge.type === "Other" ? (
                                    <TextField
                                      fullWidth
                                      disabled={!editMode}
                                      label="Custom Charge Name"
                                      value={charge.customName || ""}
                                      onChange={(e) =>
                                        handleChargeChange(
                                          index,
                                          "customName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    <TextField
                                      fullWidth
                                      value={
                                        chargeTypeDisplayNames[charge.type]
                                      }
                                      disabled
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    label="Amount"
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      let isNegative = false;

                                      // Check if value starts with a negative sign
                                      if (val.startsWith("-")) {
                                        isNegative = true;
                                        val = val.substring(1); // Remove the negative sign for processing
                                      }

                                      // Remove non-numeric characters
                                      val = val.replace(/[^0-9]/g, "");

                                      if (val === "") {
                                        handleChargeChange(
                                          index,
                                          "amount",
                                          "0.00"
                                        );
                                      } else {
                                        // Format as decimal
                                        val = parseFloat(
                                          parseInt(val, 10) / 100
                                        ).toFixed(2);

                                        // Add back the negative sign if needed
                                        if (isNegative) {
                                          val = "-" + val;
                                        }

                                        handleChargeChange(
                                          index,
                                          "amount",
                                          val
                                        );
                                      }
                                    }}
                                    value={charge.amount}
                                    disabled={!editMode}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    disabled={!editMode}
                                    onClick={() => handleRemoveCharge(index)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {editMode && (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: "20px", marginBottom: "20px" }}
                          onClick={handleAddCharge}
                        >
                          Add New Charge
                        </Button>
                      )}
                      <TextField
                        name="package_price"
                        label="Package Price"
                        variant="filled"
                        value={packageData.price || ""}
                        disabled={true}
                        sx={{ mt: 1.5, mb: 1.5 }}
                        onChange={handleChange}
                        fullWidth
                      />
                      <Grid
                        container
                        spacing={3}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item xs={12} md={12}>
                          <Typography variant="h5">More Options</Typography>
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_fragile}
                                disabled={
                                  packageData.package_type === "ENVELOPE" ||
                                  !editMode
                                }
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_fragile",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="📦 Is Fragile"
                          />
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_hazardous}
                                disabled={
                                  packageData.package_type === "ENVELOPE" ||
                                  !editMode
                                }
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_hazardous",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="☣️ Is Hazardous"
                          />
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_perishable}
                                disabled={
                                  packageData.package_type === "ENVELOPE" ||
                                  !editMode
                                }
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_perishable",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="🍎 Is Perishable"
                          />
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_oversize}
                                disabled={
                                  packageData.package_type === "ENVELOPE" ||
                                  !editMode
                                }
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_oversize",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="📏 Is Oversize"
                          />
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_insured}
                                disabled={!editMode}
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_insured",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="💰 Is Insured"
                          />
                        </Grid>

                        <Grid item xs={0}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={packageData.is_signature_required}
                                disabled={!editMode}
                                onChange={(event) =>
                                  handleChange({
                                    target: {
                                      name: "is_signature_required",
                                      value: event.target.checked,
                                    },
                                  })
                                }
                              />
                            }
                            label="✍️ Signature Required?"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl fullWidth>
                            <Typography variant="body1">
                              Destination Type
                            </Typography>
                            <Select
                              id="destination-type-select"
                              value={packageData.destination_type}
                              onChange={(event) =>
                                handleChange({
                                  target: {
                                    name: "destination_type",
                                    value: event.target.value,
                                  },
                                })
                              }
                              disabled={!editMode}
                            >
                              {destinationTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                  {destinationTypeOptions(type)}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              Choose the type of destination
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl fullWidth>
                            <Typography variant="body1">
                              Delivery Preference
                            </Typography>
                            <Select
                              id="delivery-preference-select"
                              disabled={!editMode}
                              value={packageData.delivery_preference}
                              onChange={(event) =>
                                handleChange({
                                  target: {
                                    name: "delivery_preference",
                                    value: event.target.value,
                                  },
                                })
                              }
                            >
                              {deliveryPreferences.map((type) => (
                                <MenuItem key={type} value={type}>
                                  {deliveryPreferenceOptions(type)}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              Select your preferred time for delivery
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Delivery section */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Delivery</Typography>
                    <Grid container spacing={3}>
                      {/* this option should allow to replace the packageData.active_vendor_id and thus active_vendor_name with an option from the vendors array */}
                      {/* this option can never be empty */}
                      {vendors.length > 0 && (
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            required
                            error={!packageData.active_vendor_id}
                          >
                            <InputLabel id="active-vendor-label">
                              Active Vendor for Delivery
                            </InputLabel>
                            <Select
                              labelId="active-vendor-label"
                              id="active-vendor-select"
                              value={packageData.active_vendor_id}
                              label="Active Vendor for Delivery"
                              onChange={handleActiveVendorChange}
                              disabled={
                                !editMode &&
                                (packageData.status !== "LABELED" ||
                                  packageData.status !== "PROCESSED")
                              }
                            >
                              {vendors.map((option) => (
                                <MenuItem
                                  key={option.vendor_id}
                                  value={option.vendor_id}
                                >
                                  {option.vendor_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                      {packageData.active_vendor_id !==
                        packageData.original_vendor_id && (
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Active Vendor for Delivery
                          </Typography>
                          <TextField
                            label="Vendor Name"
                            variant="outlined"
                            value={packageData.active_vendor_name || ""}
                            disabled
                            fullWidth
                            sx={{ mt: 1.5, mb: 1.5 }}
                          />
                        </Grid>
                      )}

                      {/* Hide Couriers section if there is an active vendor */}
                      {packageData.active_vendor_id ===
                        packageData.original_vendor_id && (
                        <React.Fragment>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6">Couriers</Typography>
                            <Autocomplete
                              multiple
                              options={couriers}
                              getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name}`
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.courier_id === value.courier_id
                              }
                              value={packageData.couriers || []}
                              onChange={handleMultipleCourierChange}
                              disabled={!editMode}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Typography variant="h6">
                              Courier in Possession
                            </Typography>
                            <Autocomplete
                              options={packageData.couriers || []}
                              getOptionLabel={(option) =>
                                `${option.first_name} ${option.last_name}`
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.courier_id === value.courier_id
                              }
                              value={
                                packageData.couriers?.find(
                                  (courier) =>
                                    courier.courier_id ===
                                    packageData.courier_in_possession_id
                                ) || null
                              }
                              onChange={handleCourierInPossessionChange}
                              disabled={
                                !editMode || packageData.status !== "DELIVERING"
                              }
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                  {/* Tracking section */}
                  <Grid item xs={12}>
                    <Typography variant="h5">Tracking Details</Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="tracking_number"
                          label="Tracking Number"
                          variant="filled"
                          value={packageData.tracking_number || ""}
                          disabled
                          sx={{ mt: 1.5, mb: 1.5 }}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Select
                          name="package_status"
                          label="Package Status"
                          variant="filled"
                          value={packageData.status || ""}
                          disabled={
                            !editMode ||
                            packageData.active_vendor_id !==
                              packageData.original_vendor_id
                          }
                          sx={{ mt: 1.5, mb: 1.5 }}
                          onChange={handleStatusSelect}
                          fullWidth
                        >
                          {packageStatuses.map((status, index) => (
                            <MenuItem key={index} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="status_message"
                          label="Status Message"
                          variant="filled"
                          value={packageData.status_message || ""}
                          disabled={
                            !editMode ||
                            packageData.active_vendor_id !==
                              packageData.original_vendor_id
                          }
                          sx={{ mt: 1.5, mb: 1.5 }}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="additional_delivery_instructions"
                          label="Additional Delivery Instructions"
                          variant="filled"
                          value={
                            packageData.additional_delivery_instructions || ""
                          }
                          disabled={!editMode}
                          sx={{ mt: 1.5, mb: 1.5 }}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="current_location"
                          label="Current Address"
                          variant="filled"
                          value={currentLocation || ""}
                          disabled={true}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Current Location</Typography>
                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={{
                            lat: packageData.latitude,
                            lng: packageData.longitude,
                          }}
                          zoom={10}
                          mapTypeId={"satellite"}
                        >
                          <MarkerF
                            position={{
                              lat: Number(packageData.latitude),
                              lng: Number(packageData.longitude),
                            }}
                            onClick={() => {
                              setSelectedLocation({
                                lat: Number(packageData.latitude),
                                lng: Number(packageData.longitude),
                                status: packageData.package_status,
                                status_message: packageData.status_message,
                                updated_at: packageData.date_updated,
                              });
                            }}
                            icon={{
                              url: markerIcon,
                              scaledSize: new window.google.maps.Size(50, 50),
                              origin: new window.google.maps.Point(0, 0),
                              anchor: new window.google.maps.Point(25, 25),
                            }}
                          />
                          {selectedLocation && (
                            <InfoWindow
                              position={{
                                lat: selectedLocation.lat,
                                lng: selectedLocation.lng,
                              }}
                              onCloseClick={() => setSelectedLocation(null)}
                            >
                              <div
                                style={{
                                  backgroundColor: "#333",
                                  color: "#fff",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <h2>{`Status: ${selectedLocation.status}`}</h2>
                                <p>{`Status Message: ${selectedLocation.status_message}`}</p>
                                <p>{`Last Updated: ${new Date(
                                  selectedLocation.updated_at
                                ).toLocaleString()}`}</p>
                              </div>
                            </InfoWindow>
                          )}
                        </GoogleMap>
                      </Grid>
                      <Grid item xs={12}>
                        <AuditTrailList auditTrail={auditTrail} />
                      </Grid>
                      <Grid item xs={12}>
                        <LocationTrail
                          locationAuditTrail={locationAuditTrail}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box mt={3} mb={3}>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    disabled={editMode}
                    onClick={openReceipt}
                    sx={{
                      backgroundImage:
                        "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
                      width: "100%",
                      padding: "10px",
                      textTransform: "uppercase",
                      color: "#ffffff", // Set text color to white for better visibility
                    }}
                  >
                    Package QR Code
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    disabled={editMode}
                    onClick={openItemsReceipt}
                    sx={{
                      backgroundImage:
                        "linear-gradient(45deg, #0070c9 30%, #00a8e8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(0, 112, 201, .3)",
                      width: "100%",
                      padding: "10px",
                      textTransform: "uppercase",
                      color: "#ffffff", // Set text color to white for better visibility
                    }}
                  >
                    Item QR Codes
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Dialog
              open={isReceiptOpen}
              onClose={closeReceipt}
              aria-labelledby="receipt-dialog"
              fullWidth
              maxWidth="md"
            >
              <DialogContent>
                <PackageReceipt
                  packageId={id}
                  onClose={closeReceipt}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={closeReceipt}
                  color="primary"
                  disabled={isLoading}
                >
                  Close
                </Button>
                <Button
                  onClick={printReceipt}
                  color="primary"
                  disabled={isLoading}
                >
                  Print
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={isItemsReceiptOpen}
              onClose={closeItemsReceipt}
              maxWidth="lg"
              fullWidth={true}
            >
              <DialogContent>
                <QRCodeItemPage
                  items={packageData.items}
                  onClose={closeItemsReceipt}
                  packageId={id}
                />
              </DialogContent>
            </Dialog>
          </Container>
        </>
      )}
    </div>
  );
};

export default PackageDetail;
